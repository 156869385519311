import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '@ngx-translate/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { HappeningsService } from '../../api/happenings.service';

@Component({
  selector: 'happenings-gallery',
  templateUrl: './happenings-gallery.component.html',
  styleUrls: ['./happenings-gallery.component.scss'],
  providers: [TranslatePipe]
})
export class HappeningsGalleryComponent implements OnInit {

  service: any;
  file: File;

  constructor(
    private route: UIRouter,
    private toastr: ToastrService,
    private translatePipe: TranslatePipe,
    private happeningsService: HappeningsService,
  ) { }

  ngOnInit() {
    this.happeningsService.getHappening(this.route.globals.params.id)
      .subscribe((service: any) => {
        this.service = service;
      }, err => {
        this.toastr.error(err.statusText, err.status);
      });
  }

  removeImage(image) {
    if (confirm(this.translatePipe.transform(_i18n('service.details.confirm.remove-image')))) {
      this.happeningsService.removeServiceImage(this.service.id, image.id)
        .subscribe( response => {
          this.toastr.success(this.translatePipe.transform(_i18n('service.details.toast-messages.sucess.removed-image')), 'Success!');
          this.service.pictures.splice(this.service.pictures.indexOf(image), 1);
        }, err => {
          this.toastr.error(err.statusText, err.status);
        });
    }
  }

  setAsDefault(image) {
    this.toastr.info(this.translatePipe.transform(_i18n('service.details.toast-messages.info.image-setting-default')), null);
    this.happeningsService.setServiceImageDefault(image, this.service)
      .subscribe( response => {
        this.toastr.success(this.translatePipe.transform(_i18n('service.details.toast-messages.sucess.default-image')), 'Success!');
        this.service = response;
        this.happeningsService.changePicture(this.service.image);
      }, err => {
        this.toastr.error(err.statusText, err.status);
      });
  }

  imageAdded(event) {
    if (this.service.pictures.length >= 8) {

      this.toastr.error(this.translatePipe.transform(_i18n('service.details.toast-messages.error.too-many-images')), null);
    } else {
      this.toastr.info(this.translatePipe.transform(_i18n('service.details.toast-messages.info.sending-images')), null);
      const fileList: FileList = event.target.files;

      if (fileList.length > 0) {
        this.file = fileList[0];

        const formData: FormData = new FormData();
        formData.append('source', this.file, this.file.name);
        this.happeningsService.addServiceImage(formData, this.service.id)
        .subscribe( res => {
          this.toastr.success(this.translatePipe.transform(_i18n('service.details.toast-messages.sucess.image-added')), 'Success!');
          this.service.pictures.push(res);
        }, err => {
          this.toastr.error(err.statusText, err.status);
        });
      }
    }
  }

}
