import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { routerModule } from "./users.routing";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UtilitiesModule } from "../utilities/utilities.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { UsersComponent } from './users.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UsersService } from '../api/users.service';
import { UserAddServiceModalComponent } from './modals/user-add-service-modal/user-add-service-modal.component';
import { UserAddClientModalComponent } from './modals/user-add-client-modal/user-add-client-modal.component'

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    FormsModule,
    NgbModule,
    UtilitiesModule,
    TranslateModule.forChild(),
    NgxDatatableModule
  ],
  providers: [
    UsersService
  ],
  declarations: [UsersComponent, UsersListComponent, UserFormComponent, UserDetailsComponent, UserAddServiceModalComponent, UserAddClientModalComponent],
  entryComponents: [
    UserAddServiceModalComponent,
    UserAddClientModalComponent
  ]
})
export class UsersModule { }
