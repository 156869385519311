import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ServiceAvailabilityService } from "../../api/service-availability.service";
import * as Moment from "moment";
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { ServicesService } from './../../api/services.service';

@Component({
  selector: 'service-prices-edit',
  templateUrl: './service-prices-edit.component.html',
  styleUrls: ['./service-prices-edit.component.scss'],
  providers: [TranslatePipe]
})
export class ServicePricesEditComponent implements OnInit, OnDestroy {
  intervalsToArray: any[];
  intervalsFromArray: any[];

  availability;
  serviceId;
  transitionHook: any;
  dataChanged: boolean;
  changes = {
    changed: false
  };

  baseCost:number;

  @ViewChild('formRef')
  formRef;

  constructor(private availabilityService: ServiceAvailabilityService,
              private stateService: StateService,
              private translatePipe: TranslatePipe,
              private toastr: ToastrService,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private servicesService: ServicesService,
              private transitionService: TransitionService) {
                this.transitionHook = transitionService.onBefore({from: 'services.**'}, (trans: Transition)=>{
                  return changesValidationService.validate(this.changes);
                })
              }

  ngOnInit() {
    this.serviceId = this.stateService.params.id;

    this.availabilityService.getAvailability(this.serviceId)
      .subscribe( res => {
        console.log(res)
        this.availability = res;

        for (let i = 0; i < this.availability.hours.length; i++) {
          if (this.availability.hours[i].available) {
            this.createHours(this.availability.hours[i], this.availability.interval);
          }
        }
        this.checkChangesService.assignObject(this.changes);
        this.changesValidationService.resetResolved();

      })

    this.servicesService.getService(this.serviceId)
      .subscribe(
        (res: any) => {
          this.baseCost = res.price;
        },
        err => console.log(err)
      )

  }

  ngOnDestroy() {
    this.transitionHook();
  }

  createHours(day, interval) {
    // if empty array create default value
    if (day.prices.length == 0) {
      day.prices.push({
        from:'00:00',
        to:'24:00',
        cost:''
      });
    }

    let times = [];
    day.currentFrom = "From";
    day.currentTo = "To";
    for (let i = 0; i < day.open.length; i++) {
      let startDay = Moment(day.open[i].from, 'HH:mm');
      let endDay = Moment(day.open[i].to, 'HH:mm');
      let t = endDay.diff(startDay, 'm') / interval;

      while (t--) {
        let hourObject = {
          from: startDay.format('HH:mm'),
          to: ""
        }
        startDay.add(interval, 'minute');
        if (t < 1) {
          hourObject.to = startDay.format('kk:mm')
        } else {
          hourObject.to = startDay.format('HH:mm')
        }
        times.push(hourObject)
      }

      day.intervals = times;
      day.intervalsFromArray = JSON.parse(JSON.stringify(times));
      day.intervalsToArray = JSON.parse(JSON.stringify(times));
    }
  }

  editPrice(day, from, to, newCost, invalid){

    this.intervalsFromArray = day.intervals;
    this.intervalsToArray = day.intervals;

    if (invalid || from === "From" || to === "To") {
      return
    }

    this.changes = {changed:true};

    let newPrices = [];

    for (let i = 0; i < day.prices.length; i++) {
      let start = Moment(day.prices[i].from, 'HH:mm');
      let end = Moment(day.prices[i].to, 'HH:mm');
      let oldCost = day.prices[i].cost;

      let newStart = Moment(from, 'HH:mm');
      let newEnd = Moment(to, 'HH:mm');

      if (newStart.isAfter(newEnd)) {
        newStart = Moment(to, 'HH:mm');
        newEnd = Moment(from, 'HH:mm');
      }

      let newObj = {
        from: newStart.format('HH:mm'),
        to: newEnd.format('kk:mm'),
        cost: parseInt(newCost)
      }

      if (newStart.isSameOrAfter(end)) {
        newPrices.push({
          from: start.format('HH:mm'),
          to: end.format('HH:mm'),
          cost: oldCost,
        })
        continue;
      }

      if (newStart.isSame(start)) {
        newPrices.push(newObj)
      } else if (newStart.isBetween(start, end, null, '()')) {
        newPrices.push({
          from: start.format('HH:mm'),
          to: newStart.format('HH:mm'),
          cost: oldCost
        }, newObj)
      }

      if (newEnd.isSameOrAfter(end)) {
        if (newEnd.isSameOrBefore(start)) {
          newPrices.push({
            from: start.format('HH:mm'),
            to: end.format('HH:mm'),
            cost: oldCost,
          })
        }
        continue;
      } else if (newEnd.isBetween(start, end, null, '()')) {
        newPrices.push({
          from: newEnd.format('HH:mm'),
          to: end.format('kk:mm'),
          cost: oldCost,
        })
      } else if (newEnd.isSameOrBefore(start)) {
        newPrices.push({
          from: start.format('HH:mm'),
          to: end.format('HH:mm'),
          cost: oldCost,
        })
      }
    }

    let closedHours = []

    for (let i = 0; i < day.open.length - 1; i++) {
      closedHours.push({
        from: Moment(day.open[i].to, 'HH:mm'),
        to: Moment(day.open[i + 1].from, 'HH:mm')
      })
    }

    for (let i = 0; i < newPrices.length; i++) {
      for (let j = 0; j < closedHours.length; j++) {
        let fromMoment = Moment(newPrices[i].from, "HH:mm");
        let toMoment = Moment(newPrices[i].to, "HH:mm");

        if (closedHours[j].to.isBetween(fromMoment, toMoment, null, "()")) {
          newPrices.splice(i, 1, {
            from: newPrices[i].from,
            to: closedHours[j].from.format('HH:mm'),
            cost: newPrices[i].cost
          }, {
            from: closedHours[j].to.format('HH:mm'),
            to: newPrices[i].to,
            cost: newPrices[i].cost
          })
        }
      }
    }


    day.prices = JSON.parse(JSON.stringify(newPrices));
    day.addCost = false;
    from = "From";
    to = "To";
    day.currentFrom = "From";
    day.currentTo = "To";

    newCost = "";
    this.createHours(day, this.availability.interval);
    this.formRef.reset();
  }

  save(ava) {
    this.changesValidationService.resolved = true;
    this.availabilityService.updateAvailability(this.serviceId, ava)
      .subscribe( res => {
        this.toastr.success(this.translatePipe.transform(_i18n('service.prices-edit.toast-messages.success.price-saved')), 'Success!')
        this.stateService.go('services.details', {id: this.serviceId})
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
  }

  cancel() {
    this.stateService.go('services.details', {id: this.serviceId})
  }

  translate(day:string) {
    return this.translatePipe.transform(_i18n(`weekday.${day}`));
  }

  deletePrice(deletedPrice:any, day:any) {

    for (let i = 0; i < day.prices.length; i++) {
      let current, next, prev, deletedPriceIndex;
      current = day.prices[i];
      deletedPriceIndex = day.prices.indexOf(deletedPrice);
      if (day.prices[i + 1]) { next = day.prices[i + 1]; }
      if (day.prices[i - 1]) { prev = day.prices[i - 1]; }

      if (current == deletedPrice) {
        // one element
        if ((!prev) && (!next)) {
          this.editPrice(day,current.from, current.to, current.cost,false);
        }

        // first element
        if (!prev && next) {
          if (next.to == '00:00') { next.to = '24:00' }

          if (current.cost == next.cost) {
            if ((current.from == '00:00') && (next.to == '00:00')) {
              day.prices = [];
              day.prices.push({
                from: '00:00',
                to: '24:00',
                cost: current.cost
              })
            } else {
              this.editPrice(day,current.from, next.to, current.cost,false);
            }
          } else {
            this.editPrice(day, current.from, current.to, this.baseCost, false);
            if (current.cost == next.cost) {
              this.editPrice(day,current.from, next.from, current.cost, false);
            }
          }
        }

        // middle element
        if (prev && next) {
          if (next.to == '00:00') { next.to = '24:00' }

          if ((prev.from == '00:00') && (next.to == '24:00')) {
            day.prices = [];
            day.prices.push({
              from: '00:00',
              to: '24:00',
              cost: current.cost
            })
          }
          if ((prev.cost == current.cost) && (current.cost == next.cost)) {
            if ((prev.from == '00:00') && (next.to == '24:00')) {
              day.prices = [];
              day.prices.push({
                from: '00:00',
                to: '24:00',
                cost: current.cost
              })
            } else {
              this.editPrice(day, prev.from, next.to, current.cost, false);
            }
          }
          if ((prev.cost != current.cost) && (current.cost == next.cost)) {
            this.editPrice(day, current.from, next.to, current.cost, false);
          }
          if ((prev.cost == current.cost) && (current.cost != next.cost)) {
            this.editPrice(day, prev.from, current.to, current.cost, false);
          }
          if ((prev.cost != current.cost) && (current.cost != next.cost)) {
            if (prev.cost == next.cost) {
              this.editPrice(day, prev.from, next.to, prev.cost, false);
            } else {
              this.editPrice(day, current.from, current.to, this.baseCost, false);
            }
          }
        }

        // last element
        if(!next && prev) {
          if (current.to == '00:00') { current.to = '24:00' }

          if (current.cost == prev.cost) {
            if ((prev.from == '00:00') && (current.to == '24:00')) {
              day.prices = [];
              day.prices.push({
                from: '00:00',
                to: '24:00',
                cost: current.cost
              })
            } else {
              this.editPrice(day,prev.from, current.to, current.cost, false);
            }
          } else {
            this.editPrice(day, current.from, current.to, this.baseCost, false);
          }
        }
      }
    }
  }

  createToTimesArray(current, day, intervals) {
    let array = JSON.parse(JSON.stringify(intervals));

    this.intervalsFromArray = [];
    this.intervalsToArray = [];
    if(current) {
      if (current == "00:00") {
        day.intervalsToArray = array.splice(0, array.length);
        if (day.currentTo == "To" || day.currentTo == null) {
          day.currentTo = day.intervalsToArray[day.intervalsToArray.length - 1].to
        }
      } else {
        for(let i = 0; i < array.length; i++) {
          if (array[i].from == current) {
            day.intervalsToArray = array.splice(i, array.length);
            if (day.currentTo == "To" || day.currentTo == null) {
              day.currentTo = day.intervalsToArray[day.intervalsToArray.length - 1].to
            }
          }
        }
      }

    }
  }

  createFromTimesArray(current, day, intervals) {
    let array = JSON.parse(JSON.stringify(intervals));

    this.intervalsFromArray = [];
    this.intervalsToArray = [];
    if(current) {
      if (current == "24:00") {
        day.intervalsFromArray = array.splice(0, 24);
        if (day.currentFrom == "From" || day.currentFrom == null) {
          day.currentFrom = day.intervalsFromArray[0].from
        }
      } else {
        for(let i = 0; i < array.length; i++) {
          if (array[i].from == current) {
            day.intervalsFromArray = array.splice(0, i);
            if (day.currentFrom == "From" || day.currentFrom == null) {
              day.currentFrom = day.intervalsFromArray[0].from
            }
          }
        }
      }
    }
  }
}
