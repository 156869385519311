import { Component, OnInit } from '@angular/core';
import { FinancialStatsService } from 'app/api/financial-stats.service';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'financial-stats-list',
  templateUrl: './financial-stats-list.component.html',
  styleUrls: ['./financial-stats-list.component.scss']
})
export class FinancialStatsListComponent implements OnInit {

  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  }

  service$: Observable<any>;

  constructor(
    private financialStatsService: FinancialStatsService,
  ) { }

  ngOnInit() {
    this.getServices();
  }

  getServices() {
    this.service$ = this.financialStatsService.getServices(this.page.pageNumber)
      .pipe(
        tap((pagedData) => {
          this.page.totalElements = pagedData.size;
          this.page.size = pagedData.per_page;
        }),
      )
      .map(data => data.data)
  }

  setPage(page: any) {
    this.page.pageNumber = page.offset + 1;
    this.getServices();
  }

}
