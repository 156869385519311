import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from "@ngx-translate/core";

import { ContactComponent } from './contact.component';
import { SponsorContactComponent } from './sponsor-contact/sponsor-contact.component';
import { routerModule } from "./contact.routing";
import { ContactService } from  '../api/contact.service';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    TranslateModule.forChild()
  ],
  providers: [
    ContactService
  ],
  declarations: [ContactComponent, SponsorContactComponent]
})
export class ContactModule { }
