import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServicesService } from '../../api/services.service';
import { UIRouter, StateService, TransitionService, Transition } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { ServiceChangesCheckService } from "app/utilities/service-changes-check/service-changes-check.service";

@Component({
  selector: 'service-edit',
  templateUrl: './service-edit.component.html',
  styleUrls: ['./service-edit.component.scss'],
  providers: [TranslatePipe]
})
export class ServiceEditComponent implements OnInit, OnDestroy {

  submitted: boolean = false;
  service:any = {};
  savingInProgress:boolean = false;
  transitionHook: any;
  dirty:boolean = false;
  disciplines: any;
  cancellation = [
    {
      name: "odwołanie darmowe bez względu na czas odwołania",
      value: "0"
    },
    {
      name: "5 dni przed terminem usługi",
      value: "120"
    },
    {
      name: "3 dni przed terminem usługi",
      value: "72"
    },
    {
      name: "24 godziny przed terminem usługi",
      value: "24"
    },
    {
      name: "12 godzin przed terminem usługi",
      value: "12"
    },
    {
      name: "6 godzin przed terminem usługi",
      value: "6"
    },
    {
      name: "brak możliwości darmowego odwołania usługi",
      value: "-1"
    },
  ]

  constructor(private servicesService:ServicesService,
              private route:UIRouter,
              private stateService:StateService,
              private translatePipe: TranslatePipe,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService,
              private checkService: ServiceChangesCheckService,
              private toastr: ToastrService) {

                this.transitionHook = this.transitionService.onBefore({from: 'services.edit'}, (trans: Transition) => {
                  return this.changesValidationService.validate(this.service);
                })
              }

  ngOnInit() {
    let id = this.route.globals.params.id;
    this.servicesService.getService(id)
    .subscribe((service: any) => {
      let serviceObject = {
        id: service.id,
        name: service.name,
        description: service.description,
        disciplines: service.disciplines,
        service_type: service.service_type,
        cancellation_before_time: service.cancellation_before_time,
        user: service.hosts[0].id,
        privacy: String(service.privacy),
      }
      this.service = serviceObject;
      this.checkService.assignObject(this.service);

      this.servicesService.getDisciplinesStream()
        .subscribe((res) => {
          if (res) {
            this.disciplines = JSON.parse(JSON.stringify(res));
            if (this.disciplines[this.disciplines.length - 1].id == 0) {
              this.disciplines.splice(this.disciplines.length - 1, 1)
            }
            for (let discipline of this.service.disciplines) {
              for (let globalDiscipline of this.disciplines) {
                if (globalDiscipline.id == discipline.id) {
                  globalDiscipline["selected"] = true;
                }
              }
            }
          }
        })


    }, err => {
      console.error(err);
      this.toastr.error(err.statusText, err.status);
    })
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  save(formRef, service) {
    this.submitted = true;

    if (!this.service.name || !this.service.description || (this.service.service_type == "service" && !this.disciplineSelected())) {
      return
    }
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;

    let discipline_ids = []
    for (let i = 0; i < this.disciplines.length; i++) {
      if (this.disciplines[i]['selected']) {
        discipline_ids.push(this.disciplines[i].id)
      }
    }
    service['discipline_ids'] = JSON.stringify(discipline_ids);

    this.servicesService.editService(service)
      .subscribe(
        service => {
          this.toastr.success(this.translatePipe.transform(_i18n('service.edit.toast-messages.success.service-edited')), 'Success!');
          this.savingInProgress = false;
          this.dirty = false;
          this.checkService.assignObject(this.service);
        },
        err => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
          this.savingInProgress = false;
          this.dirty = false;
        }
      )
  }

  disciplineSelected() {
    let selectedDiscipline = false
    for (let discipline of this.disciplines) {
      if (discipline["selected"] == true) {
        selectedDiscipline = true
      }
    }
    return selectedDiscipline;
  }

  cancel() {
    this.checkService.assignObject(this.service);
    this.stateService.go('services.details', {id: this.route.globals.params.id});
  }

  modelChange() {
    this.dirty = true;
  }

  switchServicePrivacy(privacy) {
    this.service.privacy = privacy;
    this.modelChange();
  }

}
