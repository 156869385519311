import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotModalComponent } from '../forgot-modal/forgot-modal.component'
import { _i18n } from '../translation-marker/translation-marker';

@Component({
  selector: 'user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

  @Input() user: any = {};
  @Input() displayOnly = false;
  @Input() simple = false;
  @Input() header = _i18n('utilies.user-card.header');

  constructor(public modalService: NgbModal) { }

  ngOnInit() {
  }

  resetPassword(user) {
    const modalRef = this.modalService.open(ForgotModalComponent, {windowClass: 'align-modal-center forgot-modal'});
    modalRef.componentInstance.email.next(user.email);
    modalRef.componentInstance.email.complete();
  }

}
