import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { TranslatePipe } from '@ngx-translate/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import * as Moment from 'moment';

import { HappeningsService } from '../../api/happenings.service';

@Component({
  selector: 'happenings-dates',
  templateUrl: './happenings-dates.component.html',
  styleUrls: ['./happenings-dates.component.scss'],
  providers: [TranslatePipe]
})
export class HappeningsDatesComponent implements OnInit {

  dates: any;
  baseInterval: number = 15;
  timesFrom = [];
  timesTo = [];

  emptyPrice = {
    price: null,
    date_from: null,
    date_to: null,
    time_from: '00:00',
    time_to: '24:00',
    from: null,
    to: null
  };

  minDate: any;

  newTerms = [];

  constructor(
    private route: UIRouter,
    private translatePipe: TranslatePipe,
    private happeningsService: HappeningsService,
  ) { }

  ngOnInit() {
    this.getHappeningSchedules();
    this.createTimesFrom(this.baseInterval, '00:00', '24:00');
    this.createTimesTo(this.baseInterval, '00:00', '24:00');
    const today = new Date();
    this.minDate = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
  }

  getHappeningSchedules() {
    this.happeningsService.getHappeningSchedules(this.route.globals.params.id)
      .subscribe(
        res => {
          this.dates = res;
          for (const date of this.dates) {
            date.bookings_count = date.bookings.filter(booking => booking.status !== 'canceled').length;
          }
        }
      );
  }

  createTimesFrom(interval, from, to) {
    this.timesFrom = [];
    let fromMoment = Moment(from, 'HH:mm');
    let toMoment = Moment(to, 'HH:mm');

    let t = toMoment.diff(fromMoment, 'm') / interval;
    while (t--) {
      this.timesFrom.push(fromMoment.format('HH:mm'));
      fromMoment.add(interval, 'minute');
    }
  }

  createTimesTo(interval, from, to) {
    this.timesTo = [];
    let fromMoment = Moment(from, 'HH:mm');
    let toMoment = Moment(to, 'HH:mm');

    let t = toMoment.diff(fromMoment, 'm') / interval;
    fromMoment.add(interval, 'minute');
    while (t--) {
      if (t < 1) {
        this.timesTo.push(fromMoment.format('kk:mm'));
      } else {
        this.timesTo.push(fromMoment.format('HH:mm'));
      }
      fromMoment.add(interval, 'minute');
    }
  }

  translateStatus(status) {
    return this.translatePipe.transform(_i18n(`service.status.${status}`));
  }

  cancelBooking(booking) {
    this.happeningsService.cancelBooking(this.route.globals.params.id, booking.id)
      .subscribe(
        res => {
          booking.status = 'canceled';
        }
      );
  }

  editDate(date) {
    date.editing = true;
    date.changes = {};
  }

  cancelDateEdit(date) {
    date.editing = false;
    delete date.changes;
  }

  saveDate(date) {
    const formData: FormData = new FormData();

    let happeningSchedules = [];

    let schedule = {
      id: date.id
    }

    if (date.changes.date_from && date.changes.time_from && date.bookings.length == 0) {
      const start_date = `${Moment().year(date.changes.date_from.year).month(date.changes.date_from.month - 1).date(date.changes.date_from.day).format('YYYY-MM-DD')} ${date.changes.time_from}`;
      schedule['start_date'] = start_date;
    }

    if (date.changes.date_to && date.changes.time_to && date.bookings.length == 0) {
      const end_date = `${Moment().year(date.changes.date_to.year).month(date.changes.date_to.month - 1).date(date.changes.date_to.day).format('YYYY-MM-DD')} ${date.changes.time_to}`;
      schedule['end_date'] = end_date;
    }

    if (date.users_limit != date.changes.users_limit) {
      schedule['users_limit'] = date.changes.users_limit;
    }

    happeningSchedules.push(schedule);

    formData.append('happening_schedules_attributes', JSON.stringify(happeningSchedules));

    this.happeningsService.updateService(this.route.globals.params.id, formData)
      .subscribe(
        res => {
          this.getHappeningSchedules();
        },
        err => {
          console.info(err);
        }
      )
  }

  editVariant(variant) {
    variant.editing = true;
    variant.changes = {
      name: variant.name,
      price: variant.price
    };
  }

  saveVariant(v) {
    const formData: FormData = new FormData();

    let happeningSchedules = [];

    let schedule = {
      id: v.happening_schedule_id
    }

    let happening_schedule_variants_attributes = []
    let attr = {
      service_prices_attributes: []
    };

    if (v.changes && v.changes.name) {
      attr['name'] = v.changes.name;
    }

    if (v.changes && v.changes.price) {
      attr['price'] = v.changes.price;
    }

    let happening_prices = [];
    for (let service_price of v.changes.service_prices_attributes) {
      if (service_price['_destroy']) {
        happening_prices.push(service_price);
      } else {
        const valid_from = `${Moment().year(service_price.date_from.year).month(service_price.date_from.month - 1).date(service_price.date_from.day).format('YYYY-MM-DD')} ${service_price.time_from}`;
        const valid_to = `${Moment().year(service_price.date_to.year).month(service_price.date_to.month - 1).date(service_price.date_to.day).format('YYYY-MM-DD')} ${service_price.time_to}`;

        let priceObject = {
          price: service_price.price,
          valid_from: valid_from,
          valid_to: valid_to,
        };

        if (service_price.id) {
          priceObject['id'] = service_price.id;
        }

        happening_prices.push(priceObject);
      }
    }
    attr.service_prices_attributes = happening_prices;

    if (v.id) {
      attr['id'] = v.id;
    }
    if (v['_destroy']) {
      attr['_destroy'] = '1';
    }
    happening_schedule_variants_attributes.push(attr);

    schedule['happening_schedule_variants_attributes'] = happening_schedule_variants_attributes;

    happeningSchedules.push(schedule);

    formData.append('happening_schedules_attributes', JSON.stringify(happeningSchedules));

    this.happeningsService.updateService(this.route.globals.params.id, formData)
      .subscribe(
        res => {
          this.getHappeningSchedules();
        },
        err => {
          console.info(err);
        }
      )
  }

  removeVariant(v) {
    let r = confirm('Czy na pewno chcesz usunąć wariant?')
    if (r === true) {
      v['_destroy'] = '1';

      let service_prices_attributes = [];
      for (let service_price of v.service_prices) {
        service_prices_attributes.push({
          id: service_price.id,
          _destroy: '1',
        });
      }

      v.changes = {
        service_prices_attributes: service_prices_attributes
      };

      this.saveVariant(v);
    }
  }

  cancelVariantEdit(v) {
    v.editing = false;
    delete v.changes;
  }

  addVariant(date) {
    date.additionalVariant = {
      changes: {
        name: null,
        price: null,
        service_prices_attributes: [
          {
            price: null,
            date_from: null,
            date_to: null,
            time_from: '00:00',
            time_to: '24:00',
            from: null,
            to: null
          },
        ]
      }
    };
  }

  saveAdditionalVariant(date, v) {
    v.happening_schedule_id = date.id;
    delete date.additionalVariant;
    this.saveVariant(v);
  }

  cancelAdditionalVariantEdit(date) {
    delete date.additionalVariant;
  }

  variantPriceInputChanged(v) {
    v.price = parseInt(v.price, 10) < 0 ? String(0) : v.price;
  }

  addPrice(arr) {
    const price = JSON.parse(JSON.stringify(this.emptyPrice));
    arr.push(price);
  }

  removePrice(variant, price) {
    let r = confirm('Czy na pewno chcesz usunąć cene?')
    if (r === true) {
      const data = {
        id: variant.id,
        happening_schedule_id: variant.happening_schedule_id,
        changes: {
          service_prices_attributes: [
            {
              id: price.id,
              _destroy: '1'
            }
          ]
        }
      };
      this.saveVariant(data);
    }
  };

  editPrice(price) {
    price.editing = true;
  }

  cancelPriceEdit(price) {
    price.editing = false;
  }

  savePriceEdit(variant, price) {
    const data = {
      id: variant.id,
      happening_schedule_id: variant.happening_schedule_id,
      changes: {
        service_prices_attributes: [price]
      }
    };
    this.saveVariant(data);
  }

  addAdditionalPrice(variant) {
    variant.additionalPrice = {
      price: null,
      date_from: null,
      date_to: null,
      time_from: '00:00',
      time_to: '24:00',
      from: null,
      to: null
    };
  }

  cancelAdditionalPriceEdit(variant) {
    delete variant.additionalPrice;
  }

  saveAdditionalPrice(variant) {
    const data = {
      id: variant.id,
      happening_schedule_id: variant.happening_schedule_id,
      changes: {
        service_prices_attributes: [variant.additionalPrice]
      }
    };
    this.saveVariant(data);
  }

  newDate() {
    this.newTerms.push({
      date_from: '',
      time_from: '00:00',
      date_to: '',
      time_to: '24:00',
      users_limit: '',
      happening_schedule_variants_attributes: [
        {
          name: '',
          price: '',
          service_prices_attributes: [
            {
              price: null,
              date_from: null,
              date_to: null,
              time_from: '00:00',
              time_to: '24:00',
              from: null,
              to: null
            },
          ]
        }
      ]
    });
  }

  saveNewTerm(term) {
    this.newTerms = [];
  }

  cancelNewTerm() {
    this.newTerms = [];
  }

  canEdit(date) {
    return date.bookings_count === 0;
  }

}
