import { Component, OnInit, Input } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, Observer, forkJoin } from 'rxjs';
import { _i18n } from './../../utilities/translation-marker/translation-marker';

@Component({
  selector: 'forgot-modal',
  templateUrl: './forgot-modal.component.html',
  styleUrls: ['./forgot-modal.component.scss']
})
export class ForgotModalComponent implements OnInit {

  error;
  completed: boolean;

  public email: Subject < any >
    activeEmail: any;

  @Input()
  message = _i18n('utilies.forgot-modal.message.question')

  constructor(private _tokenService: AngularTokenService,
    public activeModal: NgbActiveModal) {
    this.email = new Subject();
    forkJoin([this.email]).subscribe(([email]) => {
      this.activeEmail = email;
    })
  }


  ngOnInit() {}

  remindPassword() {
    this._tokenService.resetPassword({
      login: this.activeEmail
    })
    .subscribe(
      res => {
        this.completed = true;
        this.message = _i18n('utilies.forgot-modal.message.email-sended');
      },
      error => {
        this.error = JSON.parse(error._body);
      }
    )
  }

  close() {
    this.activeModal.close();
  }

}
