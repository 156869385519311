import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AngularTokenService } from 'angular-token';
import { StateService } from "@uirouter/angular";
import { Ng2Cable, Broadcaster } from 'ng2-cable';
import { environment } from "../../environments/environment";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MessagesService {
  unreadMessages = 0;
  navMessages:any;
  messagesStream$ = new Subject();

  constructor(
    private http: HttpClient,
    private tokenService: AngularTokenService,
    public stateService: StateService,
    private ng2cable: Ng2Cable,
    private broadcaster: Broadcaster
  ) {
      this.setMessagesChannel();
      this.broadcaster.on<any>('MessagesChannel').subscribe(
        message => {
          this.unreadMessages = this.unreadMessages + 1;
          this.messagesStream$.next({type: "message", data: [message]})
        }
      );
    }

  setMessagesChannel() {
    this.tokenService.validateToken().subscribe(
      res => {
        this.subscribeToMessagesChannel()
      },
      error => {
        this.unsubscribeFromMessagesChannel()
      }
    );
  }

  subscribeToMessagesChannel() {
    this.ng2cable.subscribe(environment.apiBase + `/cable?access-token=${localStorage.accessToken}&uid=${localStorage.uid}&client=${localStorage.client}`, 'MessagesChannel', { accessToken: localStorage.accessToken, uid: localStorage.uid });
  }

  unsubscribeFromMessagesChannel() {
    if (this.ng2cable.cable) {
      this.ng2cable.unsubscribe();
    }
    this.messagesStream$.next({data: []})
  }

  setUnreadConversations() {
    let url = `${environment.apiBase}/conversations`;
    return this.http.get(url)
      .subscribe((res: any) => {
        this.unreadMessages = res.unread
        this.messagesStream$.next(this.unreadMessages)
      });
  }

  getConversations() {
    let url = `${environment.apiBase}/conversations`;
    return this.http.get(url);
  }

  getConversationMessages(conversationId, page, offset){
    let url = `${environment.apiBase}/conversations/${conversationId}/messages?page=${page}&offset=${offset}`;
    return this.http.get(url);
  }

  sendMessages(conversationId, data){
    let url = `${environment.apiBase}/conversations/${conversationId}/messages`;
    return this.http.post(url, data);
  }

  getMessagesStream() {
    return this.messagesStream$.asObservable();
  }

  getNavMessageNotificationsStream() {
    if (!this.navMessages && this.tokenService.userSignedIn()) {
      this.getNavMessages();
    }
    return this.getMessagesStream()
  }

  getNavMessages() {
    this.getConversations()
      .subscribe((res: any) => {
        this.navMessages = res
        this.unreadMessages = this.unreadMessages + res.unread
        this.messagesStream$.next(this.navMessages)
      })
  }

}
