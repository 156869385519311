import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { BookingsService, Booking } from '../../api/bookings.service';
import { StateService } from '@uirouter/angular';
import { ServicesService } from '../../api/services.service';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarDateFormatter } from 'angular-calendar';
import { DashboardReservationModalComponent } from './dashboard-reservation-modal/dashboard-reservation-modal.component';
import { AngularTokenService } from 'angular-token';
import { FacilitiesService } from './../../api/facilities.service';

import * as Moment from 'moment';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { Times } from '../../utilities/day-view-scheduler-time-selector/day-view-scheduler-time-selector.component';

const colors: any = {
  red: {
    primary: '#c0392b',
    secondary: '#c0392b'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#f50',
    secondary: '#f50'
  },
  green: {
    primary: '#27ae60',
    secondary: '#27ae60',
  }
};

const RED_CELL: 'red-cell' = 'red-cell';
const BLUE_CELL: 'blue-cell' = 'blue-cell';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
   {
     provide: CalendarDateFormatter,
     useClass: CustomDateFormatter
   }
 ]
})
export class DashboardComponent implements OnInit, AfterViewChecked {
  services: Array < object > = [];
  bookableServices: Array < object > = [];
  profile: any;
  bookings: Booking;
  pagination;
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  loading = false;
  inited: boolean = false;
  q = {
    date_eq: ''
  };
  sort: any;
  dateCopy: any;
  date: any;
  subdate: string;
  selectedService: any;
  dayStartHour: number = 0;
  dayEndHour: number = 23;
  calContainerStyle = {height: '350px'};

  constructor(
    private bookingsService: BookingsService,
    private servicesService: ServicesService,
    private stateService: StateService,
    private modalService: NgbModal,
    private facilityService: FacilitiesService,
    public _tokenService: AngularTokenService,
  ) {
    this.page.pageNumber = 0;
  }

  ngAfterViewChecked() {
    const calendarHeader = document.getElementById('calendar-header')
    if (calendarHeader) {
      this.calContainerStyle = {
        height: (window.innerHeight - calendarHeader.clientHeight - 42 - 30) + 'px'
      }
    }
  }

  ngOnInit() {
    this._tokenService.validateToken().subscribe(
      response => {
        if (response.data.events_creation_enabled === true) {
          this.stateService.go('facilities-transition');
        } else {
          this.facilityService.getServices(1, {}, 'asc')
          .subscribe(
            (res: any) => {
              this.profile = res.data[0];
            }
          );
          this.servicesService.getAllVerifiedServices()
          .subscribe((res: any) => {
            this.inited = true;
            this.services = res.data;
            for (let i = 0; i < this.services.length; i++) {
              if (this.services[i]['category'] === 'bookable') {
                this.bookableServices.push(this.services[i]);
              }
            }
          });
          this.setPage({
            offset: 0
          });

          this.setView('month', 'month');
        }
      }
    )
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.q['status_not_in'] = ['canceled', 'waiting_for_approval', 'rejected'];
    this.bookingsService.getBookings(this.page.pageNumber + 1, this.q, this.sort)
      .subscribe((pagedData: any) => {
        this.page.totalElements = pagedData.size;
        this.page.size = pagedData.per_page;
        this.bookings = pagedData.data;
        this.viewTable = true;
      });
  }

  onSort(event) {
    this.loading = true;
    const s = event.sorts[0];
    let prop = s.prop.replace(/\./g, '_');
    if (prop === 'user_email') {
      prop = 'users_email';
    } else if (prop === 'user_first_name') {
      prop = 'users_first_name';
    } else if (prop === 'user_last_name') {
      prop = 'users_last_name';
    } else if (prop === 'service_service_variant_name') {
      prop = 'service_variant_name';
    }
    this.sort = prop + ' ' + s.dir;
    this.q['status_not_in'] = ['canceled', 'waiting_for_approval', 'rejected'];
    this.bookingsService.getBookings(0, this.q, this.sort)
      .subscribe((res: any) => {
        this.bookings = res.data;
        this.loading = false;
      });
  }

  onFilter(event) {
    this.page.totalElements = event.size;
    this.page.size = event.per_page;
    this.bookings = event.data;
  }

  onQueryChange(event) {
    this.q = event;
  }

  dateParser(date) {
    if (date) {
      return date.year + '-' + (date.month < 10 ? '0' + date.month : date.month) + '-' + (date.day < 10 ? '0' + date.day : date.day);
    }
  }

  datePicked(date) {
    if (date) {
      this.q.date_eq = this.dateParser(date);
      this.setPage({
        offset: 0
      });
    }
  }

  pickDate(date) {
    this.q.date_eq = this.dateParser(date);
    if (this.q.date_eq == this.dateCopy) {
      this.q.date_eq = '';
      this.setPage({
        offset: 0
      });
      this.date = undefined;
    }
    this.dateCopy = this.q.date_eq;
  }

  openReservationModal() {
    const modalRef = this.modalService.open(DashboardReservationModalComponent);
    modalRef.componentInstance.success.subscribe(
      data => {
        this.handleDateChange(data);
      }
    )
  }

  view: string = 'month';
  displayedView = 'month';
  dayScheduleView = false;

  clickedDate: string = '';
  viewDate: Date = new Date();

  refresh: Subject<any> = new Subject();

  cssClass: string = RED_CELL;

  refreshView(): void {
    this.cssClass = this.cssClass === RED_CELL ? BLUE_CELL : RED_CELL;
    this.refresh.next();
  }

  events = [];

  dayClicked(date, newEvent?) {
    this.viewDate = date;
    this.setView('day', 'day', newEvent);
  }

  viewTitle: string;

  setView(view, title, newEvent?) {
    this.view = view;
    this.displayedView = title;
    if (title == 'schedule') {
      this.dayScheduleView = true;
    } else {
      this.dayScheduleView = false;
    }
    this.setViewTitles(title);
    if (newEvent) {
      this.handleDateChange(newEvent);
    } else {
      this.handleDateChange();
    }

  }

  scrollToBooking(booking) {
    let element = document.getElementsByClassName('cal-event')[0];
    if (element) {
      let offset = this.calculateScrollToEventOffset(booking);
      window.scrollTo(0, offset);
    }
  }

  private calculateScrollToEventOffset(event): number {
    let rowHeight = document.getElementsByClassName('cal-hour-segment')[0] as any;
    rowHeight = window.getComputedStyle(rowHeight).getPropertyValue('height');
    rowHeight = rowHeight.replace('px', '');
    rowHeight = parseInt(rowHeight);
    let eventHours = parseInt(event.time_from.split(':')[0]);
    let eventMinutes = parseInt(event.time_from.split(':')[1]);

    return (rowHeight * (eventHours * 4)) + (rowHeight * (eventMinutes / 15));
  }

  setViewToSchedule() {
    this.dayScheduleView = true;
    this.setViewTitles('schedule');
  }

  setViewTitles(view) {
    let viewTitle;
    let subdate;

    if (view === 'month') {
      viewTitle = Moment(this.viewDate).locale('pl').format('MM.YYYY');
      subdate = Moment(this.viewDate).locale('pl').format('MMMM');
    } else if (view === 'day') {
      viewTitle = Moment(this.viewDate).locale('pl').format('DD.MM.YYYY');
      subdate = Moment(this.viewDate).locale('pl').format('dddd');
    } else if (view === 'schedule') {
      viewTitle = 'Rezerwacje';
    } else if (view === 'week') {
      viewTitle = `${Moment(this.viewDate).subtract(1, 'days').locale('pl').format('DD')} - ${Moment(this.viewDate).add('days', 5).locale('pl').format('DD')}${Moment(this.viewDate).add('days', 5).locale('pl').format('.MM.YYYY')}`;
      subdate = '';
    }

    this.viewTitle = viewTitle;
    this.subdate = subdate;
  }

  handleDateNextOrPrev() {
    this.setViewTitles(this.displayedView);
    this.handleDateChange();
  }

  handleDateChange(newEvent?) {
    let q = {status_not_in: ['canceled', 'waiting_for_approval', 'rejected']};
    let s = '';
    let unlimited = false;
    if (this.displayedView == 'month') {
      q['date_gteq'] = Moment(this.viewDate).startOf('month').format('YYYY-MM-DD');
      q['date_lteq'] = Moment(this.viewDate).endOf('month').format('YYYY-MM-DD');
      unlimited = true;
    } else if (this.displayedView == 'week') {
      q['date_gteq'] = Moment(this.viewDate).startOf('week').add(1, 'day').format('YYYY-MM-DD');
      q['date_lteq'] = Moment(this.viewDate).endOf('week').add(1, 'day').format('YYYY-MM-DD');
      unlimited = true;
    } else if (this.displayedView == 'day') {
      q['date_eq'] = Moment(this.viewDate).format('YYYY-MM-DD');
      unlimited = true;
      s = 'time_from asc'
    } else if (this.displayedView == 'schedule') {
      if (this.view === 'day') {
        q['date_gteq'] = Moment(this.viewDate).format('YYYY-MM-DD');
        q['service_category_eq'] = 'bookable';
      } else if (this.view == 'month'){
        q['date_gteq'] = Moment(this.viewDate).startOf('month').format('YYYY-MM-DD');
        q['date_lteq'] = Moment(this.viewDate).endOf('month').format('YYYY-MM-DD');
        q['service_category_eq'] = 'bookable';
      }
      s = 'date asc';
    }
    this.events = [];
    this.bookingsService.getBookings(1, q, s, unlimited)
      .subscribe((pagedData: any) => {
        let bookings = pagedData;
        this.events = [];
        for (let booking of bookings.data) {
          booking['start'] = Moment(`${booking.date} ${booking.time_from}`);
          booking['end'] = Moment(`${booking.date} ${booking.time_to}`);
          let clientString = "";
          if (booking.user && (booking.user.first_name || booking.user.last_name)) {
            clientString = `${booking.user.first_name} ${booking.user.last_name}\n`;
          }
          let serviceString = `${booking.service.name}\n${booking.service.service_variant.name}\n`
          let timeString = `(${booking.start.format('HH:mm')} - ${booking.end.format('HH:mm')})`
          booking['title'] = `${clientString}${serviceString}${timeString}`;
          if (booking.service.calendar_event_color) {
            booking['color'] = {primary: booking.service.calendar_event_color, secondary: booking.service.calendar_event_color};
          } else {
            if (booking.presence === null) {
              booking['color'] = colors.yellow;
            } else if (booking.presence) {
              booking['color'] = colors.green;
            } else {
              booking['color'] = colors.red;
            }
          }
          booking.draggable = false;
          booking.meta = [];
          booking.meta.services = this.services;
          booking.meta.serviceId = booking.service.id;
          this.events.push(booking);
        }
        if (this.view === 'day' && this.displayedView === 'day') {
          setTimeout(() => {
            const scrollToEvent = newEvent ? newEvent[0] : this.events[0];
            if (newEvent && !Moment(this.viewDate).isSame(new Date(newEvent[0].date))) {
              this.dayClicked(new Date(newEvent[0].date), newEvent);
            } else {
              this.scrollToBooking(scrollToEvent);
            }
          }, 0)
        }
      });
  }

  eventClicked(event) {
    this.stateService.go('bookings.details', { serviceId: event.event.service.id, bookingId: event.event.id });
  }

  hourClicked(event) {
    const modalRef = this.modalService.open(DashboardReservationModalComponent);
    modalRef.componentInstance.selectedDate = event.date;
    modalRef.componentInstance.success.subscribe(
      data => {
        this.handleDateChange(data);
      }
    );
  }

  onTimeChange(times: Times) {
    this.dayStartHour = times.from;
    this.dayEndHour = times.to;
  }

  get isOwner() {
    return this._tokenService.currentUserData && this._tokenService.currentUserData['owner'];
  }
}
