import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';
import { HappeningsService } from '../../api/happenings.service';

@Component({
  selector: 'happenings-checkout-data',
  templateUrl: './happenings-checkout-data.component.html',
  styleUrls: ['./happenings-checkout-data.component.scss']
})
export class HappeningsCheckoutDataComponent implements OnInit {

  happening: any;

  emptyServiceTerm = {
    name: '',
    content: '',
    is_required: false
  };
  serviceTerms = [];

  emptyServiceCheckoutFieldInput = {
    name: '',
    placeholder: '',
    is_required: false,
  };
  serviceCheckoutFieldInputs = [];
  emptyServiceAttachment = {
    name: '',
    source: null,
  };
  serviceAttachments = [];

  constructor(
    private route: UIRouter,
    private toastr: ToastrService,
    private happeningsService: HappeningsService,
  ) { }

  ngOnInit() {
    this.happeningsService.getHappening(this.route.globals.params.id)
      .subscribe(
        res => {
          this.happening = res;
          this.serviceTerms = this.happening.service_terms;
          this.serviceCheckoutFieldInputs = this.happening.service_checkout_form_inputs;
          this.serviceAttachments = this.happening.attachments;
        }
      );
  }

  editItem(array, i) {
    const original = JSON.parse(JSON.stringify(array[i]));
    array[i].original = original;
  }

  saveItem(array, item, key) {
    const copy = JSON.parse(JSON.stringify(item));
    delete copy.original;

    const data = {
      id: this.happening.id,
    };
    data[key + '_attributes'] = JSON.stringify([copy]);

    this.happeningsService.editService(data)
      .subscribe(
        res => {
          if (array == 'serviceTerms') {
            this.serviceTerms = res[key];
          } else if (array == 'serviceCheckoutFieldInputs') {
            this.serviceCheckoutFieldInputs = res[key];
          }
          this.toastr.success('Pomyślnie zapisano zmiany');
        },
        err => {
          this.toastr.error('Błąd, spróbuj ponownie później');
        }
      );
  }

  cancelItem(array, i) {
    if (array[i].original) {
      const original = array[i].original;
      array[i] = original;
    } else {
      array.splice(i, 1);
    }
  }

  removeItem(array, item, key) {
    let r = confirm('Czy na pewno chcesz usunąć element?');
    if (r === false) {
      return;
    }

    const copy = {
      id: item.id,
      _destroy: 1
    };

    const data = {
      id: this.happening.id,
    };
    data[key + '_attributes'] = JSON.stringify([copy]);

    this.happeningsService.editService(data)
      .subscribe(
        res => {
          if (array == 'serviceTerms') {
            this.serviceTerms = res[key];
          } else if (array == 'serviceCheckoutFieldInputs') {
            this.serviceCheckoutFieldInputs = res[key];
          }
          this.toastr.success('Pomyślnie zapisano zmiany');
        },
        err => {
          this.toastr.error('Błąd, spróbuj ponownie później');
        }
      );
  }

  addServiceTerm() {
    const serviceTerm = JSON.parse(JSON.stringify(this.emptyServiceTerm));
    this.serviceTerms.push(serviceTerm);
  }
  removeServiceTerm(i) {
    let r = confirm('Czy na pewno chcesz usunąć klauzule?');
    if (r === true) {
      this.serviceTerms.splice(i, 1);
    }
  }

  addServiceCheckoutFieldInput() {
    const emptyServiceCheckoutFieldInput = JSON.parse(JSON.stringify(this.emptyServiceCheckoutFieldInput));
    this.serviceCheckoutFieldInputs.push(emptyServiceCheckoutFieldInput);
  }

  removeServiceCheckoutFieldInput(i) {
    let r = confirm('Czy na pewno chcesz usunąć klauzule?');
    if (r === true) {
      this.serviceCheckoutFieldInputs.splice(i, 1);
    }
  }

  addServiceAttachment() {
    const emptyServiceAttachment = JSON.parse(JSON.stringify(this.emptyServiceAttachment));
    this.serviceAttachments.push(emptyServiceAttachment);
  }

  removeServiceAttachment(i) {
    let r = confirm('Czy na pewno chcesz usunąć element?');
    if (r === false) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('attachments_attributes[][id]', this.happening.attachments[i].id);
    formData.append('attachments_attributes[][_destroy]', '1');
    this.happeningsService.updateService(this.happening.id, formData)
      .subscribe(
        res => {
          this.serviceAttachments = res['attachments'];
          this.toastr.success('Pomyślnie zapisano zmiany');
        },
        err => {
          this.toastr.error('Błąd, spróbuj ponownie później');
        }
      );
  }

  attachmentAdded(serviceAttachment, event) {
    const fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      serviceAttachment.source = fileList[0];
    }
  }

  saveAttachment(i) {
    const attachment = this.serviceAttachments[i];

    const formData: FormData = new FormData();
    formData.append('attachments_attributes[][name]', attachment.name);
    formData.append('attachments_attributes[][source]', attachment.source);
    this.happeningsService.updateService(this.happening.id, formData)
      .subscribe(
        res => {
          this.serviceAttachments = res['attachments'];
          this.toastr.success('Pomyślnie zapisano zmiany');
        },
        err => {
          this.toastr.error('Błąd, spróbuj ponownie później');
        }
      );
  }

  switchRadio(el, val) {
    el.is_required = val;
  }

}
