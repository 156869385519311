import { Component, OnInit, Input } from '@angular/core';
import { ServiceAvailabilityService } from "../../api/service-availability.service";
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'service-prices',
  templateUrl: './service-prices.component.html',
  styleUrls: ['./service-prices.component.scss'],
  providers:[TranslatePipe]
})
export class ServicePricesComponent implements OnInit {

  @Input()
  serviceId;

  availability;

  constructor(private availabilityService: ServiceAvailabilityService,
              private translatePipe:TranslatePipe) { }

  ngOnInit() {
    this.availabilityService.getAvailability(this.serviceId)
      .subscribe( res => {
        console.log(res)
        this.availability = res;
      })
  }

  translate(day:string) {
    return this.translatePipe.transform(_i18n(`weekday.${day}`));
  }
}
