import { Component, OnInit } from '@angular/core';
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  blockElements = true;

  constructor(public _tokenService: AngularTokenService) { }

  ngOnInit() {
  }

  get isOwner() {
    return this._tokenService.currentUserData['owner'];
  }

}
