import { TransitionService } from "@uirouter/angular";
import { Observable ,  Subject } from 'rxjs';

let $previousStateSubject = new Subject<any>();

export function previousStateHook(transitionService: TransitionService) {

  const requiresAuthCriteria = {
    to: (state) => true
  };

  const getState = (transition) => {
    const previousState = transition.from();
    $previousStateSubject.next(previousState);
  };

  transitionService.onBefore(requiresAuthCriteria, getState, {priority: 10});
}

export function getPreviosuState() {
  return $previousStateSubject.asObservable();
}