import { Component, OnInit } from '@angular/core';
import { UIRouter } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '@ngx-translate/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { ServicesService } from '../../api/services.service';
import { StateService } from "@uirouter/angular";
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'facilities-details',
  templateUrl: './facilities-details.component.html',
  styleUrls: ['./facilities-details.component.scss'],
  providers: [TranslatePipe]
})
export class FacilitiesDetailsComponent implements OnInit {

  user_kind: any;
  events_creation_enabled: boolean;
  service;
  serviceCardTitle: string = ""
  serviceCardTooltip: string = ""
  galleryTooltip: string = "";
  location;
  file: File;

  constructor(private servicesService:ServicesService,
              private route:UIRouter,
              private stateService: StateService,
              private translatePipe: TranslatePipe,
              private _tokenService: AngularTokenService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this._tokenService.validateToken()
    .subscribe(
      res => {
        this.user_kind = this._tokenService.currentUserData['user_kind']
        if (this.user_kind == "facility_owner") {
          this.serviceCardTitle = "Obiekt"
          this.serviceCardTooltip = 'Opisz profil obiektu najlepiej jak potrafisz'
          this.galleryTooltip = "Zarządzaj zdjęciami, które będą widoczne na stronie obiektu"
        } else {
          this.serviceCardTitle = "Profil"
          this.serviceCardTooltip = 'Opisz profil swojej działalności najlepiej jak potrafisz, np. Jestem trenerem personalnym i świadczę usługi układania diety i treningu'
          this.galleryTooltip = "Zarządzaj zdjęciami, które będą widoczne w twoim profilu"
        }
      }
    )
    let id = this.route.globals.params.id;
    this.servicesService.getService(id)
    .subscribe((service: any) => {
      this.service = service

      this.events_creation_enabled = this._tokenService.currentUserData['events_creation_enabled']
      const serviceServices = this.service.services;
      const services = [];
      for (let i = 0; i < serviceServices.length; i++) {
        if (serviceServices[i].category !== 'happening') {
          services.push(serviceServices[i]);
        }
      }
      this.service.services = services;

      let locationObject = {
        name: service.location,
        lat: service.lat,
        lng: service.lng,
        serviceId: service.id
      };

      this.location = locationObject;
    }, err => {
      this.toastr.error(err.statusText, err.status);
    })
  }

  removeImage(image) {
    if (confirm(this.translatePipe.transform(_i18n('facilities.details.remove-image-confirm')))) {
      this.servicesService.removeServiceImage(this.service.id, image.id)
        .subscribe(response => {
          this.toastr.success(this.translatePipe.transform(_i18n('facilities.details.toast-messages.success.image-remove')), 'Success!');
          this.service.pictures.splice(this.service.pictures.indexOf(image), 1)
        }, err => {
          this.toastr.error(err.statusText, err.status);
        })
    }
  }

  setAsDefault(image) {
    this.toastr.info(this.translatePipe.transform(_i18n('facilities.details.toast-messages.info.setting-default')), null);
    this.servicesService.setServiceImageDefault(image, this.service)
      .subscribe( response => {
        this.toastr.success(this.translatePipe.transform(_i18n('facilities.details.toast-messages.success.set-default')), 'Success!');
        this.service = response;
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
  }

  imageAdded(event) {
    if (this.service.pictures.length >= 8) {
      this.toastr.error(this.translatePipe.transform(_i18n('facilities.details.toast-messages.error.adding-image')), null);
    } else {
      this.toastr.info(this.translatePipe.transform(_i18n('facilities.details.toast-messages.info.adding-image')), null);
      let fileList: FileList = event.target.files;

      if (fileList.length > 0) {
        this.file = fileList[0];

        let formData:FormData = new FormData();
        formData.append('source', this.file, this.file.name);
        this.servicesService.addServiceImage(formData, this.service.id)
        .subscribe( res => {
          this.toastr.success(this.translatePipe.transform(_i18n('facilities.details.toast-messages.success.adding-image')), 'Success!');
          this.service.pictures.push(res);
        }, err => {
          this.toastr.error(err.statusText, err.status);
        })
      }
    }
  }

  onLocationChange(adresses) {
    let data = {id: this.service.id ,addresses_attributes: JSON.stringify(adresses) };
    this.servicesService.editService(data)
      .subscribe(
        (res: any) => {
          this.toastr.success(this.translatePipe.transform(_i18n('facilities.details.toast-messages.success.editedLocation')), 'Success!');
          this.service.addresses = res.addresses;
        },
        err => {
          this.toastr.error(err.statusText, err.status);
        }
      )
  }
}
