import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';
import { ClientsComponent } from './clients.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientComponent } from './client/client.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { ReservationsComponent, DocsComponent, NotesComponent } from "./client/components";

const routesConfig: Ng2StateDeclaration[] = [
    {
      name: 'clients',
      redirectTo: 'clients.list',
      component:  ClientsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'clients.list',
      url: '/clients',
      component:  ClientListComponent,
      data: { requiresAuth: true },
    },
    {
      name: 'clients.listPage',
      url: '/clients/page/:pageNumber',
      component:  ClientListComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'clients.details',
      url: '/clients/:id',
      component:  ClientComponent,
      data: { requiresAuth: true },
      redirectTo: 'clients.details.reservations'
    },
    {
      name: 'clients.new',
      url: '/clients/new',
      component:  ClientFormComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'clients.edit',
      url: '/clients/:id/edit',
      component:  ClientFormComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'clients.details.reservations',
      url: '/reservations',
      views: {
        'tabs': {
          component: ReservationsComponent,
        }
      }
    },
    {
      name: 'clients.details.docs',
      url: '/docs',
      views: {
        'tabs': {
          component: DocsComponent,
        }
      }
    },
    {
      name: 'clients.details.notes',
      url: '/notes',
      views: {
        'tabs': {
          component: NotesComponent,
        }
      }
    }
  ]

export const routerModule = UIRouterModule.forChild({states:routesConfig})
