import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServiceAvailabilityService } from "../../api/service-availability.service";
import * as Moment from "moment";
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { ServicesService } from '../../api/services.service';


@Component({
  selector: 'service-locations-edit',
  templateUrl: './service-locations-edit.component.html',
  styleUrls: ['./service-locations-edit.component.scss'],
  providers: [TranslatePipe]
})
export class ServiceLocationsEditComponent implements OnInit, OnDestroy {

  availability;
  serviceId;
  transitionHook: any;
  dataChanged: boolean;
  changes = {
    changed: false
  };
  addresses = [];

  constructor(private availabilityService: ServiceAvailabilityService,
              private stateService: StateService,
              private translatePipe: TranslatePipe,
              private toastr: ToastrService,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService,
              private servicesService: ServicesService) {
                this.transitionHook = transitionService.onBefore({from: 'services.**'}, (trans: Transition)=>{
                  return changesValidationService.validate(this.changes);
                })
              }

  ngOnInit() {
    this.serviceId = this.stateService.params.id;

    this.servicesService.getService(this.serviceId)
      .subscribe( (res: any) => {
        console.log(res)
        this.addresses = res.addresses;
      })

    this.availabilityService.getAvailability(this.serviceId)
      .subscribe( res => {
        console.log(res)
        this.availability = res;
        this.availability.pickerInterval = 15;
        for (let i = 0; i < this.availability.hours.length; i++) {
          if (this.availability.hours[i].available) {
            this.createHours(this.availability.hours[i], this.availability.pickerInterval);
          }
        }
        this.checkChangesService.assignObject(this.changes);
        this.changesValidationService.resetResolved();

      })

  }

  ngOnDestroy() {
    this.transitionHook();
  }

  createHours(day, interval) {
    let times = [];
    day.currentFrom = "From";
    day.currentTo = "To";
    for (let i = 0; i < day.open.length; i++) {
      let startDay = Moment(day.open[i].from, 'HH:mm');
      let endDay = Moment(day.open[i].to, 'HH:mm');
      let t = endDay.diff(startDay, 'm') / interval;

      while (t--) {
        let hourObject = {
          from: startDay.format('HH:mm'),
          to: ""
        }
        startDay.add(interval, 'minute');
        if (t < 1) {
          hourObject.to = startDay.format('kk:mm')
        } else {
          hourObject.to = startDay.format('HH:mm')
        }
        times.push(hourObject)
      }

      day.intervals = times;

    }
  }

  editPrice(day, from, to, location, invalid){
    if (invalid || from === "From" || to === "To") {
      return
    }

    this.changes = {changed:true};

    let newPrices = [];


    for (let i = 0; i < day.location.length; i++) {
      let start = Moment(day.location[i].from, 'HH:mm');
      let end = Moment(day.location[i].to, 'HH:mm');
      let oldCost = day.location[i].location;

      let newStart = Moment(from, 'HH:mm');
      let newEnd = Moment(to, 'HH:mm');

      if (newStart.isAfter(newEnd)) {
        newStart = Moment(to, 'HH:mm');
        newEnd = Moment(from, 'HH:mm');
      }

      let newObj = {
        from: newStart.format('HH:mm'),
        to: newEnd.format('kk:mm'),
        location: location
      }

      if (newStart.isSameOrAfter(end)) {
        newPrices.push({
          from: start.format('HH:mm'),
          to: end.format('HH:mm'),
          location: oldCost,
        })
        continue;
      }

      if (newStart.isSame(start)) {
        newPrices.push(newObj)
      } else if (newStart.isBetween(start, end, null, '()')) {
        newPrices.push({
          from: start.format('HH:mm'),
          to: newStart.format('HH:mm'),
          location: oldCost
        }, newObj)
      }

      if (newEnd.isSameOrAfter(end)) {
        if (newEnd.isSameOrBefore(start)) {
          newPrices.push({
            from: start.format('HH:mm'),
            to: end.format('HH:mm'),
            location: oldCost,
          })
        }
        continue;
      } else if (newEnd.isBetween(start, end, null, '()')) {
        newPrices.push({
          from: newEnd.format('HH:mm'),
          to: end.format('kk:mm'),
          location: oldCost,
        })
      } else if (newEnd.isSameOrBefore(start)) {
        newPrices.push({
          from: start.format('HH:mm'),
          to: end.format('HH:mm'),
          location: oldCost,
        })
      }
    }

    let closedHours = []

    for (let i = 0; i < day.open.length - 1; i++) {
      closedHours.push({
        from: Moment(day.open[i].to, 'HH:mm'),
        to: Moment(day.open[i + 1].from, 'HH:mm')
      })
    }

    for (let i = 0; i < newPrices.length; i++) {
      for (let j = 0; j < closedHours.length; j++) {
        let fromMoment = Moment(newPrices[i].from, "HH:mm");
        let toMoment = Moment(newPrices[i].to, "HH:mm");

        if (closedHours[j].to.isBetween(fromMoment, toMoment, null, "()")) {
          newPrices.splice(i, 1, {
            from: newPrices[i].from,
            to: closedHours[j].from.format('HH:mm'),
            location: newPrices[i].cost
          }, {
            from: closedHours[j].to.format('HH:mm'),
            to: newPrices[i].to,
            location: newPrices[i].cost
          })
        }
      }
    }

    day.location = JSON.parse(JSON.stringify(newPrices));
    day.currentLocation = false;
    from = "From";
    to = "To";
    location = "";

  }

  save(ava) {
    this.changesValidationService.resolved = true;
    this.availabilityService.updateAvailability(this.serviceId, ava)
      .subscribe( res => {
        this.toastr.success(this.translatePipe.transform(_i18n('service.prices-edit.toast-messages.success.price-saved')), 'Success!')
        console.log(res);
        this.stateService.go('services.details', {id: this.serviceId})
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
  }

  cancel() {
    this.stateService.go('services.details', {id: this.serviceId})
  }

  translate(day:string) {
    return this.translatePipe.transform(_i18n(`weekday.${day}`));
  }

}
