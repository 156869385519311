import { Component, OnInit, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { AngularTokenService } from 'angular-token';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../api/users.service';
import { Location } from '@angular/common';
import { _i18n } from './../utilities/translation-marker/translation-marker';
import { getPreviosuState } from './../resolvers/previous-state.resolver';
import { getCurrentState } from './../resolvers/current-state.resolver';
import { Ng2Cable, Broadcaster } from 'ng2-cable';
import { NotificationsService, Notification, NotificationsList } from './../api/notifications.service';
import { MessagesService } from './../api/messages.service';
import { Subscription, Subject } from "rxjs";

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  private $previousState = getPreviosuState();
  private $currentState = getCurrentState();

  previousStateSubscription: Subscription;
  currentStateSubscription: Subscription;
  showBackButton: boolean = true;
  currentUser: any;
  isColapsed: boolean = false;

  notifications: Notification[] = [];
  unreadNotifications: number = 0;
  messages;
  unreadMessages: number = 0;
  navItems = [
    // {
    //   name: 'Bonus za transakcję',
    //   name_trainer: 'Bonus za transakcję',
    //   state: 'additionalIncome',
    //   icon: 'ion-logo-usd',
    //   owner_only: false,
    // },
    {
      id: 'calendar',
      name: 'Kalendarz',
      name_trainer: 'Kalendarz',
      state: 'dashboard',
      icon: 'ion-ios-calendar',
      owner_only: false,
    },
    {
      id: 'bookings-to-accept',
      name: 'Usługi do akceptacji',
      name_trainer: 'Usługi do akceptacji',
      state: 'bookings.toAccept',
      icon: 'ion-ios-bookmarks',
      owner_only: false,
      activeEq: true
    },
    {
      id: 'clients',
      name: 'Moi podopieczni',
      name_trainer: 'Moi podopieczni',
      state: 'clients',
      icon: 'ion-ios-people',
      owner_only: false,
    },
    {
      id: 'profile',
      name: _i18n('navbar.nav-items.facilities'),
      name_trainer: _i18n('navbar.nav-items.facilities-trainer'),
      state: 'facilities-transition',
      icon: 'ion-ios-pin',
      owner_only: true,
    },
    {
      id: 'happenings',
      name: 'Wydarzenia',
      name_trainer: 'Wydarzenia',
      state: 'happenings',
      icon: 'ion-ios-flag',
      owner_only: true,
    },
    {
      id: 'services',
      name: 'Świadczone usługi',
      name_trainer: 'Świadczone usługi',
      state: 'services',
      icon: 'ion-ios-briefcase',
      owner_only: false,
    },
    // {
    //   id: 'employees',
    //   name: _i18n('navbar.nav-items.users'),
    //   name_trainer: _i18n('navbar.nav-items.users'),
    //   state: 'users',
    //   icon: 'ion-ios-people',
    //   description: _i18n('navbar.nav-items.descriptions.users'),
    //   owner_only: true,
    // },
    // {
    //   id: 'bundles',
    //   name: 'Pakiety',
    //   name_trainer: 'Pakiety',
    //   state: 'packets',
    //   icon: 'ion-md-cube',
    //   owner_only: true,
    // },
    {
      id: 'transactions',
      name: 'Sprzedane usługi',
      name_trainer: 'Sprzedane usługi',
      state: 'transactions',
      icon: 'ion-ios-cash',
      description: _i18n('navbar.nav-items.descriptions.transactions'),
      owner_only: false,
    },
    {
      id: 'bookings',
      name: 'Historia Usług',
      name_trainer: 'Historia Usług',
      state: 'bookings',
      icon: 'ion-ios-bookmarks',
      description: _i18n('navbar.nav-items.descriptions.bookings'),
      owner_only: false,
    },
    {
      id: 'notifications',
      name: 'Powiadomienia',
      name_trainer: 'Powiadomienia',
      state: 'notifications',
      icon: 'ion-ios-notifications',
      owner_only: false,
    },
    // {
    //   name: 'Statystyki finansowe',
    //   name_trainer: 'Statystyki finansowe',
    //   state: 'financialStats',
    //   icon: 'ion-ios-cash',
    //   owner_only: false
    // },
    {
      id: 'help',
      name: _i18n('navbar.nav-items.help'),
      name_trainer: _i18n('navbar.nav-items.help'),
      state: 'help',
      icon: 'ion-md-help-circle',
      owner_only: false,
    },
    {
      id: 'settings',
      name: _i18n('navbar.nav-items.settings'),
      name_trainer: _i18n('navbar.nav-items.settings'),
      state: 'settings',
      icon: 'ion-ios-cog',
      owner_only: false,
    },
    {
      id: 'contact',
      name: _i18n('navbar.nav-items.contact'),
      name_trainer: _i18n('navbar.nav-items.contact'),
      state: 'contact.sponsor',
      icon: 'ion-md-contact',
      owner_only: false,
    },
    {
      id: 'messages',
      name: _i18n('navbar.nav-items.messages'),
      name_trainer: _i18n('navbar.nav-items.messages'),
      state: 'messages',
      icon: 'ion-md-chatboxes',
      owner_only: false,
    }
  ];

  constructor(
    public stateService: StateService,
    public _tokenService: AngularTokenService,
    public config: NgbTooltipConfig,
    private usersService: UsersService,
    private _location: Location,
    private notificationsService: NotificationsService,
    private messagesService: MessagesService,
    private ng2cable: Ng2Cable,
    private broadcaster: Broadcaster,
  ) {
    config.placement = 'right';
    config.triggers = 'hover';
    config.container = 'body';
  }

  ngOnInit() {
    this.previousStateSubscription = this.$previousState.subscribe(
      res => {
        if (res.name == 'auth.login') {
          this.showBackButton = false;
        } else {
          this.showBackButton = true;
        }
      }
    );
    this.currentStateSubscription = this.$currentState.subscribe(
      res => {
        if (res.name == 'dashboard') {
          this.showBackButton = false;
        } else {
          this.showBackButton = true;
        }
      }
    );

    this.getNotify();
    this.getMessagesNotify();
    this.usersService.getUsersStream()
      .subscribe(
        res => {
          this.notificationsService.setNotificationsChannel();
          this.messagesService.setMessagesChannel();
        }
      );
  }

  ngOnDestroy() {
    // this.previousStateSubscription.unsubscribe();
    // this.currentStateSubscription.unsubscribe();
  }

  canAccess(id) {
    const services_user_elements = [
      'calendar',
      'bookings-to-accept',
      'clients',
      'profile',
      'services',
      'employees',
      'bundles',
      'transactions',
      'bookings',
      'notifications',
      'help',
      'settings',
      'contact',
      'messages',
    ];

    const events_user_elements = [
      'clients',
      'profile',
      'happenings',
      'employees',
      'transactions',
      'bookings',
      'notifications',
      'settings',
      'contact',
      'messages',
    ];

    if (this._tokenService.currentUserData) {
      if (this._tokenService.currentUserData['events_creation_enabled'] === true) {
        return events_user_elements.indexOf(id) !== -1;
      } else {
        return services_user_elements.indexOf(id) !== -1;
      }
    }
  }

  showItemNotification(item) {
    if (item.state == 'notifications' && this.unreadNotifications > 0) {
      return true;
    } else if (item.state == 'messages' && this.unreadMessages > 0) {
      return true;
    }
  }

  backClicked() {
    this._location.back();
  }

  getUser() {
    return this.usersService.getMyProfile();
  }

  signOut() {
    this._tokenService.validateToken().subscribe(
      res => {
        if (this._tokenService.userSignedIn()) {
          this._tokenService.signOut().subscribe(
            (res) => {
              console.log(res);
              this.stateService.go('auth');
            },
            error => console.log(error)
          );
        } else {
          this.stateService.go('auth');
        }
      },
      err => {
        this._tokenService.signOut();
        this.stateService.go('auth');
      }
    )
    this.notificationsService.unsubscribeFromNotificationsChannel();
    this.messagesService.unsubscribeFromMessagesChannel();
  }

  switchNavbar() {
    this.isColapsed = !this.isColapsed;
  }

  hideMobileNav() {
    this.isColapsed = false;
  }

  getNotify() {
    this.notificationsService.getNavNotificationsStream()
      .subscribe( (res: any) => {
        if (typeof(res) == 'number') {
          this.unreadNotifications = res;
        } else {
          if (res.data.length == 0) {
            this.notifications = [];
            this.unreadNotifications = 0;
          } else {
            this.notifications = res.data.concat(this.notifications);
            if (res.unread != undefined) {
              this.unreadNotifications = res.unread;
            } else {
              this.unreadNotifications = this.unreadNotifications + res.data.length;
            }
          }
        }
      })
  }
  getMessagesNotify() {
    this.messagesService.getNavMessageNotificationsStream()
      .subscribe( (res: any) => {
        if (typeof(res) == 'number') {
          this.unreadMessages = res;
        } else {
          if (res.data.length == 0) {
            this.messages = [];
            this.unreadMessages = 0;
          } else {
            this.messages = res.data.concat(this.messages);
            if (res.unread != undefined) {
              this.unreadMessages = res.unread;
            } else if (res.type == 'message') {
              this.unreadMessages = this.unreadMessages + 1;
            }
          }
        }
      })
  }

  showNotification(notification) {
    this.notificationsService.readNotification(notification);
  }

  get isFacilityOwner() {
    return this._tokenService.currentUserData['user_kind'] == 'facility_owner';
  }

  get isPersonalTrainer () {
    return this._tokenService.currentUserData['user_kind'] == 'personal_trainer';
  }

  get isPhysiotherapist() {
    return this._tokenService.currentUserData['user_kind'] == 'physiotherapist';
  }

  get isOwner() {
    return this._tokenService.currentUserData['owner'];
  }

  get currentUserFirstName() {
    return this._tokenService.currentUserData['first_name'];
  }

  get currentUserLastName() {
    return this._tokenService.currentUserData['last_name'];
  }

  get currentUserEmail() {
    return this._tokenService.currentUserData['email'];
  }
  get canCreateEvents() {
    return this._tokenService && this._tokenService.currentUserData['events_creation_enabled'] === true;
  }
}
