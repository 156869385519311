import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../api/users.service'
import { SettingsService } from '../../api/settings.service'
import { AngularTokenService } from 'angular-token';
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';

@Component({
  selector: 'bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
  providers: [TranslatePipe]
})
export class BankAccountComponent implements OnInit, OnDestroy {

  BankAccountNumber: any;
  data = {
    bank_account_number: null
  }
  savingInProgress:boolean = false;
  availableCharacters = [48, 57]
  transitionHook: any;

  constructor(private usersService: UsersService,
              private settingsService: SettingsService,
              private _tokenService: AngularTokenService,
              private toastr: ToastrService,
              private translatePipe: TranslatePipe,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService) {
                this.transitionHook = transitionService.onBefore({from: 'settings.**'}, (trans: Transition)=>{
                    return changesValidationService.validate(this.data)
                })

                this.usersService.getMyProfile()
                  .subscribe((res: any) => {
                    this.BankAccountNumber = res.bank_account_number;
                  })
  }

  ngOnInit() {
    this.checkChangesService.assignObject(this.data);
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  eventHandler(event){
    if (event < 48 || event > 57) {
        return false
    }
  }

  save(invalid, data) {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;
    this.changesValidationService.resolved = true;

    this.settingsService.saveProfile(data)
      .subscribe((res: any) => {
        this._tokenService.validateToken();
        console.log(res);
        this.BankAccountNumber = res.bank_account_number;
        this.data.bank_account_number = '';
        this.toastr.success(this.translatePipe.transform(_i18n('settings.bank-account.toast-messages.success.save')), 'Success!');
        this.savingInProgress = false;
      }, err => {
        console.info(err)
        this.toastr.error(this.translatePipe.transform(_i18n('settings.bank-account.toast-messages.error.save')), err.status);
        this.savingInProgress = false;
      })
  }

}
