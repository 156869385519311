import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

@Component({
  selector: 'happenings-reviews',
  templateUrl: './happenings-reviews.component.html',
  styleUrls: ['./happenings-reviews.component.scss']
})
export class HappeningsReviewsComponent implements OnInit {

  happeningId;

  constructor(
    private route: UIRouter,
  ) { }

  ngOnInit() {
    this.happeningId = this.route.globals.params.id;
  }

}
