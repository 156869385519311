import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { CalendarService } from 'app/api/calendar.service';
import { CalendarIntegrationCode } from 'app/calendars/calendars';

@Component({
  selector: 'calendars-button',
  templateUrl: './calendars-button.component.html',
  styleUrls: ['./calendars-button.component.scss']
})
export class CalendarsButtonComponent  {

  constructor(
    private calendarService: CalendarService,
    public _tokenService: AngularTokenService,
  ) { }

  connetctWithGoogleCalender() {
    this.calendarService.getIntegrateUrlFromGoogle()
      .subscribe(
        res => location.replace(res.url),
        err => console.log(err)
      );
  }

}
