import { from as observableFrom,  Subject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestOptions, RequestMethod, Http, ResponseContentType } from '@angular/http';
import { environment } from '../../environments/environment';
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { IAddUserService, IAddUserClient } from 'app/users/users.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UsersService {

  users = [];
  usersStream$ = new Subject();

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
  ) { }

  getUsers(page, query, sort) {
    let q = '';
    let s = '';
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`;
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiEndpoint}/partners?page=${page}${q}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  getUser(id) {
    let url = `${environment.apiEndpoint}/partners/${id}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  getMyProfile() {
    let url = `${environment.apiBase}/user`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  editUser(data, userId) {
    let url = `${environment.apiEndpoint}/partners/${userId}/`;
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.put(url, data, { headers }).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  newUser(data) {
    let url = `${environment.apiEndpoint}/partners`;
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, data, { headers }).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  deleteUser(userId) {
    const url = `${environment.apiEndpoint}/partners/${userId}`;
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  uploadImage(userId, image) {
    const url = `${environment.apiEndpoint}/partners/${userId}`;
    const data = JSON.stringify(image);
    return this.http.put(url, {user: {avatar: data}}).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  updatePassword(data) {
    const url = `${environment.apiBase}/user/password`;
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  broadcastLogin() {
    this.usersStream$.next(true);
  }

  getUsersStream() {
    return observableFrom(this.usersStream$);
  }

  downloadRodoStatement(id, name) {
    let url = `${environment.apiEndpoint}/partners/${id}/download_rodo_statement.pdf`;

    return this.http.get(url, {responseType: 'blob'}).pipe(
      map((res: any) => {
        return {
          filename: name + ' rodo.pdf',
          file: res
        };
      }))
  }

  getUserServices(userId: number, page:any, query:any, sort:any) {
    let q = "";
    let s = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    const url = `${environment.apiEndpoint}/partners/${userId}/services?q[category_in][]=bookable&q[category_in][]=unitable&page=${page}${q}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  getUserClients(userId: number, page: any, query: any, sort: any) {
    let q = "";
    let s = "";
    let p = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    const url = `${environment.apiEndpoint}/partners/${userId}/clients?page=${page}${q}${p}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  addUserAttributes(userId: number, data: IAddUserService | IAddUserClient) {
    const url = `${environment.apiEndpoint}/partners/${userId}`;
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  removeServiceFromUser(id: number, userId: number) {
    const url = `${environment.apiEndpoint}/partners/${userId}`;
    const data = {
      user_services_attributes: JSON.stringify([{
        id, _destroy: '1'
      }])
    }
    return this.http.put(url,data).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  removeClientFromUser(id: number, userId: number) {
    const url = `${environment.apiEndpoint}/partners/${userId}`;
    const data = {
      user_relations_attributes: JSON.stringify([{
        id, _destroy: '1'
      }])
    }
    return this.http.put(url,data).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }
}
