import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'session-modal',
  templateUrl: './session-modal.component.html',
  styleUrls: ['./session-modal.component.scss']
})
export class SessionModalComponent {

  constructor(private modalInstace: NgbActiveModal,
              private stateService: StateService) { }

  close() {
    this.stateService.go('auth');
    this.modalInstace.close();
  }
}
