import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routerModule } from "./facilities.routing";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from '@agm/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from "@ngx-translate/core";
import { UtilitiesModule } from "../utilities/utilities.module";

import { FacilitiesService } from "../api/facilities.service";

import { FacilitiesComponent } from './facilities.component';
import { FacilitiesListComponent } from './facilities-list/facilities-list.component';
import { FacilitiesDetailsComponent } from './facilities-details/facilities-details.component';
import { FacilitiesFormComponent } from './facilities-form/facilities-form.component';
import { FacilitiesServicesComponent } from './facilities-services/facilities-services.component';
import { FacilitiesTransitionComponent } from './facilities-transition/facilities-transition.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    NgbModule.forRoot(),
    FormsModule,
    AgmCoreModule,
    // NgSelectModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    UtilitiesModule,
    TranslateModule.forChild()
  ],
  providers: [
    FacilitiesService,
  ],
  declarations: [FacilitiesComponent, FacilitiesListComponent, FacilitiesDetailsComponent, FacilitiesFormComponent, FacilitiesServicesComponent, FacilitiesTransitionComponent]
})
export class FacilitiesModule { }
