import { Injectable } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { environment } from "../../environments/environment";
import { Http, Response, RequestOptions, Headers, RequestMethod } from '@angular/http';
import { ErrorHandlerService } from './../utilities/error-handler.service';
import {catchError, map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContactService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private http: HttpClient
  ) { }

  getContact() {
    let url = `${environment.apiEndpoint}/contact`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }
}
