import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'angular2-moment';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DashboardFilterComponent } from './dashboard/dashboard-filter/dashboard-filter.component';
import { TranslateModule } from "@ngx-translate/core";

import { UtilitiesModule } from "../utilities/utilities.module";
import { routerModule } from "./dashboard.routing";
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardReservationModalComponent } from './dashboard/dashboard-reservation-modal/dashboard-reservation-modal.component';
import { CalendarModule } from 'angular-calendar';
import { CalendarsModule } from 'app/calendars/calendars.module';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    NgbModule,
    FormsModule,
    UtilitiesModule,
    MomentModule,
    NgxDatatableModule,
    CalendarModule,
    TranslateModule.forChild(),
    UtilitiesModule,
    CalendarsModule,
  ],
  entryComponents: [
    DashboardReservationModalComponent
  ],
  declarations: [DashboardComponent, DashboardFilterComponent, DashboardReservationModalComponent]
})
export class DashboardModule { }
