import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { MessagesService } from './../../api/messages.service';
import { StateService } from "@uirouter/angular";
import { Subscription } from 'rxjs';

@Component({
  selector: 'messages-conversation',
  templateUrl: './messages-conversation.component.html',
  styleUrls: ['./messages-conversation.component.scss']
})
export class MessagesConversationComponent implements OnInit, OnDestroy {

  conversationId: Number;
  // messagesSubscription:Subscription;
  conversation: any = [];
  loading: boolean = false;
  page: number = 1;
  offset: number = 0;
  totalMessages: number = 0;
  inited:boolean = false; // loader circle helper
  messagesStreamSubscription;

  savingInProgress = false;
  submitted = false;
  message = "";

  constructor(private messagesService: MessagesService,
              private stateService: StateService) {
                this.conversationId = this.stateService.params.conversation_id
              }

  ngOnInit() {
    this.getConversationMessages();
    this.getMessagesStream();
  }

  ngOnDestroy() {
    this.messagesStreamSubscription.unsubscribe();
  }

  loadMessages() {
    if (this.loading) {
      return;
    }
    this.page = this.page + 1;
    this.getConversationMessages();
  }

  sendMessage(form, message) {
    if (form.invalid) {
      return;
    }

    if (this.savingInProgress == true) {
      return;
    }
    this.savingInProgress = true;
    this.submitted = true;
    // this.messagesSubscription =
    this.messagesService.sendMessages(this.conversationId, {message: {content: this.message}})
      .subscribe(
        res => {
          this.submitted = false;
          this.savingInProgress = false;
          this.message = ""
          form.reset();
          this.totalMessages = this.totalMessages + 1;
          this.offset = this.offset + 1;
          this.conversation.splice(0, 0, res)
        },
        err => {
          this.savingInProgress = false;
        }
      )
  }

  getConversationMessages() {
    // this.messagesSubscription =
    if (this.loading) {
      return;
    }
    this.loading = true
    this.messagesService.getConversationMessages(this.conversationId, this.page, this.offset)
      .subscribe(
        (res: any) => {
          this.inited = true;
          this.conversation = this.conversation.concat(res.data);
          this.messagesService.setUnreadConversations()
          this.totalMessages = res.size;
          this.loading = false;
        },
        err => {
          console.log(err)
          this.loading = false;
        }
      )
  }

  getMessagesStream() {
    this.messagesStreamSubscription = this.messagesService.getMessagesStream()
      .subscribe( (res: any) =>{
        if (res.type == "message") {
           for (let data of res.data) {
             if (this.stateService.params.conversation_id == data.message.conversation_id) {
               this.totalMessages = this.totalMessages + 1;
               this.offset = this.offset + 1;
               this.conversation.splice(0, 0, data.message)
               this.messagesService.getConversationMessages(this.conversationId, 0, 0)
                .subscribe(
                  res => {
                    this.messagesService.setUnreadConversations()
                  }
                )
             }
           }
        }
      })
  }
}
