import { Component, OnInit, Input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { ServiceVerificationCommentsComponent } from '../../utilities/service-verification-comments/service-verification-comments.component'

@Component({
  selector: 'service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
  providers: [TranslatePipe]
})
export class ServiceCardComponent implements OnInit {
  
  cancellation = {
    "0": "odwołanie darmowe bez względu na czas odwołania",
    "120": "5 dni przed terminem usługi",
    "72": "3 dni przed terminem usługi",
    "24": "24 godziny przed terminem usługi",
    "12": "12 godzin przed terminem usługi",
    "6": "6 godzin przed terminem usługi",
    "-1": "brak możliwości darmowego odwołania usługi",
  }

  @Input() service;
  @Input() displayOnly;
  @Input() title: string = "Usługa";
  @Input() tooltip: boolean = true;
  @Input() tooltipContent: string = "Doprecyzuj daty oraz godziny dostępności Twojego obiektu lub świadczonych przez siebie usług";

  constructor(private translatePipe: TranslatePipe,
              private modalService: NgbModal,) { }

  ngOnInit() {
  }
  
  showRejectedReasonsModal() {
    const modalRef = this.modalService.open(ServiceVerificationCommentsComponent, {windowClass: 'align-modal-center login-modal'});
    modalRef.componentInstance.comments = this.service.verification_comments;
    modalRef.result.then((result) => {
      this.service.verification_status = result;
    }, (reason) => { });
  }

  translateStatus(status) {   
    return this.translatePipe.transform(_i18n(`service.status.${status}`))
  }

}
