import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { TransactionsService, Transaction } from "../../api/transactions.service";

@Component({
  selector: 'transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss']
})
export class TransactionsListComponent implements OnInit {

  transactions: Transaction[];
  sort: any;
  q: any;
  loading = true;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  }

  constructor(private stateService: StateService,
              private transactionsService: TransactionsService) { }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.transactionsService.getTransactions(this.page.pageNumber+1,this.q, this.sort)
      .subscribe(
        (res: any) => {
          this.transactions = res.data;
          this.loading = false;
          this.page.totalElements = res.size;
          this.page.size = res.per_page;
        },
        err => console.log(err)
      )
  }

  onSort(event) {
    this.loading = true;
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.sort = prop + " " + s.dir;
    this.transactionsService.getTransactions(0, this.q, this.sort)
      .subscribe(
        (res: any) => {
          this.transactions = res.data;
          this.loading = false;
        },
        err => console.log(err)
      );
  }

  getRowClass(row) {
    return {
      'additional-income': row.additional_income > 0
    }
  }

}
