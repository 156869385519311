import { Component, OnInit } from '@angular/core';
import { BookingsService, Booking } from "../../api/bookings.service";
import { StateService } from "@uirouter/angular";
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingEditReservationModalComponent } from '../modals/booking-edit-reservation-modal/booking-edit-reservation-modal.component';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss'],
  providers: [TranslatePipe]
})
export class BookingDetailsComponent implements OnInit {

  serviceId: string;
  bookingId: string;
  booking: Booking;

  constructor(private bookingsService: BookingsService,
              private translatePipe: TranslatePipe,
              private modalService: NgbModal,
              private stateService: StateService) { }

  ngOnInit() {
    console.log(this.stateService)
    this.serviceId = this.stateService.params.serviceId;
    this.bookingId = this.stateService.params.bookingId;

    this.bookingsService.getBooking(this.serviceId, this.bookingId)
      .subscribe((res: any) => {
        console.log(res);
        this.booking = res;
      })
  }

  cancelBooking(booking){
    if (confirm(this.translatePipe.transform(_i18n('booking.details.cancel-confirm-message')))) {
      this.bookingsService.cancelBooking(this.serviceId, this.bookingId)
        .subscribe(res => {
          console.log(res);
          booking.status = 'canceled';
        })
    }
  }

  translateStatus(status) {
    return this.translatePipe.transform(_i18n(`service.status.${status}`))
  }

  setPresence(presence) {
    this.bookingsService.setBookingPresence(this.serviceId, this.bookingId, presence)
      .subscribe((res: any) => {
        console.log(res);
        this.booking = res;
      })
  }
  setPayed(status) {
    this.bookingsService.setBookingPaymentStatus(this.serviceId, this.bookingId, status)
      .subscribe((res: any) => {
        console.log(res);
        this.booking = res;
      })
  }

  openEditModal() {
    console.log(this.booking);

    const modalRef = this.modalService.open(BookingEditReservationModalComponent);
    modalRef.componentInstance.service = { id: this.serviceId };
    modalRef.componentInstance.selectedServiceVariant = this.booking.service.service_variant;
    if (this.booking.type == 'OnlineBooking') {
      modalRef.componentInstance.client = this.booking.user;
    } else if (this.booking.type == 'OfflineBooking') {
      if (this.isOfflineClientAdded()) {
        modalRef.componentInstance.client = this.booking.user;
      } else {
        modalRef.componentInstance.client = null;
      }
    }
    modalRef.componentInstance.bookingId = this.booking.id;
    modalRef.componentInstance.availablePackets = this.booking.assigned_service_booking_groups;
    modalRef.componentInstance.selectedPacket = this.booking.service_booking_group;
    modalRef.componentInstance.inputedDay = this.booking.date;
    modalRef.componentInstance.bookingNote = this.booking.note;
    modalRef.componentInstance.inputedTime = this.booking.time_from;
    modalRef.componentInstance.success.subscribe(
      data => {
        this.ngOnInit();
      }
    );
  }

  isOfflineClientAdded() {
    if (!this.booking.user) { return false; };
    return this.booking.user.first_name || this.booking.user.last_name || this.booking.user.email;
  }

  invoiceData() {
    const data = this.booking.invoice_data;
    return data.nip && data.name && data.street && data.zip && data.city && data.country;
  }

  setApprovalStatus(status) {
    this.bookingsService.setBookingApprovalStatus(this.serviceId, this.bookingId, status)
      .subscribe((res: any) => {
        console.log(res);
        this.booking = res;
      })
  }

  isOnlineServiceStatus() {
    if (!this.booking) { return false; }
    const booking = this.booking;
    const status = this.booking.status;
    // this.booking && booking.service.connectivity_type == 'online'

    return booking && (status == 'waiting_for_approval' || status == 'approved' || status == 'rejected');
  }
}
