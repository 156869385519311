import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ServicesService } from 'app/api/services.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser, IAddUserService } from '../../users.interface';
import { UsersService } from 'app/api/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'user-add-service-modal',
  templateUrl: './user-add-service-modal.component.html',
  styleUrls: ['./user-add-service-modal.component.scss']
})
export class UserAddServiceModalComponent implements OnInit {

  services: any[];
  selectedService: any = '0';
  success: EventEmitter<any> = new EventEmitter<any>();
  loading: boolean = false;

  @Input()
  user: IUser;


  constructor(
    private servicesService: ServicesService,
    private usersService: UsersService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.servicesService.getAllServices(this.user.id).subscribe(
      (response: any) => {
        this.services = response.data;
      }
    );
  }

  close() {
    this.activeModal.close();
  }

  updateServiceModel(event) {
    this.selectedService = event;
  }

  save() {
    this.loading = true;
    if (!this.selectedService.id || !this.user.id) {
      this.toastr.error('Wybierz usługę', 'Error');
      this.loading = false;
      return;
    }
    const data: IAddUserService = {
      user_services_attributes: JSON.stringify([
        {user_id: this.user.id,
        service_id: this.selectedService.id,}
      ])
    }
    this.usersService.addUserAttributes(this.user.id, data).subscribe(
      (response) => {
        this.success.emit(response);
        this.toastr.success('Dodano usługę do pracownika', 'Sukces');
        this.loading = false;
        this.close();
      },
      (error) => {
        this.toastr.error('Wystąpił nieoczekiwany błąd', 'Error');
        this.loading = false;
      }
    )
  }
}
