import { Component, OnInit, OnDestroy, EventEmitter, Input, HostListener, ElementRef } from '@angular/core';
import { SettingsService } from "../../api/settings.service";
import { AngularTokenService } from 'angular-token';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { NgbDatepickerConfig, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from '../../utilities/date-formatter/date-formatter';
import { ToastrService } from 'ngx-toastr';
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { UsersService } from '../../api/users.service'

@Component({
  selector: 'profile-edit',
  host: {
    '(document:click)': 'onClick($event)',
  },
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    TranslatePipe
  ]

})
export class ProfileEditComponent implements OnInit, OnDestroy {

  file: File;
  user: any;
  generateView = false;
  today = new Date();
  transitionHook: any;
  minDate;
  maxDate;
  dynamicId;
  savingProfile = false;
  dirty:boolean = false;

  constructor(private settingsService: SettingsService,
              private _tokenService: AngularTokenService,
              private formatter: NgbDateFRParserFormatter,
              private toastr: ToastrService,
              private _eref: ElementRef,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService,
              private translatePipe: TranslatePipe,
              private config: NgbDatepickerConfig,
              private usersService: UsersService,
              private stateService: StateService) {
                this.minDate = {year: 1900, month: 1, day: 1};
                this.maxDate = {year: this.today.getFullYear(), month: this.today.getMonth()+1, day: this.today.getDate()};
                this.transitionHook = transitionService.onBefore({from: 'settings.profile-edit'}, (trans: Transition)=>{
                  return changesValidationService.validate(this.user)
                })
               }

  ngOnInit() {
    this.usersService.getMyProfile()
      .subscribe(res => {
        console.log(res)
        this.user = res;
        this.user.birth_date = this.formatter.parse(this.user.birth_date);
        this.generateView = true;
        this.checkChangesService.assignObject(this.user);
        this.changesValidationService.resetResolved();
      })
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  eventHandler(event){
    if (event) {
      if (event < 48 || event > 57) {
        return false
      }
    }
  }

  focusDatepicker(event, d) {
    event.preventDefault();
    event.stopPropagation();
    if (event.relatedTarget) {
      d.open();
      this.openDatepicker(d, event);
    }
  }

  openDatepicker(id, event){
    event.preventDefault();
    event.stopPropagation();
    this.dynamicId = id;
  }

  blurDatepicker(d) {
    setTimeout( () => {
      d.close()
    },500)
  }

  onClick(event) {
    if(this.dynamicId == undefined){
    }
    else if(!document.getElementById('birthdate').contains(event.target)) {
      let self = this;
      setTimeout(function(){
        self.dynamicId.close();
      },10);
    }
  }

  save(formInvalid, user) {
    if(formInvalid) {
      return
    }
    if (this.savingProfile) {
      return;
    }

    this.savingProfile = true;

    this.changesValidationService.resolved = true;

    if (this.user.birth_date) {
      this.user.birth_date = this.formatter.format(this.user.birth_date);
    }

    if (this.user.first_name == null) {
      this.user.first_name = "";
    }
    if (this.user.last_name == null) {
      this.user.last_name = "";
    }
    if (this.user.email == null) {
      this.user.email = "";
    }
    if (this.user.phone == null) {
      this.user.phone = "";
    }
    if (this.user.sex == null) {
      this.user.sex = "";
    }
    if (this.user.bank_account_number == null) {
      this.user.bank_account_number = "";
    }

    let formData:FormData = new FormData();

    for (let key in user) {
      formData.append(key, user[key])
    }

    if (this.file) {
      formData.append('avatar', this.file, this.file.name);
    }

    this.settingsService.saveProfile(formData)
      .subscribe( res => {
        this.dirty = false;
        user = res;
        console.log(user);
        this.user.birth_date = this.formatter.parseReversed(this.user.birth_date);
        this._tokenService.validateToken();
        this.toastr.success('Pomyślnie zapisano zmiany');
        this.savingProfile = false;
        this.stateService.go('settings.profile-details');
      }, err => {
        this.dirty = false;
        console.error(err);
        this.user.birth_date = this.formatter.parseReversed(this.user.birth_date);
        this.toastr.error('Błąd. Spróbuj jeszcze raz');
        this.savingProfile = false;
      })
  }

  fileChange(event, user) {
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      this.file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        user.picture = reader.result;
      }
    }
  }

  fieldChangeDirty() {
    this.dirty = true;
  }

}
