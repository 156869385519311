import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ServiceHoursModalComponent } from "../service-hours-modal/service-hours-modal.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceAvailabilityService } from "../../api/service-availability.service";
import { ToastrService } from 'ngx-toastr';
import * as Moment from "moment";
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
// TODO: add displaying component move this component to seperate url

@Component({
  selector: 'service-availability',
  templateUrl: './service-availability.component.html',
  styleUrls: ['./service-availability.component.scss'],
  providers: [TranslatePipe]
})
export class ServiceAvailabilityComponent implements OnInit, OnDestroy {

  @Input()
  service;

  intervalsMin = [60,45,30];

  availability: any;
  transitionHook: any;
  isHourModalOpen:any;

  hours: Array<object>

  constructor(private modalService: NgbModal,
              private availabilityService: ServiceAvailabilityService,
              private translatePipe: TranslatePipe,
              private toastr: ToastrService,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService) {
                this.transitionHook = transitionService.onBefore({from: 'services.**'}, (trans: Transition)=>{
                  console.log(this.availability)
                  if(this.isHourModalOpen == true){
                    return false
                  }
                  return changesValidationService.validate(this.availability);
                })
              }

  ngOnInit() {

    this.availabilityService.getAvailability(this.service.id)
      .subscribe( res => {
        console.log(res)
        this.availability = res;
        this.checkChangesService.assignObject(this.availability);
        this.changesValidationService.resetResolved();
      })

  }

  ngOnDestroy() {
    this.transitionHook();
  }

  availabilityChanges(event) {
    this.availability.changed = true;
  }

  intervalChange(value, ava) {
    // TODO: Is changing interval possible?
    if (confirm(this.translatePipe.transform(_i18n('service.availability.confirm.change-interval')))) {
      ava.interval = value;
      this.resetHours(this.availability)
    }
  }

  openHoursModal(day, availability) {
    const modalRef = this.modalService.open(ServiceHoursModalComponent);
    modalRef.componentInstance.day = day;
    modalRef.componentInstance.availability = availability;
    modalRef.componentInstance.service = this.service
    modalRef.componentInstance.isOpen.subscribe(
      res => {
        this.isHourModalOpen = res
        if (this.isHourModalOpen != 'saved') {
          this.checkChangesService.assignObject(this.availability);
        }
      }
    )
  }

  resetHours(ava) {
    for (let i = 0; i < ava.hours.length; i++) {
      ava.hours[i].open = [{from: "09:00", to: "21:00"}];
    }
  }
  cancel() {
    // this.availabilityService.getAvailability(this.service.id)
    //   .subscribe( res => {
    //     console.log(res)
    //     this.availability = res;
    //   })
    this.ngOnInit();
  }

  translate(day:string) {
    return this.translatePipe.transform(_i18n(`weekday.${day}`));
  }

}
