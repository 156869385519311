import { Component, OnInit, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../../api/services.service';
import { ClientsService } from '../../../api/clients.service';
import * as Moment from 'moment';

@Component({
  selector: 'dashboard-reservation-modal',
  templateUrl: './dashboard-reservation-modal.component.html',
  styleUrls: ['./dashboard-reservation-modal.component.scss']
})
export class DashboardReservationModalComponent implements OnInit {

  @Input() existingUser = false;
  @Input() selectedDate;

  loading = false;
  service: any;
  selectedService: any = '0';
  selectedServiceVariant: any = '0';
  selectedServiceStart: any = '0';
  requestedServiceStart;
  selectedServiceEnd: any = '';
  selectedClient: any = '';
  selectedClientObject: any = {};
  selectedColor: string = '#d35400';
  selectedDay: NgbDateStruct;
  minDate: any;
  selectedPacket: any = 0;
  q = {
    verification_status_eq: 'verified'
  };
  selectedTimes: Array<object> = [];
  times: Array<object> = [];
  services: Array<object> = [];
  allAvailableClients = [];
  availableClients: any[] = [];
  availablePackets = [];
  dateDisabled: any;
  success: EventEmitter<any> = new EventEmitter<any>();
  data = {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    note: '',
  };
  newClientForm = false;
  showClientForm = false;
  colors: string[] = [
    '#16a085',
    '#27ae60',
    '#2980b9',
    '#8e44ad',
    '#2c3e50',
    '#f39c12',
    '#d35400',
    '#c0392b',
    '#bdc3c7',
    '#7f8c8d',
  ]

  @ViewChild('clientDropdown')
  clientDropdown: NgbDropdown;

  constructor(
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private servicesService: ServicesService,
    private clientsService: ClientsService,
  ) { }

  ngOnInit() {
    let today = new Date();
    this.minDate = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};

    this.servicesService.getVerifiedServices(0, this.q)
    .subscribe((res: any) => {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].category === 'bookable') {
          this.services.push(res.data[i]);
        }
      }
    });

    this.clientsService.getClients(1, {}, null, true)
      .subscribe((res: any) => {
        this.allAvailableClients = [...res.data];
        this.availableClients = [...res.data].splice(0, this.allAvailableClients.length);
      })
  }

  close() {
    this.activeModal.close();
  }

  updateServiceModel(event) {
    this.selectedServiceVariant = '0';
    const id = event.id;
    if (this.selectedDay) {
      this.selectedDay = undefined;
      this.times = [];
    }
    this.servicesService.getService(id)
      .subscribe(res => {
        this.service = res;
        this.service.pickerInterval = 15;

        this.dateDisabled = (date: NgbDateStruct) => {
          let disabled = false;
          let checkedDate = Moment({year: date.year, month: date.month - 1, day: date.day});

          if (this.service.availability.indexOf(checkedDate.subtract(1, 'day').day()) == -1) {
            return true
          }

          for (let i = 0; i < this.service.unavailable.length; i++) {
            let fromDate = Moment(this.service.unavailable[i].from);
            let toDate = Moment(this.service.unavailable[i].to);

            if (checkedDate.add(1, 'day').isBetween(fromDate, toDate, 'day', '[]')) {
              disabled = true;
              break;
            }
          }
          return disabled;
        }
      })
  }

  variantChanged() {let momentSelectedDate;
    if (this.selectedDate) {
      momentSelectedDate = Moment(this.selectedDate);
      let parsedSelectedDate = {
        year: (momentSelectedDate.year()),
        month: (momentSelectedDate.month() + 1),
        day: (momentSelectedDate.date()),
      };

      this.selectedDay = parsedSelectedDate;

      this.requestedServiceStart = momentSelectedDate.format("HH:mm");
      this.selectedDate = undefined;
    }

    if (this.selectedDay) {
      this.dayChange(this.selectedDay)
    }
  }

  getTimeAvailabilities(date, interval) {

    this.servicesService.getServiceAvailability(this.service.id, date)
      .subscribe( (ava: any) => {
        // this.calculateHours(ava.opened, ava.bookings, interval)
        this.calculateHours([{from: '00:00', to: '24:00'}], ava.bookings, interval)
      })
  }

  selectTime(time) {
    this.selectedServiceEnd = Moment(time.from, 'HH:mm').add(this.selectedServiceVariant.interval, 'minutes').format('HH:mm');
    this.selectedTimes = [];
    this.selectedTimes.push(time)
    this.times.forEach(time => {
      time['picked'] = false;
    })
    time.picked = true;

    if (!this.selectedDay) {
      let today = new Date();

      this.selectedDay = {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate()
      };
    }
  }

  calculateHours(openHours, bookings, interval) {
    this.times = [];
    for (let i = 0; i < openHours.length; i++) {
      let startDay = Moment(openHours[i].from, 'HHmm');
      let endDay = Moment(openHours[i].to, 'HHmm');
      let t = endDay.diff(startDay, 'm') / interval;

      while (t--) {
        let hourObject = {
          from: startDay.format('HH:mm'),
          to: "",
          available: bookings.indexOf(startDay.format('HHmm')) == -1
        };
        startDay.add(interval, 'minute');
        hourObject.to = startDay.format('HH:mm');
        this.times.push(hourObject);

        if (this.requestedServiceStart && this.requestedServiceStart == hourObject.from) {
          if (hourObject.available == true) {
            this.selectedServiceStart = hourObject;
            this.selectTime(this.selectedServiceStart)
          }
          this.requestedServiceStart = undefined;
        }
      }
    }
  }

  dayChange(event) {
    this.selectedServiceStart = '0';
    this.selectedServiceEnd = '';
    if (this.service && this.selectedDay) {
      this.getTimeAvailabilities(event, this.service.pickerInterval);
    }
  }

  reserve(times) {
    if (this.selectedClient == '' && (!this.data.email || !this.data.phone)) {
      let r = confirm("Pamiętaj, że nie podając adresu e-mail lub numeru telefonu podopiecznego nie otrzyma on informacji i przypomnienia o zarezerwowanej usłudze");
      if (r == false) {
        return;
      }
    }

    this.loading = true;

    let reservationDay;
    if (this.selectedService.category == 'unitable') {
      let today = new Date();
      reservationDay = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
    } else {
      reservationDay = this.selectedDay;
    }

    let reservation = {
      day: reservationDay,
      hours: []
    }

    if (this.selectedService.category == 'unitable') {
      reservation.hours.push({from: "00:00", to: "00:00"});
    } else {
      for (let i = 0; i < times.length; i++) {
        if (times[i].picked) {
          times[i].to = Moment(times[i].from, 'HH:mm').add(this.selectedServiceVariant.interval, 'minutes').format('HH:mm');
          reservation.hours.push(times[i]);
        }
      }
    }

    let data = {
      reservation: reservation,
      service: this.service,
      person: this.data,
      service_variant_id: this.selectedServiceVariant.id,
      packetId: this.selectedPacket ? this.selectedPacket : null,
      // label_color: this.selectedColor
    }

    this.servicesService.reserveService(data)
      .subscribe(
        (data: any) => {
          let bookingsIds = []
          for (let i = 0; i < data.length; i++) {
            bookingsIds.push(data[i].id);
          }
          this.toastr.success('Utworzono nową rezerwację', 'Sukces!');
          this.success.emit(data);
          this.close();
        },
        err => {
          this.loading = false;
        }
      )
  }

  validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  closeDatePicker(e, d) {
    let datePickerClicked = false;
    for (let element of e.path) {
      if (element.localName == 'ngb-datepicker') {
        datePickerClicked = true;
      }
    }
    if (datePickerClicked == false) {
      d.close();
    }
  }

  fillClientData(client) {
    this.showClientForm = true;
    this.selectedClient = `${client.first_name} ${client.last_name}`;
    this.selectedClientObject = client;
    this.data.id = client.id;
    this.data.first_name = client.first_name;
    this.data.last_name = client.last_name;
    this.data.email = client.email;
    this.clearPacket();
    this.clientDropdown.close();
  }

  clearPacket() {
    this.selectedPacket = 0;
  }

  newClient() {
    this.showClientForm = true;
    this.selectedClient = '0';
    this.selectedClient = '';
    this.selectedClientObject = {};
    this.onSearchClient('');

    this.data.id = '';
    this.data.first_name = '';
    this.data.last_name = '';
    this.data.email = '';
    this.data.phone = '';
    this.data.note = '';
  }

  selectColor(color: string) {
    this.selectedColor = color;
  }

  onSearchClient(query: string) {
    if (query === '') {
      this.availableClients = [...this.allAvailableClients].splice(10, this.allAvailableClients.length);
      return
    }
    const searchRes = this.allAvailableClients.filter(client =>
      (client && client.first_name ? client.first_name.toLowerCase() : '').includes(query.toLowerCase()) ||
      (client && client.last_name ? client.last_name.toLowerCase() : '').includes(query.toLowerCase()) ||
      client.email.toLowerCase().includes(query.toLowerCase())
    );
    this.availableClients = searchRes;
  }
}
