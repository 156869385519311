import { UIRouterModule, Transition } from "@uirouter/angular";
import { AuthComponent } from "./auth.component";
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { DashboardComponent } from './../dashboard/dashboard/dashboard.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionModalComponent } from './../utilities/session-modal/session-modal.component';
import { AngularTokenService } from 'angular-token';

const routesConfig = {
  states: [
    { name: 'auth', redirectTo: 'auth.login', component:  AuthComponent, data:{hideNav: true, isAuth: true}},
    { name: 'auth.login', url:'/', component:  AuthLoginComponent, data: {isAuth: true}},
    {
      name: 'auth.login-reset',
      url:'/login/?&reset',
      component:  AuthLoginComponent,
      data :{isAuth: true}
    },
    {
      name: 'accConfirmed',
      url: '/account/confirmed',
      component: AuthLoginComponent,
      data:{hideNav: true, isAuth: true},
      resolve: [{
        token:'accountConfirmedMessage',
        deps:[AngularTokenService],
        resolveFn:showConfirmAccountMessage
      }]
    },
    { name: 'auth.resetPassword', url: '/password/reset?client_id&expiry&token&uid', component:  ResetPasswordComponent, data:{hideNav: true, isAuth: true}},
    { name: 'auth.setPassword', url: '/password/set?client_id&expiry&token&uid', component:  ResetPasswordComponent, data:{hideNav: true, isAuth: true}}
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)

export function showConfirmAccountMessage(_tokenService) {
  if(_tokenService.userSignedIn()){
    _tokenService.signOut();
  }
  return 'Dziękujemy za potwierdzenie swojego maila. Możesz teraz się zalogować.'
}