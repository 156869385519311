import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UsersService } from '../../api/users.service'
import { SettingsService } from '../../api/settings.service'
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { AngularTokenService } from 'angular-token';
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';

@Component({
  selector: 'image-change',
  templateUrl: './image-change.component.html',
  styleUrls: ['./image-change.component.scss'],
  providers: [TranslatePipe]
})
export class ImageChangeComponent implements OnInit, OnDestroy {

  image: any;
  imageChanged = {
    changed: false
  }
  file: any;
  user: any;
  transitionHook: any;

  @Input()
  blockElements;


  constructor(private usersService: UsersService,
              private settingsService: SettingsService,
              private _tokenService: AngularTokenService,
              private toastr: ToastrService,
              private translatePipe: TranslatePipe,
              private stateService: StateService,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService) {

                this.transitionHook = transitionService.onBefore({from: 'settings.image-change'}, (trans: Transition)=>{
                    return changesValidationService.validate(this.imageChanged)
                })

                this.usersService.getMyProfile()
                  .subscribe(res => {
                    this.user = res;
                  })
    }

  ngOnInit() {
    this.checkChangesService.assignObject(this.imageChanged);
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  imageAdded(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.image = reader.result;
      }
      this.imageChanged['changed'] = true;
    }
  }

  save() {
    let formData:FormData = new FormData();

    if (this.file) {
      formData.append('avatar', this.file, this.file.name);
    }
    this.changesValidationService.resolved = true;

    this.settingsService.saveProfile(formData)
      .subscribe(res => {
        console.log(res);
        this._tokenService.validateToken();
        this.toastr.success(
          this.translatePipe.transform(_i18n('settings.image-changhe.toast-messages.success.saveProfile')),
          'Sukces!'
        );
        this.image = undefined;
      }, err => {
        console.info(err)

        this.toastr.error(
          this.translatePipe.transform(_i18n('settings.image-changhe.toast-messages.error.saveProfile')),
          err.status
        );
      })

  }

  cancel() {
    this.file = undefined;
    this.image = undefined;
    this.imageChanged['changed'] = false;
  }

}
