import { Injectable } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { environment } from "../../environments/environment";
import { Response, RequestOptions, Headers, RequestMethod } from '@angular/http';
import { Observable, Subject } from 'rxjs';
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { map, catchError } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';

export interface Booking {
  id?: number,
  status?: string,
  date: any,
  time_from: string,
  time_to: string,
  service?: any,
  type: string,
  user?: any,
  assigned_service_booking_groups?: any,
  service_booking_group?: any,
  note?: string
  invoice_data: any
  additional_info: any
}

@Injectable()
export class BookingsService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private http: HttpClient
  ) { }

  getBookings(page, query, sort, all = false) {
    let q = "";
    let s = "";
    let p = "";
    if (query) {
      for (let key in query) {
        if (query[key] && key != 'status_not_in') {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (query['status_not_in']) {
      let status_query = '';
      for (let key of query['status_not_in']) {
        status_query += `&q[status_not_in][]=${key}`;
      }
      q = q + status_query;
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    if (all) {
      p = "&except[]=limit&except[]=offset";
    }
    let url = `${environment.apiEndpoint}/bookings?page=${page}${q}${p}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getBooking(serviceId, bookingId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/bookings/${bookingId}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  cancelBooking(serviceId, bookingId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/bookings/${bookingId}`;
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  updateBooking(serviceId, booking) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/bookings/${booking.id}`;
    return this.http.put(url, booking).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  setBookingPresence(service_id, booking_id, presence) {
    let url = `${environment.apiEndpoint}/services/${service_id}/bookings/${booking_id}/presence`;
    return this.http.put(url, {booking: {presence: presence}}).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  setBookingPaymentStatus(service_id, booking_id, status) {
    let url = `${environment.apiEndpoint}/services/${service_id}/bookings/${booking_id}`;
    return this.http.put(url, {booking: {is_payed: status}}).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  setBookingApprovalStatus(service_id, booking_id, status) {
    let url = `${environment.apiEndpoint}/services/${service_id}/bookings/${booking_id}/change_approval_status`;
    return this.http.put(url, {booking: {status: status}}).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

}
