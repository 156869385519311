import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEvents'
})
export class FilterEventsPipe implements PipeTransform {

  transform(items: any[], term: string): any {
    if (!term) {
      return items;
    } else {
      return items.filter(item => item.service.name === term);
    }
  }

}
