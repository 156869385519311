export interface CourseOrExperiece {
  date_from: any;
  date_to: any;
  name: string;
  experience_type: CourseOrExperieceType;
  id?: number;
  created_at?: Date;
  updated_at?: Date;
  currently: boolean;
}

export type CourseOrExperieceType = 'education' | 'work';

export interface ExperienceAttributes {
  experiences_attributes: string;
}

export enum CurrentlyCheckboxText {
  education = 'Jest to moja obecna rola',
  work = 'W trakcie',
}

export enum HeaderText {
  education = 'Edukacja (uczelnie, kursy, szkolenia)',
  work = 'Doświadczenie zawodowe',
}

export enum ModalHeaderText {
  education = 'edukacja',
  work = 'doświadczenie zawodowe',
}

export enum NoContentVariant {
  education = 'ukończonych uczelni, kursów lub szkoleń',
  work = 'doświadczenia zawodowego'
}