import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routerModule } from "./notifications.routing";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { NotificationsService } from "../api/notifications.service";

import { NotificationsComponent } from './notifications.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    NgbModule.forRoot(),
  ],
  providers: [
    NotificationsService
  ],
  declarations: [NotificationsComponent, NotificationsListComponent]
})
export class NotificationsModule { }
