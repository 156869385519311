
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { BookingsService } from '../../api/bookings.service';

import { Subject } from 'rxjs';

@Component({
  selector: 'bookings-filter',
  host: {
    '(document:click)': 'onClick($event)',
  },
  templateUrl: './bookings-filter.component.html',
  styleUrls: ['./bookings-filter.component.scss']
})
export class BookingsFilterComponent implements OnInit {

  @Output()
  filter = new EventEmitter();

  @Output()
  query = new EventEmitter();

  @Input('datePicked') datePicked: string;

  @Input('acceptOnly') acceptOnly: boolean;

  modelChanged: Subject<string> = new Subject<string>();

  dateModel: any;

  q = {
    date_eq: this.datePicked,
    time_from_cont: '',
    time_to_cont: '',
    status_eq: '',
    status_cont: '',
    service_name_cont: '',
    email_cont: '',
    first_name_or_last_name_or_email_cont: '',
    is_payed_eq: '',
    // ONLINE
    service_connectivity_type_eq: ''
  }
  dynamicId: any;

  constructor(private bookingsService: BookingsService,
              private _eref: ElementRef) { }

  ngOnInit() {
    this.modelChanged.pipe(
      debounceTime(600),
      distinctUntilChanged()
    )
    .subscribe(res => {
      if (this.acceptOnly) {
        this.q = {
          ...this.q,
          status_eq: "waiting_for_approval"
        }
      }
      this.bookingsService.getBookings(0, this.q, null)
      .subscribe(res => {
        this.filter.emit(res);
      })
    });
   }

   resetDate(newModel) {
     this.q.date_eq = '';
     this.dateModel = undefined;
     this.query.emit(this.q);
     this.modelChanged.next(newModel);
   }

   openDatepicker(id){
     this.dynamicId = id;
   }

   focusDatepicker(event, d) {
     if (event.relatedTarget) {
       d.open();
       this.openDatepicker(d);
     }
   }

   onClick(event) {
     if(this.dynamicId == undefined){
       // console.log("Dynamic id ==");
     }
     else if(!document.getElementById('booking-date-filter').contains(event.target)) {
       let self = this;
       setTimeout(function(){
         self.dynamicId.close();
       },10);
     }
   }

  updateModel(newModel) {
    if (newModel.year) {
      this.q.date_eq = newModel.year + '-' +  newModel.month + '-' + newModel.day
    }
      this.query.emit(this.q);
      this.modelChanged.next(newModel);
  }

}
