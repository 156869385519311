import { Injectable } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { environment } from "../../environments/environment";
import { Response, RequestOptions, Headers, RequestMethod } from '@angular/http';
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CodesService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private http: HttpClient
  ) { }

  getCode() {
    let url = `${environment.apiEndpoint}/authentication_codes`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  generateCode() {
    let url = `${environment.apiEndpoint}/authentication_codes`;
    return this.http.post(url, {}).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

}
