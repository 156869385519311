import { Injectable } from '@angular/core';
import { of } from 'rxjs/observable/of';
import { Calendar, CalendarIntegrationUrl, CalendarIntegrationCode, Calendars, CalendarIntegrationUpdateParams } from './../calendars/calendars';
import { Observable } from 'rxjs';
import { AngularTokenService } from 'angular-token';
import { environment } from "../../environments/environment";
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { map, catchError } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CalendarService {

  constructor(private _tokenService: AngularTokenService, private errorHandler: ErrorHandlerService, private http: HttpClient) { }

  getIntegrateUrlFromGoogle(): Observable<CalendarIntegrationUrl> {
    const url = `${environment.apiBase}/google_calendars/integration`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    ).map(response => response as CalendarIntegrationUrl)
  }

  integrateWithGoogleCalendar(integrationData: CalendarIntegrationCode): Observable<any> {
    const url = `${environment.apiBase}/google_calendars/integrate`;
    return this.http.post(url, integrationData)
      .map(response => response['calendars'].items as Calendar[])
  }

  updateIntegrationWithGoogleCalnedar(integrationData: CalendarIntegrationUpdateParams): Observable<any> {
    const url = `${environment.apiBase}/google_calendars/update`;
    return this.http.put(url, integrationData)
  }

}
