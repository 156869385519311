import { Component, OnInit, OnDestroy } from '@angular/core';
import { FacilitiesService, Facility } from '../../api/facilities.service';
import { ServicesService, NewService } from '../../api/services.service';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import * as Moment from "moment";
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { CheckModalComponent } from '../../utilities/check-modal/check-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularTokenService } from 'angular-token';
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';

@Component({
  selector: 'facilities-form',
  templateUrl: './facilities-form.component.html',
  styleUrls: ['./facilities-form.component.scss'],
  providers: [TranslatePipe]
})
export class FacilitiesFormComponent implements OnInit, OnDestroy {

  service: Facility = {
    name: '',
    description: '',
    interval: 60,
    location: '',
    city_id: 1,
    openTime: '00:00',
    closeTime: '24:00',
    price: '',
    addresses_attributes: [],
    disciplineSelected: false,
    suggested_discipline: "",
    category: "facility",
    privacy: '0',
  };
  isSaving = false;
  cities:Array<object>;
  disciplines:Array<object>;
  disciplineSelected: number;
  intervalsMin = [60,45,30,15];
  timesFrom = [];
  timesTo = [];
  file: File;
  image;
  errors = false;

  weekdays = [
    {
      name: "Poniedziałek",
      engName: "monday",
      selected: true,
      value: 64
    },
    {
      name: "Wtorek",
      engName: "tuesday",
      selected: true,
      value: 32
    },
    {
      name: "Środa",
      engName: "wednesday",
      selected: true,
      value: 16
    },
    {
      name: "Czwartek",
      engName: "thursday",
      selected: true,
      value: 8
    },
    {
      name: "Piątek",
      engName: "friday",
      selected: true,
      value: 4
    },
    {
      name: "Sobota",
      engName: "saturday",
      selected: true,
      value: 2
    },
    {
      name: "Niedziela",
      engName: "sunday",
      selected: true,
      value: 1
    },
  ]

  transitionHook: any;
  savingInProgress:boolean = false;
  resolved: boolean = false;
  resolvedWith: boolean;
  changesChecked: boolean;

  user_kind;
  events_creation_enabled;
  newFacilityTooltip: string = "";

  constructor(private facilitiesService:FacilitiesService,
              private servicesService:ServicesService,
              private route:UIRouter,
              private stateService:StateService,
              private toastr: ToastrService,
              public config: NgbTooltipConfig,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService,
              private translatePipe: TranslatePipe,
              private modalService: NgbModal,
              public _tokenService: AngularTokenService,
            ) {
                config.placement = 'right';
                config.triggers = 'hover';
                config.container = 'body';
                this.transitionHook = transitionService.onBefore({from: 'facilities.**'}, (trans: Transition)=>{
                  if ((this.image || this.service.addresses_attributes.length > 0) && !this.changesChecked) {
                      let cos:HookResult = new Promise((resolve, reject) => {
                        this.openEditModal()
                        .then((res) => {
                          if (res) {
                            this.resolved = true;
                            this.resolvedWith = true;
                            this.changesChecked = true;
                            resolve(true);
                          } else {
                            this.resolved = true;
                            this.resolvedWith = false;
                            this.changesChecked = false;
                            resolve(false);
                          }
                        })
                      })
                      return cos;
                  } else {
                    this.changesChecked = false;
                    return changesValidationService.validate(this.service);
                  }
                })
                this._tokenService.validateToken().subscribe(
                  res => {
                    this.user_kind = this._tokenService.currentUserData['user_kind'];
                    this.events_creation_enabled = this._tokenService.currentUserData['events_creation_enabled'];

                    if (this.user_kind == 'facility_owner') {
                      this.newFacilityTooltip = "Utwórz nowy obiekt, aby później dodać do niego dostępne w nim usługi"
                    } else {
                      this.newFacilityTooltip = "Utwórz swój profil, aby później dodać do niego świadczone przez Ciebie usługi"
                    }
                  }
                )
              }

  openEditModal(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var modalRef = this.modalService.open(CheckModalComponent);
      modalRef.componentInstance.decision
      .subscribe((decision) => {
        if (decision == true) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      })
    });
  }

  ngOnInit() {
    this._tokenService.validateToken().subscribe(
      res => {
        this.user_kind = this._tokenService.currentUserData['user_kind'];
      }
    )

    this.servicesService.getDisciplinesStream().subscribe(
      (res: Array<object>) => {
        if (res) {
          this.disciplines = JSON.parse(JSON.stringify(res));
        }
      }
    )

    this.facilitiesService.getCitiesStream().subscribe(
      (res: Array<object>) => {
        this.cities = res;
        console.log(res)
      }
    )

    this.createTimesFrom(this.service.interval, '00:00', '24:00')
    this.createTimesTo(this.service.interval, '00:00', '24:00')
    let service2 = {...this.service}
    this.checkChangesService.assignObject(service2);
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  isOtherDisciplineSelected() {
    let selected = false;
    if (this.disciplines) {
      for (let discipline of this.disciplines) {
        if (discipline['selected'] == true && discipline['id'] == 0) {
          selected = true
        }
      }
    }
    return selected;
  }
  checkSelectedDisciplines() {
    let selected = false;
    for (let discipline of this.disciplines) {
      if (discipline['selected']) {
        if (discipline['id'] > 0 || (discipline['id'] == 0 && this.service.suggested_discipline != "")) {
          selected = true
        }
      }
    }
    this.service.disciplineSelected = selected;
  }

  save(form, service) {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;

    this.changesValidationService.resolved = true;
    this.errors = false;

    // new validation
    if (!this.file || !this.service.name || !this.service.description || (this.user_kind == 'facility_owner' && this.service.addresses_attributes.length == 0) || !service.disciplineSelected) {
      this.toastr.warning("Upewnij się, że wypełniłeś wszystkie dane.");
      this.errors = true;
      this.savingInProgress = false;
    }

    if (this.errors) {
      this.savingInProgress = false;
      return;
    }

    this.changesValidationService.resolved = true;

    let disciplinesCopy = JSON.parse(JSON.stringify(this.disciplines))
    if (this.isSaving) {
      return;
    } else {
      this.isSaving = true;
      disciplinesCopy.splice(disciplinesCopy.length-1, 1);
    }
    this.facilitiesService.newService(service, disciplinesCopy, this.weekdays, this.file, 0, 0)
      .subscribe( (service: any) => {
        this.savingInProgress = false;
        for (let i = 0; i < this.disciplines.length; i++) {
          this.disciplines[i]['selected'] = false;
        }
        disciplinesCopy = {};
        this.changesChecked = true;
        this.toastr.success(this.translatePipe.transform(_i18n('facilities.form.toast-messages.success-message')), 'Success!');
        // BELOW CHANGE ONLY TEMP FOR BUILD
        // this.stateService.go('services.new', {primary_service_id: service.id}, {location: 'replace'});
        if (this.events_creation_enabled) {
          this.stateService.go('happenings.new', { primary_service_id: service.id }, { location: 'replace' });
        } else {
          this.stateService.go('services.new', {primary_service_id: service.id}, {location: 'replace'});
        }

      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
        for (let i = 0; i < this.disciplines.length; i++) {
          this.disciplines[i]['selected'] = false;
        }
        this.isSaving = false;
        this.savingInProgress = false;
      })

  }

  createTimesFrom(interval, from, to) {
    this.timesFrom = [];
    let fromMoment = Moment(from, "HH:mm");
    let toMoment = Moment(to, "HH:mm");

    let t = toMoment.diff(fromMoment, 'm') / interval;
    while (t--) {
      this.timesFrom.push(fromMoment.format("HH:mm"));
      fromMoment.add(interval, 'minute');
    }
  }

  createTimesTo(interval, from, to) {
    this.timesTo = [];
    let fromMoment = Moment(from, "HH:mm");
    let toMoment = Moment(to, "HH:mm");

    let t = toMoment.diff(fromMoment, 'm') / interval;
    fromMoment.add(interval, 'minute');
    while (t--) {
      if (t < 1) {
        this.timesTo.push(fromMoment.format("kk:mm"));
      } else {
        this.timesTo.push(fromMoment.format("HH:mm"));
      }
      fromMoment.add(interval, 'minute');
    }
  }

  imageAdded(event) {
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      this.file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.image = reader.result;
      }
    }
  }

  cancel() {
    if (this.route.globals.params.id) {
      this.stateService.go('services.details', {id: this.route.globals.params.id})
    } else {
      this.stateService.go('services.list')
    }
  }

  setAddresses(event) {
    this.service.addresses_attributes = event;
  }

  switchServicePrivacy(privacy) {
    this.service.privacy = privacy;
  }

}
