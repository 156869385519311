import { Directive, AfterViewInit, ElementRef, Renderer2, Input } from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[stickypollyfill]'
})
export class StickypollyfillDirective implements AfterViewInit{

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  @Input()
  offsetTop: number = 42;

  ngAfterViewInit() {
    if (!this.hasStickySupport()) {
      const el = this.elementRef.nativeElement as HTMLElement;
      this.renderer.setStyle(el.parentElement, 'display', 'block');
      fromEvent(document, 'scroll').subscribe(() => {
        const top = el.parentElement.getBoundingClientRect().top;
        if (top < 0) {
          this.renderer.setStyle(el, 'transform', `translate(0, ${Math.abs(top) + this.offsetTop}px)`);
        } else {
          this.renderer.setStyle(el, 'transform', `translate(0, 0)`);
        }
      })
    }
  }

  private hasStickySupport(): boolean {
    let el = document.createElement('a');
    let mStyle=el.style;

    mStyle.cssText="position:sticky;position:-webkit-sticky;position:-ms-sticky;";
    return mStyle.position.indexOf('sticky') !== -1;
  }
}
