import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '@ngx-translate/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';

@Component({
  selector: 'profile-safety',
  templateUrl: './profile-safety.component.html',
  styleUrls: ['./profile-safety.component.scss'],
  providers: [TranslatePipe]
})
export class ProfileSafetyComponent implements OnInit, OnDestroy {

  data = {
    passwordCurrent: '',
    password: '',
    passwordConfirmation: ''
  };
  savingInProgress:boolean = false;
  transitionHook: any;

  constructor(private _tokenService: AngularTokenService,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService,
              private toastr: ToastrService,
              private translatePipe: TranslatePipe) {
                this.transitionHook = transitionService.onBefore({from: 'settings.**'}, (trans: Transition)=>{
                    return changesValidationService.validate(this.data)
                })
              }

  ngOnInit() {
    this.checkChangesService.assignObject(this.data);
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }


  save(formInvalid, data) {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;
    this.changesValidationService.resolved = true;

    this._tokenService.updatePassword(data)
      .subscribe(res => {
        this.savingInProgress = false;
        this.toastr.success(this.translatePipe.transform(_i18n('settings.profile-safety.toast-messages.success')), null);
      }, err => {
        console.log(err)
        this.savingInProgress = false;
        this.toastr.error(this.translatePipe.transform(_i18n('settings.profile-safety.toast-messages.error')), null);
      })
  }

}
