import { Component, OnInit } from '@angular/core';
import { CodesService } from '../../api/codes.service';
import { Http, Response, RequestOptions, Headers, RequestMethod } from '@angular/http';

@Component({
  selector: 'generate-code',
  templateUrl: './generate-code.component.html',
  styleUrls: ['./generate-code.component.scss']
})
export class GenerateCodeComponent implements OnInit {

  hasCode: boolean;
  generateView = false;
  myCode: any;

  constructor(private codesService: CodesService,
              private http:Http) { }

  ngOnInit() {
    this.codesService.getCode()
    .subscribe((res: any) => {
      if (res.length == 0) {
          this.hasCode = false;
      } else {
        this.myCode = res[0].code;
      }
      this.generateView = true;
    })
  }

  generateCode() {
    this.codesService.generateCode()
    .subscribe((res: any) =>{
      this.myCode = res.code;
      this.hasCode = true;
    })
  }


}
