import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { AngularTokenService } from 'angular-token';
import { UsersService } from './../../api/users.service';
import { Http, RequestOptions, RequestMethod } from '@angular/http';
import { environment } from "../../../environments/environment";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  data: any;
  error:boolean = false;
  loading;
  content: string;

  constructor(
    private stateService: StateService,
    private usersService: UsersService,
    private _tokenService: AngularTokenService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    if (this.stateService.current.name == "auth.setPassword") {
      this.content = "set_password_content"
    } else {
      this.content = "reset_password_content"
    }
    this._tokenService.signOut().subscribe(
        res =>      console.log(res),
        error =>    console.log(error)
    );

    this.data = {}
  }

  resetPassword(form, data) {
    this.error = false;

    if (this.loading) {
      return;
    }

    if (form.invalid) {
      return
    }

    if (this.data.password !== this.data.password_confirmation) {
      return;
    }

    this.loading = true;

    let url = `${environment.apiBase}/auth`;
    const headers = new HttpHeaders({
      "access-token": this.stateService.params.token,
      "client": this.stateService.params.client_id,
      "expiry": this.stateService.params.expiry,
      "token-type": "Bearer",
      "uid": decodeURIComponent(this.stateService.params.uid)
    });

    return this.http.put(url, data, { headers }).subscribe(
      success => {
        this.loading = false;
        this._tokenService.validateToken();
        this.stateService.go('dashboard');
      },
      error => {
        this.error = JSON.parse(error._body);
        this.loading = false;
      }
    );
  }

}
