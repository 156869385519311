import { Component, OnInit } from '@angular/core';
import { UIRouter } from "@uirouter/angular";
import { ClientsService } from "./../../../../api/clients.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DashboardReservationModalComponent } from './../../../../dashboard/dashboard/dashboard-reservation-modal/dashboard-reservation-modal.component';

@Component({
  selector: 'reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit {

  public viewTable = false;
  public user_id: number;
  public bookings = [];
  public loading = false;
  public sort: any;
  public pagination: any;
  public user: any;
  public q: any = {
    date_eq: ''
  };
  public page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };


  constructor(
    private route: UIRouter,
    private clientsService: ClientsService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.page.pageNumber = 0;
    this.user_id = this.route.globals.params.id;
    this.setPage({
      offset: 0
    });
    this.clientsService.getClient(this.user_id).subscribe(user => {
      this.user = user;
    });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.clientsService.getBookings(this.user_id, this.page.pageNumber + 1, this.q, this.sort).subscribe((pagedData: any) => {
      this.page.totalElements = pagedData.size;
      this.page.size = pagedData.per_page;
      this.bookings = pagedData.data;
      this.viewTable = true;
    });
  }

  onSort(event) {
    this.loading = true;
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, '_');
    if (prop === 'user_email') {
      prop = 'users_email';
    } else if (prop === 'user_first_name') {
      prop = 'users_first_name';
    } else if (prop === 'user_last_name') {
      prop = 'users_last_name';
    }
    this.sort = prop + ' ' + s.dir;
    this.clientsService.getBookings(this.user_id, 0, this.q, this.sort)
      .subscribe((res: any) => {
        this.bookings = res.data;
        this.loading = false;
      });
  }

  onFilter(event) {
    this.page.totalElements = event.size;
    this.page.size = event.per_page;
    this.bookings = event.data;
  }

  onQueryChange(event) {
    this.q = event;
  }

  openReservationModal() {
    const modalRef = this.modalService.open(DashboardReservationModalComponent);
    modalRef.componentInstance.data = {first_name: this.user['first_name'], last_name: this.user['last_name'], email: this.user['email'], phone: this.user['phone']};
    modalRef.componentInstance.existingUser = true;
    modalRef.componentInstance.success.subscribe(
      data => this.setPage({ offset: 0 })
    );
  }
}
