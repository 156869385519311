import { UIRouterModule } from "@uirouter/angular";
import { AdditionalIncomeComponent } from './additional-income/additional-income.component';
import { AngularTokenService } from 'angular-token';

const routesConfig = {
  states: [
    {
      name: 'additionalIncome',
      url:'/promocje/bonus-za-transakcje',
      component:  AdditionalIncomeComponent,
      data: { requiresAuth: true }
    },
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
