
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { BookingsService, Booking } from "../../api/bookings.service";
import * as Moment from "moment";
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
  providers: [TranslatePipe]
})
export class BookingFormComponent implements OnInit {

  bookingId: string;
  serviceId: string;
  booking: Booking;
  formDirty = false;
  firstChange = false;

  constructor(private stateService: StateService,
              private bookingsService: BookingsService,
              private translatePipe: TranslatePipe,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.serviceId = this.stateService.params.serviceId;
    this.bookingId = this.stateService.params.bookingId;

    if (this.serviceId && this.bookingId) {
      this.bookingsService.getBooking(this.serviceId, this.bookingId).pipe(
        map( (res: any) => {
          res.date = {
            year: Moment(res.date).year(),
            month: Moment(res.date).month() + 1,
            day: Moment(res.date).date(),
          }
          return res
        }))
        .subscribe(res => {
          console.log(res)
          this.booking = res
        })
    }
  }

  formChanged() {
    if (this.firstChange == false) {
      this.formDirty = false;
      this.firstChange = true;
    } else {
      this.formDirty = true;
    }
  }

  save(invalid, booking) {
    if (invalid) {
      this.toastr.error(this.translatePipe.transform(_i18n('booking.form.messages.errors.fill-all-data')),'Error!');
      return
    }
    let bookingObj = Object.assign({}, booking);
    bookingObj.date = "" + bookingObj.date.year + "-" + bookingObj.date.month + "-" + bookingObj.date.day;
    if (this.bookingId) {
      this.bookingsService.updateBooking(this.serviceId, bookingObj)
        .subscribe( (res: any) => {
          console.log(res)
          this.toastr.success(this.translatePipe.transform(_i18n('booking.form.messages.success.saved')), 'Success!');
          this.stateService.go('bookings.details', {serviceId: res.service.id, bookingId: res.id});
        })
    }
  }

  cancel() {
    if (this.bookingId) {
      this.stateService.go('bookings.details', {serviceId: this.serviceId, bookingId: this.bookingId});
    } else {
      this.stateService.go('bookings.list');
    }
  }

}
