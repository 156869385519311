import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../api/users.service'
import { SettingsService } from '../../api/settings.service'
import { AngularTokenService } from 'angular-token';
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';

@Component({
  selector: 'terms-agreements',
  templateUrl: './terms-agreements.component.html',
  styleUrls: ['./terms-agreements.component.scss'],
  providers: [TranslatePipe]
})
export class TermsAgreementsComponent implements OnInit, OnDestroy {

  data: any = {}
  savingInProgress:boolean = false;
  transitionHook: any;

  constructor(private usersService: UsersService,
              private settingsService: SettingsService,
              private _tokenService: AngularTokenService,
              private toastr: ToastrService,
              private translatePipe: TranslatePipe,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService) {
                this.transitionHook = transitionService.onBefore({from: 'settings.**'}, (trans: Transition)=>{
                    return changesValidationService.validate(this.data)
                })

                this.usersService.getMyProfile()
                  .subscribe((res: any) => {
                    this.data.accepted_marketing = res.accepted_marketing
                    this.checkChangesService.assignObject(this.data);
                    this.changesValidationService.resetResolved();
                  })
  }

  ngOnInit() {
    // this.checkChangesService.assignObject(this.data);
    // this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  save(invalid, data) {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;
    this.changesValidationService.resolved = true;

    this.settingsService.saveProfile(data)
      .subscribe(res => {
        this._tokenService.validateToken();
        this.toastr.success(this.translatePipe.transform("Zgoda została zaktualizowana"), 'Success!');
        this.savingInProgress = false;
      }, err => {
        console.info(err)
        this.toastr.error(this.translatePipe.transform("Błąd podczas aktualizacji"), err.status);
        this.savingInProgress = false;
      })
  }

}
