import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routerModule } from './packets.routing';
import { TranslateModule } from "@ngx-translate/core";
import { UtilitiesModule } from "../utilities/utilities.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PacketsService } from "../api/packets.service";

import { PacketsComponent } from './packets.component';
import { PacketsListComponent } from './packets-list/packets-list.component';
import { PacketDetailsComponent } from './packet-details/packet-details.component';
import { PacketFormComponent } from './packet-form/packet-form.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    TranslateModule.forChild(),
    NgxDatatableModule,
    UtilitiesModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    PacketsService
  ],
  declarations: [PacketsComponent, PacketsListComponent, PacketDetailsComponent, PacketFormComponent]
})
export class PacketsModule { }
