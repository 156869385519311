import { Injectable } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { AngularTokenService } from 'angular-token';
import { environment } from '../../environments/environment';
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { map, catchError } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { of } from "rxjs/observable/of";
import { Observable } from 'rxjs';

@Injectable()
export class ClientsService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private http: HttpClient,
  ) { }

  clients = [];

  getClients(page, query, sort, all = false){
    let q = "";
    let s = "";
    let p = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;

    if (all) {
      p = "&except[]=limit&except[]=offset";
    }
    let url = `${environment.apiEndpoint}/clients?page=${page}${q}${p}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )

  }

  getAllClients(userId: number) {
    const url = `${environment.apiEndpoint}/clients?except[]=limit&except[]=offset&user_id_not_eq=${userId}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getClient(id){
    let url = `${environment.apiEndpoint}/clients/${id}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  createClient(data) {
    let url = `${environment.apiEndpoint}/clients`;
    return this.http.post(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  updateClient(id:number, data) {
    let url = `${environment.apiEndpoint}/clients/${id}`;
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  destroyClient(id) {
    let url = `${environment.apiEndpoint}/clients/${id}`;
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getBookings(id, page, query, sort): Observable<any> {
    let q = "";
    let s = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiEndpoint}/clients/${id}/bookings?page=${page}${q}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  downloadRodoStatement(id, name) {
    let url = `${environment.apiEndpoint}/clients/${id}/download_rodo_statement.pdf`;
    return this.http.get(url, {responseType: 'blob'}).pipe(
      map((res: any) => {
        return {
          filename: name + ' rodo.pdf',
          file: res
        };
      }))
  }

  exportToCsv() {
    let url = `${environment.apiEndpoint}/clients/csv_export`;
    return this.http.get(url, {responseType: 'blob'}).pipe(
      map((res: any) => {
        return {
          filename: 'klienci.csv',
          file: res
        };
      }))
  }

  downloadAttachment(client_id, attachment) {
    const name = attachment.name;
    const extension = attachment.extension;
    let url = `${environment.apiEndpoint}/clients/${client_id}/download_attachment/${attachment.id}`;
    return this.http.get(url, {responseType: 'blob'}).pipe(
      map((res: any) => {
        return {
          filename: `${name}.${extension}`,
          file: res
        };
      }))
  }

  getDocuments(id) {
    let url = `${environment.apiEndpoint}/clients/${id}/attachments`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  createDocument(id, data) {
    let attachment = {
      source: data.file,
      name: data.name,
    }
    let url = `${environment.apiEndpoint}/clients/${id}/attachments`;
    return this.http.post(url, attachment).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getNotes(id) {
    let url = `${environment.apiEndpoint}/clients/${id}/notes`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }
  createNote(id, data) {
    let url = `${environment.apiEndpoint}/clients/${id}/notes`;
    return this.http.post(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }
}

