import { Injectable } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { AppConfig } from '../app.config';
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export interface Transaction {
  id: number,
  date: string,
  from: any,
  to: any,
  amount: string,
  for: any,
  type: string
}

@Injectable()
export class TransactionsService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  getTransactions(page, query, sort) {
    // let q = "q[service_category_eq]=unitable";
    let q = "";
    let s = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiEndpoint}/transactions?page=${page}&${q}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getTransaction(id) {
    let url = `${environment.apiEndpoint}/transactions/${id}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

}

