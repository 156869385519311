import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { BookingsService } from '../../../api/bookings.service';
import { ServicesService } from '../../../api/services.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit {

  @Output()
  filter = new EventEmitter();

  @Output()
  query = new EventEmitter();

  @Output()
  dateResetInitiator = new EventEmitter();

  @Input('datePicked') datePicked: string;

  modelChanged: Subject<string> = new Subject<string>();

  q = {
    service_id_eq: '',
    date_eq: this.datePicked,
    first_name_or_last_name_or_email_cont: ''
  };
  qs = {
    status_not_eq: 'canceled',
    verification_status_eq: 'verified'
  };
  services = [];

  constructor(
    private bookingsService: BookingsService,
    private servicesService: ServicesService,
  ) { }

  ngOnInit() {
    this.servicesService.getVerifiedServices(0, this.qs)
    .subscribe((res: any) => {
      this.services = res.data;
    })

    this.modelChanged.pipe(
      debounceTime(600),
      distinctUntilChanged(),
    )
     .subscribe(res => {
       this.q['status_not_eq'] = 'canceled';
       this.bookingsService.getBookings(0, this.q, null)
       .subscribe(res => {
         this.filter.emit(res);
       })
     });
  }

  updateModel(newModel) {
    this.q['status_not_eq'] = 'canceled';
    this.q.date_eq = this.datePicked;
    this.query.emit(this.q);
    this.modelChanged.next(newModel);
  }

  resetDate() {
    this.dateResetInitiator.emit();
  }

}
