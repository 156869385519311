import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HappeningsService, NewService } from '../../api/happenings.service';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from '@uirouter/angular';
import * as Moment from 'moment';
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { CheckModalComponent } from '../../utilities/check-modal/check-modal.component';
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'happenings-form',
  templateUrl: './happenings-form.component.html',
  styleUrls: ['./happenings-form.component.scss'],
  providers: [TranslatePipe]
})
export class HappeningsFormComponent implements OnInit, OnDestroy {

  minDate: any;

  service: NewService = {
    name: '',
    description: '',
    interval: 60,
    location: '',
    city_id: 1,
    openTime: '00:00',
    closeTime: '24:00',
    price: '',
    addresses_attributes: [],
    disciplineSelected: false,
    suggested_discipline: "",
    primary_service_id: null,
    category:'bookable',
    cancellation_before_time: '0',
    periodically: 'false',
    privacy: '0',
    account_free_registration: 'false',
    external_registration: 'false',
    external_registration_url: '',
    // ONLINE
    connectivity_type: 'offline'
  };

  emptyTerm = {
    date_from: '',
    time_from: '00:00',
    date_to: '',
    time_to: '24:00',
    users_limit: '',
    happening_schedule_variants_attributes: [
      {
        name: '',
        price: '',
        service_prices_attributes: [
          {
            price: null,
            date_from: null,
            date_to: null,
            time_from: '00:00',
            time_to: '24:00',
            // remove below?
            from: null,
            to: null
          },
        ]
      }
    ]
  };
  emptyVariant = {
    name: '',
    price: '',
    service_prices_attributes: [
      {
        price: null,
        date_from: null,
        date_to: null,
        time_from: '00:00',
        time_to: '24:00',
        // remove below?
        from: null,
        to: null
      }
    ]
  };
  emptyPrice = {
    price: null,
    date_from: null,
    date_to: null,
    time_from: '00:00',
    time_to: '24:00',
    // remove below?
    from: null,
    to: null
  };

  terms = [];
  emptyServiceTerm = {
    name: '',
    content: '',
    is_required: 'false'
  };
  serviceTerms = [];
  emptyServiceCheckoutFieldInput = {
    name: '',
    placeholder: '',
    is_required: 'false',
  };
  serviceCheckoutFieldInputs = [];
  emptyServiceAttachment = {
    name: '',
    source: null,
  };
  serviceAttachments = [];
  primary_service_id: string;
  isSaving = false;
  disciplines:Array<object>;
  disciplineSelected: number;

  baseInterval: number = 15;
  intervalsMin = [480, 420, 360, 300, 240, 180, 120, 90, 75, 60, 45, 30, 15];
  timesFrom = [];
  timesTo = [];
  file: File;
  image;
  errors = false;
  user_kind: any;

  weekdays = [
    {
      name: "Poniedziałek",
      engName: "monday",
      selected: true,
      value: 64
    },
    {
      name: "Wtorek",
      engName: "tuesday",
      selected: true,
      value: 32
    },
    {
      name: "Środa",
      engName: "wednesday",
      selected: true,
      value: 16
    },
    {
      name: "Czwartek",
      engName: "thursday",
      selected: true,
      value: 8
    },
    {
      name: "Piątek",
      engName: "friday",
      selected: true,
      value: 4
    },
    {
      name: "Sobota",
      engName: "saturday",
      selected: true,
      value: 2
    },
    {
      name: "Niedziela",
      engName: "sunday",
      selected: true,
      value: 1
    },
  ]

  cancellation = [
    {
      name: 'odwołanie darmowe bez względu na czas odwołania',
      value: '0'
    },
    {
      name: 'do 7 dni od dokonanej płatności',
      value: '168'
    },
    {
      name: 'do 14 dni od dokonanej płatności',
      value: '336'
    },
    {
      name: 'do 30 dni od dokonanej płatności',
      value: '720'
    },
    {
      name: 'brak możliwości darmowego odwołania',
      value: '-1'
    },
  ];

  transitionHook: any;
  savingInProgress:boolean = false;
  resolved: boolean = false;
  resolvedWith: boolean;
  changesChecked: boolean;

  constructor(
    private route: UIRouter,
    private stateService: StateService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private happeningsService: HappeningsService,
    private checkChangesService: ServiceChangesCheckService,
    private changesValidationService: ServiceChangesValidationService,
    private transitionService: TransitionService,
    private translatePipe: TranslatePipe,
    private _tokenService: AngularTokenService,
  ) {
    this.transitionHook = transitionService.onBefore({from: 'happenings.**'}, (trans: Transition)=>{
      if ((this.image || this.service.addresses_attributes.length > 0) && !this.changesChecked) {
          let cos:HookResult = new Promise((resolve, reject) => {
            this.openEditModal()
            .then((res) => {
              if (res) {
                this.resolved = true;
                this.resolvedWith = true;
                this.changesChecked = true;
                resolve(true);
              } else {
                this.resolved = true;
                this.resolvedWith = false;
                this.changesChecked = false;
                resolve(false);
              }
            })
          })
          return cos;
      } else {
        if (this.changesChecked !== true) {
          this.changesChecked = false;
          return changesValidationService.validate(this.service);
        } else {
          return true;
        }
      }
    })
    this.primary_service_id = this.route.globals.params.primary_service_id;
    if (this.primary_service_id) {
      this.service.primary_service_id = this.primary_service_id;
    }
  }

  ngOnInit() {
    this.addTerm();
    const today = new Date();
    this.minDate = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};

    this._tokenService.validateToken().subscribe(
      res => {
        this.user_kind = this._tokenService.currentUserData['user_kind'];
      }
    )

    this.happeningsService.getDisciplinesStream()
      .subscribe((res: Array<object>) => {
        if (res) {
          this.disciplines = JSON.parse(JSON.stringify(res));
        }
      })

    this.createTimesFrom(this.baseInterval, '00:00', '24:00')
    this.createTimesTo(this.baseInterval, '00:00', '24:00')
    this.checkChangesService.assignObject(this.service);
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  openEditModal(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var modalRef = this.modalService.open(CheckModalComponent);
      modalRef.componentInstance.decision
      .subscribe((decision) => {
        if (decision == true) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      })
    });
  }
  isOtherDisciplineSelected() {
    let selected = false;
    if (this.disciplines) {
      for (let discipline of this.disciplines) {
        if (discipline['selected'] == true && discipline['id'] == 0) {
          selected = true
        }
      }
    }
    return selected;
  }
  checkSelectedDisciplines() {
    let selected = false;
    for (let discipline of this.disciplines) {
      if (discipline['selected']) {
        if (discipline['id'] > 0 || (discipline['id'] == 0 && this.service.suggested_discipline != "")) {
          selected = true
        }
      }
    }
    this.service.disciplineSelected = selected;
  }

  validTerms() {
    for (let term of this.terms) {
      if (!term.date_from || !term.time_from || !term.date_to || !term.time_to) {
        return false;
      }
    }
    return true;
  }

  save(form, service) {

    // remove below condition
    // if (this.savingInProgress === false) {
    //   console.info(this.terms)
    //   return;
    // }

    // test start
    this.service['service_terms_attributes'] = JSON.stringify(this.serviceTerms);
    this.service['service_checkout_form_inputs_attributes'] = JSON.stringify(this.serviceCheckoutFieldInputs);
    this.service['attachments'] = this.serviceAttachments;//JSON.stringify(this.serviceCheckoutFieldInputs); // serviceAttachments
    // console.info(this.service['service_terms_attributes'])
    // console.info(this.service['service_checkout_form_inputs_attributes'])
    // console.info(this.service['attachments_attributes'])

    // if (this.serviceAttachments.length > 0) {
    //   const filesData = []
    //   for (let i = 0; i < this.serviceAttachments.length; i++) {
    //     const formData: FormData = new FormData();
    //     formData.append('name', this.serviceAttachments[i].name);
    //     formData.append('source', this.serviceAttachments[i].source, this.serviceAttachments[i].source.name);
    //     filesData.push(formData);
    //     console.info("AFTER PUSH 1")
    //   }
    //   this.service['attachments_attributes'] = filesData;
    //   // const formData: FormData = new FormData();
    //   // formData.append('source', this.file, this.file.name);
    // }
    // if (!this.savingInProgress) {
    //   return;
    // }
    // test end
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;

    this.changesValidationService.resolved = true;
    this.errors = false;

    // console.info("service.external_registration && !service.external_registration_url")
    // console.info(service.external_registration)
    // console.info(service.external_registration_url)

    // uncomment condition below
    // if (!this.file || !this.service.name || !this.service.description || !this.validTerms() || this.service.addresses_attributes.length == 0 || !service.disciplineSelected || ((service.external_registration == false || service.external_registration == 'false') && service.external_registration_url.trim() == '')) {
    // if (!this.file || !this.service.name || !this.service.description || !this.validTerms() || this.service.addresses_attributes.length == 0 || !service.disciplineSelected || service.external_registration && !service.external_registration_url) {
    if (!this.file || !this.service.name || !this.service.description || !this.validTerms() || this.service.addresses_attributes.length == 0 || !service.disciplineSelected) {
      this.toastr.warning(this.translatePipe.transform('service.form.toast-messages.warning.no-all-data'), null);
      this.errors = true;
      this.savingInProgress = false;
    }

    if (this.errors) {
      this.savingInProgress = false;
      return;
    }

    let disciplinesCopy = JSON.parse(JSON.stringify(this.disciplines))
    if (this.isSaving) {
      return;
    } else {
      this.isSaving = true;
      disciplinesCopy.splice(disciplinesCopy.length-1, 1);
    }

    this.service["schedules"] = this.terms;
    this.service['service_terms_attributes'] = JSON.stringify(this.serviceTerms);
    this.service['service_checkout_form_inputs_attributes'] = JSON.stringify(this.serviceCheckoutFieldInputs);

    this.happeningsService.newService(service, disciplinesCopy, this.weekdays, this.file, 0, 0)
      .subscribe( (service: any) => {
        this.savingInProgress = false;
        for (let i = 0; i < this.disciplines.length; i++) {
          this.disciplines[i]['selected'] = false;
        }
        disciplinesCopy = {};
        this.changesChecked = true;
        this.checkChangesService.assignObject(this.service);
        this.toastr.success(this.translatePipe.transform('service.form.toast-messages.success.created-service'), 'Success!');
        this.stateService.go('happenings.details', {id: service.id});
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
        for (let i = 0; i < this.disciplines.length; i++) {
          this.disciplines[i]['selected'] = false;
        }
        this.isSaving = false;
        this.savingInProgress = false;
      })

  }

  createTimesFrom(interval, from, to) {
    this.timesFrom = [];
    let fromMoment = Moment(from, "HH:mm");
    let toMoment = Moment(to, "HH:mm");

    let t = toMoment.diff(fromMoment, 'm') / interval;
    while (t--) {
      this.timesFrom.push(fromMoment.format("HH:mm"));
      fromMoment.add(interval, 'minute');
    }
  }


  createTimesTo(interval, from, to) {
    this.timesTo = [];
    let fromMoment = Moment(from, "HH:mm");
    let toMoment = Moment(to, "HH:mm");

    let t = toMoment.diff(fromMoment, 'm') / interval;
    fromMoment.add(interval, 'minute');
    while (t--) {
      if (t < 1) {
        this.timesTo.push(fromMoment.format("kk:mm"));
      } else {
        this.timesTo.push(fromMoment.format("HH:mm"));
      }
      fromMoment.add(interval, 'minute');
    }
  }

  imageAdded(event) {
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      this.file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.image = reader.result;
      }
    }
  }

  priceInputChanged() {
    this.service.price = parseInt(this.service.price, 10) < 0 ? String(0) : this.service.price;
  }

  switchServiceCategory(periodically) {
    this.service.category = periodically;

    if (periodically === true) {
      this.terms = [this.terms[0]];
    }
  }

  switchServicePrivacy(privacy) {
    this.service.privacy = privacy;
  }

  switchServiceRegistrationOption(option) {
    this.service.account_free_registration = option;
  }

  switchServiceExternalOption(option) {
    this.service.external_registration = option;
  }

  // ONLINE
  switchServiceOnline(online) {
    this.service.connectivity_type = online;
  }

  setAddresses(event) {
    this.service.addresses_attributes = event;
  }

  startDayChange(event, term) {
    if (term.date_to === '') { return; }

    if (event.year >= term.date_to.year && event.month >= term.date_to.month && event.day > term.date_to.day) {
      term.date_to = term.date_from;
    }
  }

  addTerm() {
    const term = JSON.parse(JSON.stringify(this.emptyTerm));
    this.terms.push(term);
  }

  removeTerm(i) {
    if (this.terms.length > 1) {
      let r = confirm('Czy na pewno chcesz usunąć termin wydarzenia?')
      if (r === true) {
        this.terms.splice(i, 1);
      }
    }
  }

  addVariant(i) {
    const variant = JSON.parse(JSON.stringify(this.emptyVariant));
    this.terms[i].happening_schedule_variants_attributes.push(variant);
  }

  removeVariant(i, vi) {
    if (this.terms[i].happening_schedule_variants_attributes.length > 1) {
      let r = confirm('Czy na pewno chcesz usunąć termin wydarzenia?')
      if (r === true) {
        this.terms[i].happening_schedule_variants_attributes.splice(i, 1);
      }
    }
  }

  addServiceTerm() {
    const serviceTerm = JSON.parse(JSON.stringify(this.emptyServiceTerm));
    this.serviceTerms.push(serviceTerm);
  }
  removeServiceTerm(i) {
    let r = confirm('Czy na pewno chcesz usunąć klauzule?');
    if (r === true) {
      this.serviceTerms.splice(i, 1);
    }
  }

  addServiceCheckoutFieldInput() {
    const emptyServiceCheckoutFieldInput = JSON.parse(JSON.stringify(this.emptyServiceCheckoutFieldInput));
    this.serviceCheckoutFieldInputs.push(emptyServiceCheckoutFieldInput);
  }
  removeServiceCheckoutFieldInput(i) {
    let r = confirm('Czy na pewno chcesz usunąć klauzule?');
    if (r === true) {
      this.serviceCheckoutFieldInputs.splice(i, 1);
    }
  }

  addServiceAttachment() {
    const serviceAttachment = JSON.parse(JSON.stringify(this.emptyServiceAttachment));
    this.serviceAttachments.push(serviceAttachment);
  }
  removeServiceAttachment(i) {
    let r = confirm('Czy na pewno chcesz usunąć załącznik?');
    if (r === true) {
      this.serviceAttachments.splice(i, 1);
    }
  }
  attachmentAdded(serviceAttachment, event) {
    const fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      serviceAttachment.source = fileList[0];
    }
  }

  switchRadio(el, val) {
    el.is_required = val;
  }

  variantPriceInputChanged(v) {
    v.price = parseInt(v.price, 10) < 0 ? String(0) : v.price;
  }

  addPrice(arr) {
    const price = JSON.parse(JSON.stringify(this.emptyPrice))
    console.info("THIS IS THE EMPTY PRICE")
    console.info(price)
    arr.push(price)
  }
  removePrice(arr, i) {
    let r = confirm('Czy na pewno chcesz usunąć cenę?');
    if (r === true) {
      arr.splice(i, 1);
    }
  }

  cancel() {
    this.stateService.go('happenings.list');
  }

  accountFreeEnabled() {
    return this._tokenService.currentUserData && this._tokenService.currentUserData['account_free_service_reservation'] === true;
  }

  externalEnabled() {
    return this._tokenService.currentUserData && this._tokenService.currentUserData['external_service_reservation'] === true;
  }

}
