import { UIRouterModule, UIRouter, TransitionService } from "@uirouter/angular";
import { DashboardComponent } from "app/dashboard/dashboard/dashboard.component";
import { previousStateHook } from "app/resolvers/previous-state.resolver";
import { sessionExpiredHook } from 'app/resolvers/session-expired.resolver';
import { authHook } from './resolvers/auth.resolver';

import { NotFoundComponent } from './utilities/not-found/not-found.component';
import { currentStateHook } from "./resolvers/current-state.resolver";

const routesConfig = {
  otherwise: '/',
  states: [
    {
      name: '404',
      url: '/404',
      component:  NotFoundComponent,
      data: { requiresAuth: false, hideNav: true }
    },
  ],
  config: routerConfigFn,
}

export const routerModule = UIRouterModule.forRoot(routesConfig)

export function routerConfigFn(router: UIRouter) {
  const transitionService = router.transitionService;
  previousStateHook(transitionService);
  currentStateHook(transitionService);
  // sessionExpiredHook(transitionService);
  // authHook(transitionService);
}