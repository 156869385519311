import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ClientsService } from "app/api/clients.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UIRouter } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  public noteForm: FormGroup;
  public modalRef: NgbModalRef;
  public notes: any[];
  public page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  constructor(
    private fb: FormBuilder,
    private clientsService: ClientsService,
    private modalService: NgbModal,
    private route: UIRouter,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.noteForm = this.fb.group({
      note: ['', Validators.required]
    });
    this.setPage({
      offset: 0
    });
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.clientsService.getNotes(this.route.globals.params.id).subscribe((pagedData: any) => {
      this.page.totalElements = pagedData.size
      this.page.size = pagedData.per_page
      this.notes = pagedData.data;
    });
  }

  public submit() {
    if (this.noteForm.valid) {
      this.clientsService.createNote(this.route.globals.params.id, {note: {content: this.noteForm.value.note}}).subscribe(
        (pagedData: any) => {
          this.toastr.success("Pomyślnie dodano notatkę", 'Sukces!');
          this.modalRef.close();
          this.ngOnInit();
        },
        error => {
          this.toastr.error("Błąd podczas dodawania rezerwacji, spróbuj ponownie później",'Błąd!');
        }
      );
    } else {
      this.noteForm.get('note').markAsTouched();
    }
  }

  public openModal(content) {
    this.modalRef = this.modalService.open(content);
  }

  get note() {
    return this.noteForm.get('note');
  }
}
