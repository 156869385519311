import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../api/users.service'
import { SettingsService } from '../../api/settings.service'
import { AngularTokenService } from 'angular-token';
import { ToastrService } from 'ngx-toastr';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';

@Component({
  selector: 'email-change',
  templateUrl: './email-change.component.html',
  styleUrls: ['./email-change.component.scss']
})
export class EmailChangeComponent implements OnInit, OnDestroy {

  oldEmail: any;
  data = {
    email: '',
    current_password: ''
  }
  savingInProgress:boolean = false;
  transitionHook: any;

  formSaved: boolean;

  constructor(private usersService: UsersService,
              private settingsService: SettingsService,
              private _tokenService: AngularTokenService,
              private toastr: ToastrService,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService) {
                this.transitionHook = transitionService.onBefore({from: 'settings.**'}, (trans: Transition)=>{
                    return changesValidationService.validate(this.data)
                })

                this.usersService.getMyProfile()
                  .subscribe((res: any) => {
                    this.oldEmail = res.email;
                  })
              }

  ngOnInit() {
    this.checkChangesService.assignObject(this.data);
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  save(invalid, data) {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;
    this.changesValidationService.resolved = true;

    this.settingsService.updateProfile(data).subscribe(
      res => {
        this._tokenService.validateToken();
        this.formSaved = true;
        console.log(res);
        this.savingInProgress = false;
      },
      err => {
        console.info("ERROR")
        console.info(err)
        this.toastr.error("Błąd zapisu", err.status);
        let error = JSON.parse(err["_body"])
        for (let k in error.errors) {
          if (k == "email") {
            if (error.errors[k].indexOf("is invalid") > -1 || error.errors[k].indexOf("is not an email") > -1) {
              this.toastr.error("Niepoprawny adres email");
            }
            if (error.errors[k].indexOf("has already been taken") > -1) {
              this.toastr.error("Email zajęty");
            }
          }
          if (k == "current_password") {
            if (error.errors[k].indexOf("is invalid") > -1) {
              this.toastr.error("Niepoprawne hasło");
            }
          }
        }
        this.savingInProgress = false;
      }
    )
  }

}
