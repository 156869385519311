import { Injectable } from '@angular/core';
import { CalendarUtils } from 'angular-calendar';
import { DayView, GetDayViewArgs } from 'calendar-utils';
import { EVENT_WIDTH } from './day-view-scheduler.constants';

export interface DayViewScheduler extends DayView {
  services: any[];
}

@Injectable()
export class DayViewSchedulerService extends CalendarUtils {
  getDayView(args: GetDayViewArgs): DayViewScheduler {
    const view: DayViewScheduler = {
      ...super.getDayView(args),
      services: []
    };

    if (view.events && view.events[0] && view.events[0].event.meta && view.events[0].event.meta.services) {
      view.services = view.events[0].event.meta.services;
    }

    view.events = view.events.map(dayViewEvent => {
      const index = view.services.findIndex(el => {
        console.log(dayViewEvent);
        
        return el.id == dayViewEvent.event.meta.serviceId
      });
      dayViewEvent.left = index * EVENT_WIDTH;
      return dayViewEvent;
    });
    return view;
  }
}
