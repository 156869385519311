import { Component, OnInit } from '@angular/core';
// import { UIRouter, StateService } from '@uirouter/angular';
import { UIRouter } from '@uirouter/angular';

import { HappeningsService } from '../../api/happenings.service';

@Component({
  selector: 'happenings-info',
  templateUrl: './happenings-info.component.html',
  styleUrls: ['./happenings-info.component.scss']
})
export class HappeningsInfoComponent implements OnInit {

  happening: any;

  constructor(
    private route: UIRouter,
    private happeningsService: HappeningsService,
  ) { }

  ngOnInit() {
    this.happeningsService.getHappening(this.route.globals.params.id)
      .subscribe(
        res => {
          this.happening = res;
        }
      );
  }

}
