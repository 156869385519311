import { UIRouterModule } from "@uirouter/angular";
import { FacilitiesComponent } from './facilities.component';
import { FacilitiesListComponent } from './facilities-list/facilities-list.component';
import { FacilitiesDetailsComponent } from './facilities-details/facilities-details.component';
import { FacilitiesFormComponent } from './facilities-form/facilities-form.component';
import { FacilitiesTransitionComponent } from './facilities-transition/facilities-transition.component';

const routesConfig = {
  states: [
    { 
      name: 'facilities',
      redirectTo: 'facilities.list',
      component:  FacilitiesComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'facilities.list',
      url: '/facilities',
      component:  FacilitiesListComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'facilities.listPage',
      url: '/facilities/page/:pageNumber',
      component:  FacilitiesListComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'facilities-transition',
      url: '/facilities/transition',
      component:  FacilitiesTransitionComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'facilities.new',
      url: '/facilities/new',
      component:  FacilitiesFormComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'facilities.details',
      url: '/facilities/:id',
      component:  FacilitiesDetailsComponent,
      data: { requiresAuth: true }
    },
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
