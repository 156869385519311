import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';
import { PacketsService } from '../../api/packets.service';
import { IUser } from 'app/users/users.interface';
import { ClientsService } from '../../api/clients.service';
import { NgbDropdown, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'packet-form',
  templateUrl: './packet-form.component.html',
  styleUrls: ['./packet-form.component.scss']
})
export class PacketFormComponent implements OnInit {
  user: IUser;
  allAvailableClients = [];
  availableClients = [];
  selectedClient;

  @ViewChild('clientDropdown')
  clientDropdown: NgbDropdown;

  packetGroup: FormGroup;

  constructor(
    private stateService: StateService,
    private toastr: ToastrService,
    private packetsService: PacketsService,
    private clientsService: ClientsService,
    private fb: FormBuilder,
    private dateFormater: NgbDateParserFormatter,
  ) { }

  ngOnInit() {
    this.packetGroup = this.fb.group({
      name: ['', Validators.required],
      user_id: ['', Validators.required],
      number_of_bookings: ['', Validators.compose([Validators.min(1), Validators.required])],
      valid_from: [''],
      valid_to: [''],
      // is_paid: ['', Validators.required]
    }, {
      updateOn: 'change'
    });


    if (this.isEdit) {
      this.packetsService.getPacket(this.packetId).subscribe(
        (res: any) => {
          Object.keys(this.packetGroup.controls).forEach((key) => {
            if (this.packetGroup.get(key)) {
              this.packetGroup.get(key).setValue(res[key]);
            }

            if (key === 'user_id') {
              this.fillClientData(res.user);
            }

            if (key === 'valid_from') {
              this.packetGroup.get('valid_from').setValue(this.dateFormater.parse(res.valid_from || ''));
            }

            if (key === 'valid_to') {
              this.packetGroup.get('valid_to').setValue(this.dateFormater.parse(res.valid_to || ''));
            }
          });
        }
      );
    }

    this.clientsService.getClients(1, {}, null, true)
      .subscribe((res: any) => {
        this.allAvailableClients = [...res.data];
        this.availableClients = [...res.data].splice(0, this.allAvailableClients.length);
      });
  }

  submit() {
    if (!this.packetGroup.get('name') || !this.packetGroup.get('user_id') || !this.packetGroup.get('number_of_bookings')) {
      Object.keys(this.packetGroup.controls).forEach((f) => this.packetGroup.get(f).markAsTouched());
      return;
    }

    this.packetGroup.get('valid_from').setValue(this.dateFormater.format(this.packetGroup.get('valid_from').value));
    this.packetGroup.get('valid_to').setValue(this.dateFormater.format(this.packetGroup.get('valid_to').value));

    const params = {
      service_booking_group: this.packetGroup.value
    };

    this.isEdit ? this.update(params) : this.save(params);
  }

  save(params) {
    this.packetsService.createPacket(params).subscribe(
      (res: any) => {
        this.toastr.success('pakiet został dodany pomyślnie', 'Success!');
        this.stateService.go('packets.details', { id: res.id });
      },
      () => {
        this.toastr.success('spróbuj ponownie później', 'Error!');
      }
    )
  }

  update(params) {
    this.packetsService.updatePacket(this.packetId, params).subscribe(
      (res: any) => {
        this.toastr.success('pakiet został zaktualizowany pomyślnie', 'Success!');
        this.stateService.go('packets.details', { id: res.id });
      },
      () => {
        this.toastr.success('spróbuj ponownie później', 'Error!');
      }
    );
  }

  onSearchClient(query: string) {
    if (query === '') {
      this.availableClients = [...this.allAvailableClients].splice(0, this.allAvailableClients.length);
      return
    }
    const searchRes = this.allAvailableClients.filter(client =>
      (client && client.first_name ? client.first_name.toLowerCase() : '').includes(query.toLowerCase()) ||
      (client && client.last_name ? client.last_name.toLowerCase() : '').includes(query.toLowerCase()) ||
      client.email.toLowerCase().includes(query.toLowerCase())
    );
    this.availableClients = searchRes;
  }

  fillClientData(client) {
    this.selectedClient = `${client.first_name} ${client.last_name}`;
    this.packetGroup.get('user_id').patchValue(client.id);
    this.clientDropdown.close();
  }

  get isEdit() {
    return this.stateService.params['id'] ? true : false;
  }

  get packetId() {
    return this.stateService.params['id'];
  }

}
