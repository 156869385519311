import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FinancialStatsService {

    constructor(
        private http: HttpClient,
    ) { }

    getServices(page: number): Observable<any> {
        let url = `${environment.apiEndpoint}/services?q[category_not_eq]=facility&page=${page}`;
        return this.http.get(url);
    }
}