import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routerModule } from "./promo.routing";
import { AdditionalIncomeComponent } from './additional-income/additional-income.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
  ],
  declarations: [AdditionalIncomeComponent]
})
export class PromoModule { }
