import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';
import { ClientsService } from '../../api/clients.service';

@Component({
  selector: 'client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent implements OnInit {
  data = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    is_valid_user: true
  };
  submitted = false;
  submitAttempt: boolean = false;
  user_exists: boolean = false;

  constructor(
    private stateService: StateService,
    private toastr: ToastrService,
    private clientsService: ClientsService,
  ) { }

  ngOnInit() {
    if (this.isEdit) {
      this.clientsService.getClient(this.clientId).subscribe(
        (res: any) => {
          const { first_name, last_name, email, phone, is_valid_user } = res;
          this.data = { first_name, last_name, email, phone, is_valid_user }
        }
      )
    }
  }

  submit() {
    this.submitted = true;
    this.submitAttempt = true;

    if (!this.data.first_name || !this.data.last_name || this.user_exists) {
      this.submitAttempt = false;
      return
    }

    if (!this.data.email || !this.data.phone) {
      let r = confirm("Pamiętaj, że nie podając adresu e-mail lub numeru telefonu podopiecznego nie otrzyma on informacji i przypomnienia o zarezerwowanej usłudze");
      if (r == false) {
        return;
      }
    }

    const params = {
      client: this.data
    };

    if(!this.isEdit) {
      this.save(params);
    } else {
      this.update(params);
    }

  }

  save(params) {
    this.clientsService.createClient(params).subscribe(
      (res: any) => {
        this.submitted = false;
        this.submitAttempt = false;
        this.toastr.success('podopieczny został dodany pomyślnie', 'Success!');
        this.stateService.go('clients.details', {id: res.id});
      },
      err => {
        this.submitted = false;
        this.submitAttempt = false;
        this.toastr.success('spróbuj ponownie później', 'Error!');
      }
    )
  }

  update(params) {
    this.clientsService.updateClient(this.clientId, params).subscribe(
      (res: any) => {
        this.submitted = false;
        this.toastr.success('podopieczny został zaktualizowany pomyślnie', 'Success!');
        this.stateService.go('clients.details', {id: res.id});
      },
      err => {
        this.submitted = false;
        this.toastr.success('spróbuj ponownie później', 'Error!');
      }
    )
  }

  modelChanged(data) {
    this.user_exists = false
    if (data.first_name && data.last_name) {
      const q = {
        first_name_eq: data.first_name,
        last_name_eq: data.last_name,
      }
      this.clientsService.getClients(0, q, null, true)
        .subscribe(
          (res: any) => {
            if (res.data.length > 0) {
              this.user_exists = true;
            }
          }
        );
    }
  }

  get isEdit() {
    return this.stateService.params['id'] ? true : false;
  }

  get clientId() {
    return this.stateService.params['id']
  }
}
