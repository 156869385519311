import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from '../../api/services.service';
import { Observable } from 'rxjs';
import { ReviewInterface } from '../../services/models/review.interface';
import { tap } from 'rxjs/operators';
import { PageEventInterface } from '../models/datatable.events.interface';
import { ServiceReviewResponseComponent } from '../service-review-response/service-review-response.component';

@Component({
  selector: 'service-reviews',
  templateUrl: './service-reviews.component.html',
  styleUrls: ['./service-reviews.component.scss']
})
export class ServiceReviewsComponent implements OnInit {

  @Input()
  serviceId: string | number;

  @Input() view: number;

  reviews$: Observable<ReviewInterface[]>;

  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 1,
  };

  constructor(
    private modalService: NgbModal,
    private servicesService: ServicesService,
  ) {
    this.view = 1;
  }

  ngOnInit() {
    this.getReviews();
  }

  getReviews(): void {
    this.reviews$ = this.servicesService.getServiceReview(this.serviceId, this.page.pageNumber)
    .pipe(
      tap((pagedData) => {
        this.page.totalElements = pagedData.size;
        this.page.size = pagedData.per_page;
      })
    )
    .map(data => data.data)
  }

  setPage(page: PageEventInterface) {
    this.page.pageNumber = page.offset + 1;
    this.getReviews();
  }

  respond(review) {
    const modalRef = this.modalService.open(ServiceReviewResponseComponent, {windowClass: 'align-modal-center'});
    modalRef.componentInstance.review = review;
    modalRef.componentInstance.reviewableId = this.serviceId;
    modalRef.componentInstance.responseCreated.subscribe((response) => {
      review.response = {
        content: response.response_content,
        response_status: 'pending',
      };
    });
  }
}
