import { Component } from '@angular/core';
import { environment } from "../environments/environment";
import { TranslateService } from '@ngx-translate/core';
import * as Moment from 'moment';
import { StateService } from '@uirouter/core';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { AngularTokenService } from 'angular-token';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private stateService: StateService,
    private translate: TranslateService,
    private tokenService: AngularTokenService,
  ) {

    //i18n
    registerLocaleData(localePl);
    translate.addLangs(["en", "pl"]);
    translate.setDefaultLang('pl');

    let browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');

    if (Moment().format('X') >= localStorage.getItem('expiry')) {
      let state = "auth"
      if (window.location.href.indexOf('/password/reset') != -1) {
        state = 'auth.resetPassword'
      }
      if (window.location.href.indexOf('/password/set') != -1) {
        state = 'auth.setPassword'
      }
      this.tokenService.signOut().subscribe(
          res => this.stateService.go(state, { location: true }),
          err => this.stateService.go(state, { location: true })
      );
    }
  }
}
