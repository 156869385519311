import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HappeningsService, NewService } from '../../api/happenings.service';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from '@uirouter/angular';
import * as Moment from 'moment';
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { CheckModalComponent } from '../../utilities/check-modal/check-modal.component';
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'happenings-details-edit',
  templateUrl: './happenings-details-edit.component.html',
  styleUrls: ['./happenings-details-edit.component.scss'],
  providers: [TranslatePipe]
})
export class HappeningsDetailsEditComponent implements OnInit, OnDestroy {

  minDate: any;
  submitted: boolean = false;
  service: any;
  dirty:boolean = false;

  primary_service_id: string;
  isSaving = false;
  disciplines: Array<any>;
  disciplineSelected: number;

  baseInterval: number = 15;
  intervalsMin = [480, 420, 360, 300, 240, 180, 120, 90, 75, 60, 45, 30, 15];
  timesFrom = [];
  timesTo = [];

  errors = false;
  user_kind: any;

  cancellation = [
    {
      name: 'odwołanie darmowe bez względu na czas odwołania',
      value: '0'
    },
    {
      name: 'do 7 dni od dokonanej płatności',
      value: '168'
    },
    {
      name: 'do 14 dni od dokonanej płatności',
      value: '336'
    },
    {
      name: 'do 30 dni od dokonanej płatności',
      value: '720'
    },
    {
      name: 'brak możliwości darmowego odwołania',
      value: '-1'
    },
  ];

  transitionHook: any;
  savingInProgress:boolean = false;
  resolved: boolean = false;
  resolvedWith: boolean;
  changesChecked: boolean;

  constructor(
    private route: UIRouter,
    private stateService: StateService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private happeningsService: HappeningsService,
    private checkChangesService: ServiceChangesCheckService,
    private changesValidationService: ServiceChangesValidationService,
    private transitionService: TransitionService,
    private translatePipe: TranslatePipe,
    private _tokenService: AngularTokenService,
  ) { }

  ngOnInit() {
    this.happeningsService.getService(this.route.globals.params.id)
    .subscribe((service: any) => {
      const serviceObject = {
        id: service.id,
        name: service.name,
        description: service.description,
        addresses: service.addresses,
        disciplines: service.disciplines,
        service_type: service.service_type,
        price: service.price,
        cancellation_before_time: service.cancellation_before_time,
        user: service.hosts[0].id,
        privacy: String(service.privacy),
        account_free_registration: String(service.account_free_registration),
        external_registration: String(service.external_registration),
        external_registration_url: service.external_registration_url,
        // ONLINE
        connectivity_type: String(service.connectivity_type)
      };
      this.service = serviceObject;
      this.checkChangesService.assignObject(this.service);

      this.happeningsService.getDisciplinesStream()
        .subscribe((res) => {
          if (res) {
            this.disciplines = JSON.parse(JSON.stringify(res));
            if (this.disciplines[this.disciplines.length - 1].id === 0) {
              this.disciplines.splice(this.disciplines.length - 1, 1);
            }
            for (const discipline of this.service.disciplines) {
              for (const globalDiscipline of this.disciplines) {
                if (globalDiscipline.id === discipline.id) {
                  globalDiscipline['selected'] = true;
                }
              }
            }
          }
        });


    }, err => {
      console.error(err);
      this.toastr.error(err.statusText, err.status);
    })
  }

  ngOnDestroy() {
  }

  openEditModal(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var modalRef = this.modalService.open(CheckModalComponent);
      modalRef.componentInstance.decision
      .subscribe((decision) => {
        if (decision == true) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      })
    });
  }

  checkSelectedDisciplines() {
    let selected = false;
    for (const discipline of this.disciplines) {
      if (discipline['selected']) {
        if (discipline['id'] > 0 || (discipline['id'] === 0 && this.service.suggested_discipline !== '')) {
          selected = true;
        }
      }
    }
    return selected;
  }

  save(formRef, service) {
    this.submitted = true;

    // if (!this.service.name || !this.service.description || (this.service.service_type == "service" && !this.checkSelectedDisciplines()) || (service.external_registration && !service.external_registration_url)) {
    if (!this.service.name || !this.service.description || (this.service.service_type == "service" && !this.checkSelectedDisciplines())) {
      return
    }
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;

    let discipline_ids = []
    for (let i = 0; i < this.disciplines.length; i++) {
      if (this.disciplines[i]['selected']) {
        discipline_ids.push(this.disciplines[i].id)
      }
    }
    service['discipline_ids'] = JSON.stringify(discipline_ids);

    this.happeningsService.editService(service)
      .subscribe(
        service => {
          this.toastr.success(this.translatePipe.transform('service.edit.toast-messages.success.service-edited'), 'Success!');
          this.savingInProgress = false;
          this.dirty = false;
          this.checkChangesService.assignObject(this.service);
          this.stateService.go('happenings.details', {id: this.route.globals.params.id});
        },
        err => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
          this.savingInProgress = false;
          this.dirty = false;
        }
      )
  }

  switchServicePrivacy(privacy) {
    this.service.privacy = privacy;
  }

  switchServiceRegistrationOption(option) {
    this.service.account_free_registration = option;
  }

  switchServiceExternalOption(option) {
    this.service.external_registration = option;
  }

  // ONLINE
  switchServiceOnline(online) {
    this.service.connectivity_type = online;
  }

  priceInputChanged() {
    this.service.price = parseInt(this.service.price, 10) < 0 ? String(0) : this.service.price;
  }

  setAddresses(event) {
    this.service.addresses_attributes = event;
  }

  cancel() {
    this.checkChangesService.assignObject(this.service);
    this.stateService.go('services.details', {id: this.route.globals.params.id});
  }

  accountFreeEnabled() {
    return this._tokenService.currentUserData && this._tokenService.currentUserData['account_free_service_reservation'] === true;
  }

  externalEnabled() {
    return this._tokenService.currentUserData && this._tokenService.currentUserData['external_service_reservation'] === true;
  }

}
