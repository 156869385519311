import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { UtilitiesModule } from '../utilities/utilities.module';

import { HappeningsComponent } from './happenings.component';
import { RouterModule } from './happenings.routing';
import { HappeningsListComponent } from './happenings-list/happenings-list.component';
import { HappeningsDetailsComponent } from './happenings-details/happenings-details.component';
import { HappeningsFormComponent } from './happenings-form/happenings-form.component';
import { HappeningsInfoComponent } from './happenings-info/happenings-info.component';
import { HappeningsGalleryComponent } from './happenings-gallery/happenings-gallery.component';
import { HappeningsSubmissionsComponent } from './happenings-submissions/happenings-submissions.component';
import { HappeningsDatesComponent } from './happenings-dates/happenings-dates.component';
import { HappeningsDetailsEditComponent } from './happenings-details-edit/happenings-details-edit.component';
import { HappeningsReviewsComponent } from './happenings-reviews/happenings-reviews.component';
import { HappeningsCheckoutDataComponent } from './happenings-checkout-data/happenings-checkout-data.component';

@NgModule({
  declarations: [
    HappeningsComponent,
    HappeningsListComponent,
    HappeningsDetailsComponent,
    HappeningsFormComponent,
    HappeningsInfoComponent,
    HappeningsGalleryComponent,
    HappeningsSubmissionsComponent,
    HappeningsDatesComponent,
    HappeningsDetailsEditComponent,
    HappeningsReviewsComponent,
    HappeningsCheckoutDataComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    TranslateModule.forChild(),
    NgxDatatableModule,
    UtilitiesModule,
    RouterModule,
  ]
})
export class HappeningsModule { }
