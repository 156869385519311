import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceAvailabilityService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  getAvailability(serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/availability`;
    return this.http.get(url).pipe(
      map((res: any) => {
        let arr = [];
        for (let key in res.hours) {
          arr.push({
            name: key,
            open: res.hours[key].open,
            id: res.hours[key].id,
            range: res.hours[key].range,
            available: res.hours[key].available,
            prices: res.hours[key].prices,
            location: res.hours[key].location,
            intervals: res.hours[key].intervals,
            interval_start: res.hours[key].interval_start
          })
        }
        res.hours = arr;
        return res
      }),
      catchError(err => this.errorHandler.handle(err))
    )
  }

  updateAvailability(serviceId, availability) {
    // TODO: Change days definitions
    let avObject = {
      days: availability.days,
      interval: availability.interval,
      hours: {},
      monday: availability.monday,
      tuesday: availability.tuesday,
      wednesday: availability.wednesday,
      thursday: availability.thursday,
      friday: availability.friday,
      saturday: availability.saturday,
      sunday: availability.sunday,
    }

    let times_changed = []

    for (let i = 0; i < availability.hours.length; i++) {
      avObject.hours[availability.hours[i].name] = {};
      avObject.hours[availability.hours[i].name].open = availability.hours[i].open;
      avObject.hours[availability.hours[i].name].range = availability.hours[i].range;
      avObject.hours[availability.hours[i].name].available = availability.hours[i].available;
      avObject.hours[availability.hours[i].name].id = availability.hours[i].id;
      avObject.hours[availability.hours[i].name].prices = availability.hours[i].prices;
      avObject.hours[availability.hours[i].name].location = availability.hours[i].location;
      avObject.hours[availability.hours[i].name].intervals = availability.hours[i].intervals;
      avObject.hours[availability.hours[i].name].interval_start = availability.hours[i].interval_start;

      if (availability.hours[i].times_changed) {
        for (let j = 0; j < availability.hours[i].times_changed.length; j++) {
          times_changed.push(availability.hours[i].times_changed[j])
        }
      }
    }
    avObject["times_changed"] = times_changed;

    let url = `${environment.apiEndpoint}/services/${serviceId}/availability`;
    return this.http.put(url, avObject).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

}
