import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as Moment from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceChangesCheckService } from 'app/utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from 'app/utilities/service-changes-validation/service-changes-validation.service';
import { ServiceAvailabilityService } from 'app/api/service-availability.service';
import { ToastrService } from 'ngx-toastr';

import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-service-hours-modal',
  templateUrl: './service-hours-modal.component.html',
  styleUrls: ['./service-hours-modal.component.scss'],
  providers: [TranslatePipe]
})

export class ServiceHoursModalComponent implements OnInit {
  @Input() day: any;
  @Input() availability: any;
  @Input() service: any;

  @Output() isOpen: EventEmitter<any> = new EventEmitter<any>();

  bookings: any[];
  timesFrom: string[];
  timesTo: string[];
  times_changed;
  dayAvailable;
  isTimepickerVisible: boolean = false;

  constructor(
    private modalInstace: NgbActiveModal,
    private changesValidationSrvice: ServiceChangesValidationService,
    private changesValidationService: ServiceChangesValidationService,
    private changesService: ServiceChangesCheckService,
    private availabilityService: ServiceAvailabilityService,
    private translatePipe: TranslatePipe,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.availability.pickerInterval = 15;
    this.dayAvailable = this.day.available;
    this.createTimesFrom(this.availability.pickerInterval, '00:00', '24:00');
    this.createTimesTo(this.availability.pickerInterval, '00:00', '24:00');
  }

  createTimesFrom(interval, from, to) {
    this.timesFrom = [];
    let fromMoment = Moment(from, 'HH:mm');
    let toMoment = Moment(to, 'HH:mm');

    let t = toMoment.diff(fromMoment, 'm') / interval;
    while (t--) {
      this.timesFrom.push(fromMoment.format('HH:mm'));
      fromMoment.add(interval, 'minute');
    }
  }

  createTimesTo(interval, from, to) {
    this.timesTo = [];
    let fromMoment = Moment(from, 'HH:mm');
    let toMoment = Moment(to, 'HH:mm');

    let t = toMoment.diff(fromMoment, 'm') / interval;
    fromMoment.add(interval, 'minute');
    while (t--) {
      if (t < 1) {
        this.timesTo.push(fromMoment.format('kk:mm'));
      } else {
        this.timesTo.push(fromMoment.format('HH:mm'));
      }
      fromMoment.add(interval, 'minute');
    }
  }

  save(day, ava) {
    day.available = this.dayAvailable;
    day.times_changed = this.times_changed;
    day.intervals = 0;
    if (day.intervals === 0) {
      this.dayAvailable = false;
    }
    this.isOpen.emit('saved');
    ava.days = 0;
    this.changesValidationService.resolved = true;

    for (let i = 0; i < ava.hours.length; i++) {
      if (ava.hours[i].available && ava.hours[i].open.length > 0) {
        ava.days += 2 ** (6 - ava.hours[i].id);
      } else {
        ava[ava.hours[i].name] = [];
        ava.hours[i].available = false;
      }
    }

    this.availabilityService.updateAvailability(this.service.id, ava)
      .subscribe( res => {
        this.toastr.success(this.translatePipe.transform(_i18n('service.availability.toast-messages.success')), 'Success!');
        this.modalInstace.close();
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      });
  }

  close() {
    let validFrom = this.changesService.checkObject(this.reduceTimes(this.timesFrom));
    let validTo = this.changesService.checkObject(this.reduceTimes(this.timesTo));
    let isOpen:boolean = false;

    if (validFrom && validTo) {
      this.modalInstace.close();
      isOpen = false;
      this.isOpen.emit(isOpen);
    } else {
        if (!isOpen) {
          isOpen = true;
          this.isOpen.emit(isOpen);
          this.changesValidationSrvice.openEditModal()
            .then(() => {
              this.modalInstace.close();
              isOpen = false;
              this.isOpen.emit(isOpen);
            })
            .catch(() => {
              console.log('cancel');
              isOpen = false;
              this.isOpen.emit(isOpen);
            });
        }
    }
  }

  reduceTimes(times) {
    return times.reduce(function(acc, cur, i) {
      acc[cur.from] = cur.available;
      return acc;
    }, {});
  }

  setDayAvailability() {
    this.dayAvailable = !this.dayAvailable;
    if (this.dayAvailable && this.day.open.length === 0) {
      this.day.open.push({from: '09:00', to: '17:00'});
    }
  }

  fromChanged(from) {
    let fromMoment = Moment(from, 'HH:mm');

    if (!this.day.currentTo) {
      this.day.currentTo = fromMoment.add(15, 'minute').format('HH:mm');
      this.createTimesFrom(15, '00:00', this.day.currentTo);
      this.createTimesTo(15, this.day.currentFrom, '24:00');
    }
  }

  toChanged(to) {
    let toMoment = Moment(to, 'HH:mm');

    if (!this.day.currentFrom) {
      this.day.currentFrom = toMoment.subtract(15, 'minute').format('HH:mm');
      this.createTimesFrom(15, '00:00', this.day.currentTo);
      this.createTimesTo(15, this.day.currentFrom, '24:00');
    }
  }

  editPrice(day, from, to, invalid) {
    if (from === 'From' || to === 'To') {
      return;
    }

    if (!from || !to) {
      return;
    }

    if (!day.hours) {
      day.hours = [];
    }

    day.open.push({ from, to });

    if (day.open.length > 1) {
      day.open.sort((a: any, b: any) => {
        a = Object.assign({}, a);
        b = Object.assign({}, b);
        a.to = parseInt(a.to.replace(':', ''), 10);
        b.to = parseInt(b.to.replace(':', ''), 10);
        if (a.to > b.to) {
          return 1;
        }
        return -1;
      });
    }
    this.merge(day);
    this.isTimepickerVisible = false;
    this.day.currentFrom = null;
    this.day.currentTo = null;
    this.createTimesFrom(15, '00:00', '24:00');
    this.createTimesTo(15, '00:00', '24:00');
  }

  merge(day) {
    day.open.forEach((current: {from: string; to: string}, index: number) => {
      const currentStart = Moment(current.from, 'HH:mm');
      const currentEnd = Moment(current.to, 'HH:mm');
      if (day.open[index + 1]) {
        const nextStart = Moment(day.open[index + 1].from, 'HH:mm');
        const nextEnd = Moment(day.open[index + 1].to, 'HH:mm');

        if (nextStart.isSameOrBefore(currentStart) && (nextEnd.isSameOrAfter(currentStart) && nextEnd.isSameOrBefore(currentEnd))) {
          day.open[index] = { from: day.open[index + 1].from , to: current.to };
          day.open.splice(index + 1, 1);
          this.merge(day);
          return;
        }

        if  (nextStart.isSameOrBefore(currentStart) && nextEnd.isSameOrAfter(currentEnd)) {
          day.open[index] = { from: day.open[index + 1].from , to: day.open[index + 1].to };
          day.open.splice(index + 1, 1);
          this.merge(day);
          return;
        }

        if ((nextStart.isSameOrAfter(currentStart) && nextStart.isSameOrBefore(currentEnd)) && nextEnd.isSameOrAfter(currentEnd)) {
          day.open[index] = { from: current.from , to: day.open[index + 1].to };
          day.open.splice(index + 1, 1);
          this.merge(day);
          return;
        }

        if (nextStart.isSameOrAfter(currentStart) && nextEnd.isSameOrBefore(currentEnd)) {
          day.open[index] = { from: current.from , to: current.to };
          day.open.splice(index + 1, 1);
          this.merge(day);
          return;
        }
      }
    });
  }

  removeOpenHours(index: number, day: any) {
    this.day.open.splice(index, 1);
    this.merge(day);
  }

  showTimepicker() {
    this.isTimepickerVisible = true;
  }
}
