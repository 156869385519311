import { Component, OnInit } from '@angular/core';
import { BookingsService, Booking } from '../../api/bookings.service';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardReservationModalComponent } from './../../dashboard/dashboard/dashboard-reservation-modal/dashboard-reservation-modal.component';
import { TranslatePipe } from '@ngx-translate/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';

@Component({
  selector: 'app-bookings-list',
  templateUrl: './bookings-list.component.html',
  styleUrls: ['./bookings-list.component.scss'],
  providers: [TranslatePipe]
})
export class BookingsListComponent implements OnInit {

  bookings: Booking;
  pagination;
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  loading = false;
  q: any = {
    date_eq: ''
  };
  sort: any;
  dateCopy: any;
  date: any;
  onlineFilter = {};
  isToAccept = false;

  constructor(
    private bookingsService: BookingsService,
    private modalService: NgbModal,
    private translatePipe: TranslatePipe,
    private stateService: StateService,
  ) {
    this.page.pageNumber = 0;
  }

  ngOnInit() {
    if (this.stateService.current.data.acceptOnly) {
      this.isToAccept = true;
      this.onlineFilter = {
        status_eq: "waiting_for_approval"
      }
      this.q = this.onlineFilter;
    } else {
      this.q['status_not_in'] = ['canceled', 'waiting_for_approval', 'rejected'];
    }

    this.setPage({
      offset: 0
    });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    console.info("OUR Q IS ")
    console.info(this.q)
    this.bookingsService.getBookings(this.page.pageNumber + 1, this.q, this.sort).subscribe((pagedData: any) => {
      this.page.totalElements = pagedData.size;
      this.page.size = pagedData.per_page;
      this.bookings = pagedData.data;
      this.viewTable = true;
    });
  }

  onSort(event) {
    this.loading = true;
    const s = event.sorts[0];
    let prop = s.prop.replace(/\./g, '_');
    if (prop === 'user_email') {
      prop = 'users_email';
    } else if (prop === 'user_first_name') {
      prop = 'users_first_name';
    } else if (prop === 'user_last_name') {
      prop = 'users_last_name';
    } else if (prop === 'service_service_variant_name') {
      prop = 'service_variant_name';
    }
    this.sort = prop + ' ' + s.dir;
    this.bookingsService.getBookings(0, this.q, this.sort)
      .subscribe((res: any) => {
        this.bookings = res.data;
        this.loading = false;
      });
  }

  onFilter(event) {
    this.page.totalElements = event.size;
    this.page.size = event.per_page;
    this.bookings = event.data;
  }

  onQueryChange(event) {
    this.q = {...this.onlineFilter, ...event};
    // this.q = event;
  }

  openReservationModal() {
    const modalRef = this.modalService.open(DashboardReservationModalComponent);
    modalRef.componentInstance.success.subscribe(
      data => this.setPage({ offset: 0 })
    );
  }
  translateStatus(status) {
    return this.translatePipe.transform(_i18n(`service.status.${status}`));
  }

}
