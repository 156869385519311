import { Component, OnInit } from '@angular/core';
import { PacketsService } from './../../api/packets.service';
import { BookingsService } from './../../api/bookings.service';
import { StateService } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';

import { TranslatePipe } from '@ngx-translate/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';

@Component({
  selector: 'packet-details',
  templateUrl: './packet-details.component.html',
  styleUrls: ['./packet-details.component.scss'],
  providers: [TranslatePipe],
})
export class PacketDetailsComponent implements OnInit {
  packet = {
    id: null,
    name: '',
    number_of_bookings: null,
    user: null,
    bookings: []
  };

  constructor(
    private packetsService: PacketsService,
    private bookingsService: BookingsService,
    private stateService: StateService,
    private toastr: ToastrService,
    private translatePipe: TranslatePipe,
  ) { }

  ngOnInit() {
    this.getPacket();
  }

  getPacket() {
    this.packetsService.getPacket(this.packetId).subscribe(
      (res: any) => {
        const { id, name, number_of_bookings, user, bookings } = res;
        this.packet = { id, name, number_of_bookings, user, bookings };
      }
    );
  }

  removeFromPackage(booking) {
    if (confirm("Czy na pewno chcesz usunąć rezerwację z pakietu?")) {
      this.bookingsService.updateBooking(booking.service.id, {id: booking.id, service_booking_group_id: null})
      .subscribe(
        success => {
          this.getPacket();
          this.toastr.success('Rezerwacja usunięta z pakietu pomyślnie', 'Sukces!');
        },
        error => {
          this.toastr.error('Wystąpił błąd podczas usuwania rezerwacji', 'Błąd!');
        }
      );
    }
  }

  translateStatus(status) {
    return this.translatePipe.transform(_i18n(`service.status.${status}`));
  }

  get packetId() {
    return this.stateService.params['id'];
  }
}
