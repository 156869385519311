import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers, RequestMethod } from '@angular/http';
import { AngularTokenService } from 'angular-token';
import * as Moment from "moment";
import {catchError, map, startWith} from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { ErrorHandlerService } from './../utilities/error-handler.service';
import {from as observableFrom,  Observable, Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

export interface Facility {
  name: string,
  description: string,
  location: string,
  interval: number,
  city_id: number,
  openTime: string,
  closeTime: string,
  price: string,
  addresses_attributes: any,
  disciplineSelected: boolean,
  suggested_discipline: string,
  category: string,
  privacy: string,
}

@Injectable()
export class FacilitiesService {

  cities:Array<object>;
  disciplines:Array<object>;

  disciplinesStream$ = new Subject();
  citiesStream$ = new Subject();

  constructor(
    private errorHandler: ErrorHandlerService,
    private http: HttpClient
  ) { }

  getServices(page, query, sort) {
    let q = "";
    let s = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiEndpoint}/services?q[category_eq]=facility&page=${page}${q}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  deleteService(serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}`;
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getService(id) {
    let url = `${environment.apiEndpoint}/services/${id}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  removeServiceImage(serviceId, imageId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/pictures/${imageId}`
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  setServiceImageDefault(image, service) {
    let url = `${environment.apiEndpoint}/services/${service.id}/pictures/${image.id}/set_default.json`
    let data = {
      image: image
    }
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  addServiceImage(data, serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/pictures`;
    return this.http.post(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  newService(service, disciplines, weekdays, file, markerLat, markerLng) {

    let formData:FormData = new FormData();

    if (service.addresses_attributes.length > 0) {
      service.location = service.addresses_attributes[0].formatted_address;
      service.addresses_attributes = JSON.stringify(service.addresses_attributes);
    }

    if (service.addresses_attributes.length == 0) {
      delete service.addresses_attributes;
    }
    for (let key in service) {
      formData.append(key, service[key])
    }
    if (!service.addresses_attributes) {
      service.addresses_attributes = []
    }

    if (service.addresses_attributes.length > 0) {
      service.addresses_attributes = JSON.parse(service.addresses_attributes);
    }

    let hoursArray = []
    let startMoment = Moment(service.openTime, "HHmm");
    let endMoment = Moment(service.closeTime, "HHmm");

    let t = endMoment.diff(startMoment, 'm') / service.interval;

    while (t--) {
      hoursArray.push(startMoment.format('HHmm'))
      startMoment.add(service.interval, 'minute');
    }

    let availability_attributes = {
      days: 0,
      interval: service.interval,
      hours: {
        from: service.openTime,
        to: service.closeTime,
        price: service.price
      }
    };

    for (let i = 0; i < weekdays.length; i++) {
      if (weekdays[i].selected) {
        availability_attributes.days += weekdays[i].value;
        availability_attributes[weekdays[i].engName] = hoursArray;
      }
    }
    let discipline_ids = [];

    for (let i = 0; i < disciplines.length; i++) {
      if (disciplines[i].selected) {
        discipline_ids.push(disciplines[i].id)
      }
    }


    formData.append('availability_attributes', JSON.stringify(availability_attributes));
    if (service.addresses_attributes.length > 0) {
      formData.append('lat', service.addresses_attributes[0].lat);
      formData.append('lng', service.addresses_attributes[0].lng);
    }
    formData.append('discipline_ids', JSON.stringify(discipline_ids));
    formData.append('image', file, file.name);

    let url = `${environment.apiEndpoint}/services`;

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post(url, formData, { headers }).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  editService(service) {
    let url = `${environment.apiEndpoint}/services/${service.id}`;
    return this.http.put(url, service).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getDisciplines() {
    let url = `${environment.apiEndpoint}/disciplines`;

    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
    .subscribe((response: any) => {
      if (!this.disciplines) {
        this.disciplines = response;
        if (this.disciplines) {
          this.disciplines.push({id: 0, name: 'Inny'})
        }
        this.disciplinesStream$.next(this.disciplines);
      }
    })
  }

  getCities() {
    let url = `${environment.apiEndpoint}/cities`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
    .subscribe((response: any) => {
      if (!this.cities) {
        this.cities = response;
        this.citiesStream$.next(this.cities);
      }
    })
  }

  getDisciplinesStream() {
    if (!this.disciplines) {
        this.getDisciplines();
    }
    return observableFrom(this.disciplinesStream$).pipe(
      startWith(this.disciplines)
    );
  }

  getCitiesStream() {
    if (!this.cities) {
      this.getCities();
    }
    return observableFrom(this.citiesStream$).pipe(
      startWith(this.cities)
    );
  }
}
