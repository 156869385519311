import { Component, OnInit } from '@angular/core';
import { Calendar } from '../calendars';
import { CalendarService } from 'app/api/calendar.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CalendarIntegrationCode } from 'app/calendars/calendars';
import { UIRouter, StateService } from '@uirouter/angular';

@Component({
  selector: 'calendars-list',
  templateUrl: './calendars-list.component.html',
  styleUrls: ['./calendars-list.component.scss']
})
export class CalendarsListComponent implements OnInit {

  calendars: Calendar[] = [];
  selectedCalendars: Calendar[] = [];
  google_calendars_to_observe: string[];
  google_calendar_to_push: string;

  stage: 1 | 2 = 1;

  constructor(
    private calendarService: CalendarService,
    private toastr: ToastrService,
    private route: UIRouter,
    private stateService: StateService
  ) { }

  ngOnInit() {
    const code = this.route.globals.params.code;
    if (code) {
      const data: CalendarIntegrationCode = { code };
      this.integrateWithGoogleCalendar(data);
    }
  }

  integrateWithGoogleCalendar(data: CalendarIntegrationCode) {
    this.calendarService.integrateWithGoogleCalendar(data).subscribe(
      res => {
        this.calendars = res;
      }
    );
  }

  onSelect({ selected }) {
    switch (this.stage) {
      case 1:
        this.selectedCalendars.splice(0, this.selectedCalendars.length);
        this.selectedCalendars.push(...selected);
      break;

      case 2:
        if (selected.length > 0) {
          this.selectedCalendars = [selected[this.selectedCalendars.length - 1]];
        } else {
          this.selectedCalendars = [];
        }
      break;
    }
  }

  addCalendarsToObserve() {
    this.google_calendars_to_observe = this.selectedCalendars.map(calendar => calendar.id);
    this.selectedCalendars = [];
    this.stage = 2;
  }

  addCalendarToPush() {
    this.google_calendar_to_push = this.selectedCalendars.map(calendar => calendar.id)[0];
    this.selectedCalendars = [];
    this.stage = 1;

    const data = {
      google_calendars_to_observe: this.google_calendars_to_observe,
      google_calendar_to_push: this.google_calendar_to_push
    };

    this.calendarService.updateIntegrationWithGoogleCalnedar(data).subscribe(
      res => {
        this.toastr.success('Pomyślnie dodano kalendarze', 'Sukces!');
        this.stateService.go('dashboard');
      },
      err => console.log(err),
    );
  }
}
