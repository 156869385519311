import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'angular2-moment';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { routerModule } from "./transactions.routing";
import { UtilitiesModule } from './../utilities/utilities.module';
import { TransactionsService } from "../api/transactions.service";

import { TransactionsListComponent } from './transactions-list/transactions-list.component';
import { TransactionsDetailsComponent } from './transactions-details/transactions-details.component';
import { TransactionsComponent } from './transactions.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    MomentModule,
    NgbModule,
    NgxDatatableModule,
    TranslateModule.forChild(),
    UtilitiesModule
  ],
  providers: [
    TransactionsService
  ],
  declarations: [TransactionsListComponent, TransactionsDetailsComponent, TransactionsComponent]
})
export class TransactionsModule { }
