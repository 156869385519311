import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from "@ngx-translate/core";

import { HelpComponent } from './help.component';
import { routerModule } from "./help.routing";
import { GenerateCodeComponent } from './generate-code/generate-code.component';
import { CodesService } from './../api/codes.service';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    TranslateModule.forChild()
  ],
  providers: [
    CodesService
  ],
  declarations: [HelpComponent, GenerateCodeComponent, FaqComponent]
})
export class HelpModule { }
