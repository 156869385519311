import { Component, OnInit } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from "@ngx-translate/core";
import { UsersService } from './../../api/users.service';
import { SettingsService } from './../../api/settings.service';
import { _i18n } from './../../utilities/translation-marker/translation-marker';

@Component({
  selector: 'billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  providers: [TranslatePipe]
})
export class BillingComponent implements OnInit {

  data:any = {};
  savingInProgress:boolean = false;
  dirty:boolean = false;

  constructor(private userService: UsersService,
              private _tokenService: AngularTokenService,
              private toastr: ToastrService,
              private translatePipe: TranslatePipe,
              private settingsService: SettingsService) { }

  ngOnInit() {
    this.getProfileData();
  }

  eventHandler(event){
   if (event) {
     if (event < 48 || event > 57) {
       return false
     }
   }
  }

  save(formInvalid, data) {
    if (formInvalid) {
      return
    }
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;

    console.log(this.data)
    this.settingsService.saveProfile(this.data)
      .subscribe(
        res => {
          this._tokenService.validateToken();
          this.savingInProgress = false;
          this.toastr.success(this.translatePipe.transform(_i18n('settings.billing.toast-messages.success.save')))
          console.log(res)
          this.dirty = false;
        },
        err => {
          console.log(err);
          this.savingInProgress = false;
          this.toastr.success(this.translatePipe.transform(_i18n('settings.billing.toast-messages.error.save')))
          this.dirty = false;
        }
      )
  }

  getProfileData() {
    this.userService.getMyProfile()
      .subscribe(
        res => this.data = res,
        err => console.log(err)
      )
  }

  fieldChangeDirty() {
    this.dirty = true;
  }

}
