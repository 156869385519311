
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { StateService } from "@uirouter/angular";

@Injectable()
export class ErrorHandlerService {

  constructor(private stateService:StateService) { }

  handle(error: Response|any) {

    // this.stateService.go('404', {}, {location: 'replace'});

    return observableThrowError(error);  
  }

}
