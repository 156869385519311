import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../api/users.service'
import { SettingsService } from '../../api/settings.service'
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {

  user:any;

  constructor(private usersService: UsersService,
              private settingsService: SettingsService,
              private _tokenService: AngularTokenService) { }

  ngOnInit() {
    this.usersService.getMyProfile()
    .subscribe(res => {
      this.user = res;
    })
  }


}
