import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestOptions, RequestMethod } from '@angular/http';
import { environment } from "../../environments/environment";
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SettingsService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  saveProfile(data) {
    let url = `${environment.apiBase}/user`;
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.put(url, data, { headers }).pipe(
      catchError(err => this.errorHandler.handle(err))
    )

  }

  updateProfile(data) {
    let url = `${environment.apiEndpoint}/auth`;
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.put(url, data, { headers }).pipe(
      catchError(err => this.errorHandler.handle(err))
    )

  }
}
