import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../api/services.service';
import { UIRouter } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss'],
  providers: [TranslatePipe]
})
export class ServiceDetailsComponent implements OnInit {

  service;
  location;
  file: File;
  serviceView = true;
  kind;

  constructor(private servicesService:ServicesService,
              private route:UIRouter,
              private translatePipe: TranslatePipe,
              private toastr: ToastrService) { }

  ngOnInit() {
    let id = this.route.globals.params.id;
    this.servicesService.getService(id)
    .subscribe((service: any) => {
      this.service = service;
      this.kind = service.hosts[0].kind
      let locationObject = {
        name: service.location,
        lat: service.lat,
        lng: service.lng,
        serviceId: service.id
      };

      this.location = locationObject;
    }, err => {
      this.toastr.error(err.statusText, err.status);
    })
  }

  removeImage(image) {
    if (confirm(this.translatePipe.transform(_i18n('service.details.confirm.remove-image')))) {
      this.servicesService.removeServiceImage(this.service.id, image.id)
        .subscribe( response => {
          this.toastr.success(this.translatePipe.transform(_i18n('service.details.toast-messages.sucess.removed-image')), 'Success!');
          this.service.pictures.splice(this.service.pictures.indexOf(image), 1)
        }, err => {
          this.toastr.error(err.statusText, err.status);
        })
    }
  }

  setAsDefault(image) {
    this.toastr.info(this.translatePipe.transform(_i18n('service.details.toast-messages.info.image-setting-default')), null);
    this.servicesService.setServiceImageDefault(image, this.service)
      .subscribe( response => {
        this.toastr.success(this.translatePipe.transform(_i18n('service.details.toast-messages.sucess.default-image')), 'Success!');
        this.service = response;
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
  }

  imageAdded(event) {
    if (this.service.pictures.length >= 8) {

      this.toastr.error(this.translatePipe.transform(_i18n('service.details.toast-messages.error.too-many-images')), null);
    } else {
      this.toastr.info(this.translatePipe.transform(_i18n('service.details.toast-messages.info.sending-images')), null);
      let fileList: FileList = event.target.files;

      if (fileList.length > 0) {
        this.file = fileList[0];

        let formData:FormData = new FormData();
        formData.append('source', this.file, this.file.name);
        this.servicesService.addServiceImage(formData, this.service.id)
        .subscribe( res => {
          this.toastr.success(this.translatePipe.transform(_i18n('service.details.toast-messages.sucess.image-added')), 'Success!');
          this.service.pictures.push(res);
        }, err => {
          this.toastr.error(err.statusText, err.status);
        })
      }
    }
  }

  onLocationChange(adresses) {
    let data = {id: this.service.id, addresses_attributes: JSON.stringify(adresses) };
    this.servicesService.editService(data)
      .subscribe(
        (res: any) => {
          this.toastr.success(this.translatePipe.transform(_i18n('facilities.details.toast-messages.success.editedLocation')), 'Success!');
          this.service.addresses = res.addresses;
        },
        err => {
          this.toastr.error(err.statusText, err.status);
        }
      )
  }

}
