import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { Response, RequestOptions, Headers, RequestMethod } from '@angular/http';
import { Observable, Subject } from 'rxjs';
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface Packet {
  id?: number,
  name?: string,
  service_id: number,
  user_id: number,
  amount: number,
}

@Injectable()
export class PacketsService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private http: HttpClient
  ) { }

  getPackets(page, query, sort, all = false) {
    let q = "";
    let s = "";
    let p = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    if (all) {
      p = "&except[]=limit&except[]=offset";
    }
    let url = `${environment.apiEndpoint}/service_booking_groups?page=${page}${q}${p}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  deletePacket(packetId) {
    let url = `${environment.apiEndpoint}/service_booking_groups/${packetId}`;
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getPacket(packetId) {
    let url = `${environment.apiEndpoint}/service_booking_groups/${packetId}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  createPacket(data) {
    let url = `${environment.apiEndpoint}/service_booking_groups`;
    return this.http.post(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  updatePacket(id: number, data) {
    let url = `${environment.apiEndpoint}/service_booking_groups/${id}`;
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

}
