import { Component, Input, OnInit } from '@angular/core';
import { UIRouter } from "@uirouter/angular";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '@ngx-translate/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { ServicesService } from './../../api/services.service';

@Component({
  selector: 'service-verification-comments',
  templateUrl: './service-verification-comments.component.html',
  styleUrls: ['./service-verification-comments.component.scss'],
  providers: [TranslatePipe]
})
export class ServiceVerificationCommentsComponent implements OnInit {

  @Input()
  comments;

  id:Number;

  constructor(public activeModal: NgbActiveModal,
              private servicesService: ServicesService,
              private route: UIRouter,
              private toastr: ToastrService,
              private translatePipe: TranslatePipe) {
    this.id = this.route.globals.params.id;
  }

  ngOnInit() {
  }

    repeatVerifictaion() {
      let service = {
        id: this.id,
        verification_status:'for_reconsideration'
      }

      this.servicesService.editService(service)
        .subscribe(
          (res: any) => {
            this.toastr.success(this.translatePipe.transform(_i18n('service.verification-comment.toastr-messages.success.repeat-verification')),'Success');
            this.activeModal.close(res.verification_status);
          },
          err => {
            this.toastr.error(this.translatePipe.transform(_i18n('service.verification-comment.toastr-messages.error.repeat-verification')),'Error');
            console.log(err);
          }
        );
    }

}
