import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';
import { ServicesService } from 'app/api/services.service';
import { ClientsService } from 'app/api/clients.service';
import { NgbActiveModal, NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as Moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'booking-edit-reservation-modal',
  templateUrl: './booking-edit-reservation-modal.component.html',
  styleUrls: ['./booking-edit-reservation-modal.component.scss']
})
export class BookingEditReservationModalComponent implements OnInit {

  @Input()
  service: any;

  @Input()
  selectedServiceVariant: any = '0';

  @Input()
  client;

  @Input()
  bookingId: number;

  @Input()
  availablePackets;

  @Input()
  selectedPacket;

  @Input()
  inputedDay;

  @Input()
  inputedTime;

  @Input()
  bookingNote;

  @ViewChild('clientDropdown')
  clientDropdown: NgbDropdown;

  success: EventEmitter<any> = new EventEmitter<any>();

  loading = false;
  selectedService: any = '0';
  selectedServiceStart: any = '0';
  requestedServiceStart;
  selectedServiceEnd: any = '';
  selectedDay: NgbDateStruct;
  selectedTimes: Array<object> = [];
  times: Array<object> = [];
  pickerInterval = 15;
  dateDisabled: any;
  minDate: any;
  selectedPacketId;
  note = '';
  selectedClient: any = '';
  allAvailableClients = [];
  availableClients = [];
  data: any = {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    note: '',
  };

  constructor(
    public activeModal: NgbActiveModal,
    private servicesService: ServicesService,
    private toastr: ToastrService,
    private clientsService: ClientsService,
  ) { }

  ngOnInit() {
    this.setCalendarMinDate();
    this.setServiceAvailability();
    if (this.inputedDay && this.inputedTime) {
      const momentDay = Moment(this.inputedDay);
      this.selectedDay = {
        year: momentDay.year(),
        month: momentDay.month() + 1,
        day: momentDay.date()
      }
      this.requestedServiceStart = this.inputedTime;
      this.getTimeAvailabilities(this.selectedDay, this.pickerInterval);
    }

    this.selectedPacket ? this.selectedPacketId = this.selectedPacket.id : this.selectedPacketId = 0;
    this.clientsService.getClients(1, {}, null, true)
      .subscribe((res: any) => {
        this.allAvailableClients = [...res.data];
        this.availableClients = [...res.data].splice(0, this.allAvailableClients.length);
      })
  }

  fillClientData(client) {
    this.data.id = client.id;
    this.data.first_name = client.first_name;
    this.data.last_name = client.last_name;
    this.data.email = client.email;
    this.selectedClient = `${client.first_name} ${client.last_name}`;
    this.clientDropdown.close();
  }

  public closeDatePicker(e, d) {
    let datePickerClicked = false;
    for (let element of e.path) {
      if (element.localName == 'ngb-datepicker') {
        datePickerClicked = true;
      }
    }
    if (datePickerClicked == false) {
      d.close();
    }
  }

  public dayChange(event) {
    this.selectedServiceStart = '0';
    this.selectedServiceEnd = '';
    this.getTimeAvailabilities(event, this.pickerInterval);
  }

  public close() {
    this.activeModal.close();
  }

  public selectTime(time) {
    this.selectedServiceEnd = Moment(time.from, 'HH:mm').add(this.selectedServiceVariant.interval, 'minutes').format('HH:mm');
    this.selectedTimes = [];
    this.selectedTimes.push(time)
    this.times.forEach(time => {
      time['picked'] = false;
    })
    time.picked = true;

    if (!this.selectedDay) {
      let today = new Date();

      this.selectedDay = {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate()
      };
    }
  }

  public reserve(times) {
    this.loading = true;
    let reservation = {
      day: this.selectedDay,
      hours: []
    };

    for (let i = 0; i < times.length; i++) {
      if (times[i].picked) {
        times[i].to = Moment(times[i].from, 'HH:mm').add(this.selectedServiceVariant.interval, 'minutes').format('HH:mm');
        reservation.hours.push(times[i]);
      }
    }

    let person;
    if (!this.client) {
      person = this.data
    } else {
      person = this.client
    }

    let data = {
      reservation: reservation,
      service: this.service,
      person: person,
      service_variant_id: this.selectedServiceVariant.id,
      packetId: this.selectedPacketId ? this.selectedPacketId : null
    }
    data.person.note = this.bookingNote;

    this.servicesService.editReserveService(data, this.bookingId)
      .subscribe(
        (data: any) => {
          let bookingsIds = []
          for (let i = 0; i < data.length; i++) {
            bookingsIds.push(data[i].id);
          }
          this.toastr.success('Pomyślnie zaktualizowano rezerwacje', 'Sukces!');
          this.success.emit(data);
          this.close();
        },
        err => {
          this.loading = false;
        }
      );
  }

  private getTimeAvailabilities(date, interval) {
    date['excluded_booking_id'] = this.bookingId;
    return this.servicesService.getServiceAvailability(this.service.id, date).subscribe(
      (res: any) => this.calculateHours(res.opened, res.bookings, interval),
      errors => console.log(errors));
  }

  private calculateHours(openHours, bookings, interval) {
    this.times = [];

    for (let i = 0; i < openHours.length; i++) {
      let startDay = Moment(openHours[i].from, 'HHmm');
      let endDay = Moment(openHours[i].to, 'HHmm');
      let t = endDay.diff(startDay, 'm') / interval;

      while (t--) {
        let hourObject = {
          from: startDay.format('HH:mm'),
          to: '',
          available: bookings.indexOf(startDay.format('HHmm')) == -1
        };
        startDay.add(interval, 'minute');
        hourObject.to = startDay.format('HH:mm');
        this.times.push(hourObject);

        if (this.requestedServiceStart && this.requestedServiceStart == hourObject.from) {
          if (hourObject.available == true) {
            this.selectedServiceStart = hourObject;
            this.selectTime(this.selectedServiceStart)
          }

          this.requestedServiceStart = undefined;
        }
      }
    }
  }

  private setCalendarMinDate() {
    const today = new Date();
    this.minDate = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
  }

  private setServiceAvailability() {
    this.servicesService.getService(this.service.id)
      .subscribe(res => {
        this.service = res;

        this.dateDisabled = (date: NgbDateStruct) => {
          let disabled = false;
          const checkedDate = Moment({year: date.year, month: date.month - 1, day: date.day});

          if (this.service.availability.indexOf(checkedDate.subtract(1, 'day').day()) === -1) {
            return true;
          }

          for (let i = 0; i < this.service.unavailable.length; i++) {
            const fromDate = Moment(this.service.unavailable[i].from);
            const toDate = Moment(this.service.unavailable[i].to);

            if (checkedDate.add(1, 'day').isBetween(fromDate, toDate, 'day', '[]')) {
              disabled = true;
              break;
            }
          }
          return disabled;
        };
      });
  }

  onSearchClient(query: string) {
    if (query === '') {
      this.availableClients = [...this.allAvailableClients].splice(0, this.allAvailableClients.length);
      return
    }
    const searchRes = this.allAvailableClients.filter(client =>
      (client && client.first_name ? client.first_name.toLowerCase() : '').includes(query.toLowerCase()) ||
      (client && client.last_name ? client.last_name.toLowerCase() : '').includes(query.toLowerCase()) ||
      client.email.toLowerCase().includes(query.toLowerCase())
    );
    this.availableClients = searchRes;
  }
}
