import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { routerModule } from './financial-stats.routing';

import { FinancialStatsComponent } from './financial-stats.component';
import { FinancialStatsListComponent } from './pages';
import { DateFilterComponent } from './components'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const PAGES = [
  FinancialStatsComponent,
  FinancialStatsListComponent,
]

const COMPONENTS = [
  DateFilterComponent
]

@NgModule({
  declarations: [...PAGES, ...COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModule,
    NgxDatatableModule,
    TranslateModule.forChild(),
    NgbModule.forRoot(),
  ]
})
export class FinancialStatsModule { }
