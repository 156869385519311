import { Component, OnInit } from '@angular/core';
import { FacilitiesService } from "../../api/facilities.service";
import { UIRouter, StateService } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'facilities-list',
  templateUrl: './facilities-list.component.html',
  styleUrls: ['./facilities-list.component.scss'],
  providers: [TranslatePipe]
})
export class FacilitiesListComponent implements OnInit {

  services = [];
  pagination;
  query;
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  }
  loading = false;
  sort: any;
  q = {};

  constructor(
    private stateService:StateService,
    private route:UIRouter,
    private facilitiesService: FacilitiesService,
    private translatePipe: TranslatePipe,
    private toastr: ToastrService
  ) {
    this.page.pageNumber = 0;
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo){
   this.page.pageNumber = pageInfo.offset;
   this.facilitiesService.getServices(this.page.pageNumber+1, this.q, this.sort)
   .subscribe((pagedData: any) => {
      this.page.totalElements = pagedData.size;
      this.page.size = pagedData.per_page;
      this.services = pagedData.data;
      this.viewTable = true;
    });
 }

  onSort(event) {
    this.loading = true;
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.sort = prop + " " + s.dir;
    this.facilitiesService.getServices(0, this.q, this.sort)
    .subscribe((res: any) => {
      this.services = res.data;
      this.loading = false;
    });
  }

  onFilter(event) {
    this.page.totalElements = event.size;
    this.page.size = event.per_page;
    this.services = event.data;
  }

  onQueryChange(event) {
    this.q = event;
  }

  pageChange(page) {
    if (page != this.route.globals.params.pageNumber) {
      this.stateService.go('users.listPage', {pageNumber: page})
    }
  }

  remove(serviceId) {
    if (confirm(this.translatePipe.transform(_i18n('facilities.list.remove-confirm')))) {
      this.facilitiesService.deleteService(serviceId)
      .subscribe( response => {
        this.toastr.success(this.translatePipe.transform(_i18n('facilities.list.toast-messages.success')), 'Success!')
        this.setPage({ offset: 0 });
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
    }
  }

}
