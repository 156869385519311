import { Component, OnInit } from '@angular/core';
import { UIRouter, StateService } from '@uirouter/angular';
import { ClientsService } from '../../api/clients.service';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { IClientData, IClient } from '../client.interface';
import { QueryBuilderOptions } from 'ransack-query-builder';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
  providers: [TranslatePipe],
})
export class ClientListComponent implements OnInit {

  users = [];
  pagination;
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  loading = false;
  sort: any;
  q = {};
  csvExporting = false;

  constructor(
    private clientsService: ClientsService,
    private route: UIRouter,
    private stateService: StateService,
    private translatePipe: TranslatePipe,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.clientsService.getClients(this.page.pageNumber + 1, this.q, this.sort).subscribe((pagedData: IClientData) => {
      this.page.totalElements = pagedData.size;
      this.page.size = pagedData.per_page;
      this.users = pagedData.data;
      this.viewTable = true;
   });
 }

  onSort(event) {
    this.loading = true;
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.sort = prop + " " + s.dir;
    this.clientsService.getClients(0, this.q, this.sort)
    .subscribe((res: any) => {
      this.users = res.data;
      this.loading = false;
    });
  }

  onFilter(event) {
    this.page.totalElements = event.size;
    this.page.size = event.per_page;
    this.users = event.data;
  }

  onQueryChange(event) {
    this.q = event;
  }

  pageChange(page) {
    if (page != this.route.globals.params.pageNumber) {
      this.stateService.go('users.listPage', {pageNumber: page})
    }
  }

  downloadRodoForm(id: number, client: IClient) {
    if (client.rodo_downloading == true) return ;
    client.rodo_downloading = true;
    let name = `${client.first_name} ${client.last_name}`
    this.clientsService.downloadRodoStatement(id, name)
      .subscribe(res => {
          const file = new File([res.file], res.filename);
          FileSaver.saveAs(file);
          client.rodo_downloading = false;
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed file download.');
        });
  }

  exportToCsv() {
    if (this.csvExporting === true) { return };
    this.csvExporting = true;
    this.clientsService.exportToCsv()
      .subscribe(res => {
          const file = new File([res.file], res.filename);
          FileSaver.saveAs(file);
          this.csvExporting = false;
        }, error => {
          console.log('download error:', JSON.stringify(error));
          this.toastr.error('spróbuj ponownie później', 'Error!');
          this.csvExporting = false;
        }, () => {
          console.log('Completed file download.')
        });
  }

  onFilterChange(query: Object) {
    this.clientsService.getClients(0, query, 'desc', true).subscribe((res: any) => {
      this.page.totalElements = res.size;
      this.page.size = res.per_page;
      this.users = [...res.data];
    });
  }

}
