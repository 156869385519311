import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AngularTokenService } from 'angular-token';
import { StateService } from '@uirouter/angular';
import { _i18n } from '../../utilities/translation-marker/translation-marker';
import { NotificationsService } from '../../api/notifications.service';
import { UsersService } from './../../api/users.service';

@Component({
  selector: 'auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent implements OnInit {

  newPassword: any = false;
  data: any = {};
  error: boolean = false;
  submitted: boolean = false;
  emptyEmail: boolean = false;
  emptyPassword: boolean = false;
  invalidData: boolean = false;
  showResetForm: boolean = false;
  loading = false;
  completed: boolean;
  message: any;
  email;
  link: string;

  @Input()
  accountConfirmedMessage;

  constructor(
    private _tokenService: AngularTokenService,
    private stateService: StateService,
    private notificationsService: NotificationsService,
    private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.link = `${environment.webApp}/promocje/bonus-za-transakcje`;
    if (this.stateService.params.reset) {
      this.newPassword = this.stateService.params.reset;
    }

    if (this._tokenService.userSignedIn()) {
      this.stateService.go('dashboard');
    }
  }

  showReset() {
    this.completed = false;
    this.showResetForm?  this.showResetForm = false : this.showResetForm = true;
  }

  remindPassword(form, email) {
    if (this.loading) {
      return;
    }

    if (form.invalid) {
      return;
    }
    this.loading = true;
    this._tokenService.resetPassword({login: email})
      .subscribe(
        res => {
          this.email = email;
          this.completed = true;
          this.message = _i18n('auth.login.message');
          this.loading = false;
        },
        error => {
          this.error = JSON.parse(error._body);
          this.loading = false;
        }
      )
  }

  login(formRef, data) {
    this.submitted = true;
    this.invalidData = false;

    !formRef.value.login? this.emptyEmail = true : this.emptyEmail = false;
    !formRef.value.password? this.emptyPassword = true : this.emptyPassword = false;

    if (formRef.invalid) {
      this.error = true;
      return;
    }
    console.log(data);
    data.userType = 'Host';
    this._tokenService.signIn(data).subscribe(
      res => {
        this.error = false;
        this.notificationsService.getNavNotifications();
        this.notificationsService.setNotificationsChannel();
        this.stateService.go('dashboard');
        this.usersService.broadcastLogin();
      },
      error => {
        this.error = false;
        this.invalidData = true;
      }
    );
  }

}
