import { Component, AfterViewInit, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CalendarUtils, CalendarDayViewComponent } from 'angular-calendar';
import { DayViewSchedulerService, DayViewScheduler } from './day-view-scheduler.service';
import { EVENT_WIDTH } from './day-view-scheduler.constants';
import * as Moment from 'moment';


@Component({
  selector: 'dsn-day-view-scheduler',
  templateUrl: './day-view-scheduler.component.html',
  styleUrls: ['day-view-scheduler.component.scss'],
  providers: [
    {
      provide: CalendarUtils,
      useClass: DayViewSchedulerService
    }
  ],
})
export class DayViewSchedulerComponent extends CalendarDayViewComponent implements OnInit, AfterViewInit {
  view: DayViewScheduler;
  hourWrapperWidth: number;
  currentHour: string;

  @Input()
  services: any[];

  @ViewChild('dayViewContainer')
  wrappper: ElementRef;

  ngOnInit() {
    super.ngOnInit();
    this.currentHour = Moment().format('hh:00');
  }

  ngAfterViewInit(): void {
    this.hourWrapperWidth = this.services.length * EVENT_WIDTH;
    const currentTimeRow = this.wrappper.nativeElement.querySelector(`[data-hour="${this.currentHour}"]`)
    currentTimeRow.scrollIntoView({block: 'center'});
  }
}
