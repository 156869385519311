import { UIRouterModule } from "@uirouter/angular";
import { MessagesComponent } from './messages.component';
import { MessagesListComponent } from './messages-list/messages-list.component';
import { MessagesConversationComponent } from './messages-conversation/messages-conversation.component';

const routesConfig = {
  states: [
    {
      name:'messages',
      component:  MessagesComponent,
      redirectTo: 'messages.list',
      data: { requiresAuth: true }
    },
    {
      name: 'messages.list',
      component: MessagesListComponent,
      url: '/messages',
      data: { requiresAuth: true }
    },
    {
      name: 'messages.list.conversation',
      component: MessagesConversationComponent,
      url: '/:conversation_id',
      data: { requiresAuth: true }
    }
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)

