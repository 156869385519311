import { Component, OnInit } from '@angular/core';
import { ServicesService } from "../../api/services.service";
import { UIRouter, StateService } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { AngularTokenService } from 'angular-token';
import { FacilitiesService } from "../../api/facilities.service";

@Component({
  selector: 'services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
  providers: [TranslatePipe]
})
export class ServicesListComponent implements OnInit {

  services = [];
  pagination;
  query;
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  }
  loading = false;
  sort: any;
  q = {};
  profile: any;
  user_kind:any;

  constructor(private stateService:StateService,
              private route:UIRouter,
              private translatePipe: TranslatePipe,
              private servicesService: ServicesService,
              private toastr: ToastrService,
              private _tokenService: AngularTokenService,
              private facilitiesService: FacilitiesService) {
                this.page.pageNumber = 0;
              }

  ngOnInit() {
    this.setPage({ offset: 0 });
    this._tokenService.validateToken().subscribe(
      res => {
        this.user_kind = this._tokenService.currentUserData['user_kind'];
      }
    )
    this.facilitiesService.getServices(0, null, null)
    .subscribe((res: any) => {
      this.profile = res.data[0];
    })
  }

  setPage(pageInfo){
   this.page.pageNumber = pageInfo.offset;
   this.servicesService.getPagedServices(this.page.pageNumber+1, this.q, this.sort)
   .subscribe((pagedData: any) => {
      this.page.totalElements = pagedData.size;
      this.page.size = pagedData.per_page;
      this.services = pagedData.data;
      this.viewTable = true;
    });
 }

  onSort(event) {
    this.loading = true;
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.sort = prop + " " + s.dir;
    this.servicesService.getPagedServices(0, this.q, this.sort)
    .subscribe((res: any) => {
      this.services = res.data;
      this.loading = false;
    });
  }

  onFilter(event) {
    this.page.totalElements = event.size;
    this.page.size = event.per_page;
    this.services = event.data;
  }

  onQueryChange(event) {
    this.q = event;
  }

  pageChange(page, query, sort) {
    if (page != this.route.globals.params.pageNumber) {
      this.stateService.go('users.listPage', {pageNumber: page})
    }
  }

  remove(serviceId) {
    if (confirm(this.translatePipe.transform(_i18n('service.list.confirm.remove')))) {
      this.servicesService.deleteService(serviceId)
      .subscribe( response => {
        this.toastr.success(this.translatePipe.transform(_i18n('service.list.toast-messages.success.removed')), 'Success!')
        this.setPage({ offset: 0 });
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
    }
  }

  translateStatus(status) {
    return this.translatePipe.transform(_i18n(`service.status.${status}`))
  }

  get isOwner() {
    return this._tokenService.currentUserData['owner'];
  }
}
