import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';
import { IUser, IAddUserClient } from 'app/users/users.interface';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { IClient, IClientData } from 'app/clients/client.interface';
import { ClientsService } from 'app/api/clients.service';
import { ToastrService } from 'ngx-toastr';

import { UsersService } from 'app/api/users.service';

@Component({
  selector: 'user-add-client-modal',
  templateUrl: './user-add-client-modal.component.html',
  styleUrls: ['./user-add-client-modal.component.scss']
})
export class UserAddClientModalComponent implements OnInit {

  @Input()
  user: IUser;
  clients: IClient[];
  selectedClient: IClient | any = '';
  selectedClientId;
  loading: boolean = false;
  success: EventEmitter<any> = new EventEmitter<any>();

  allAvailableClients: IClient[] = [];
  availableClients: IClient[] = [];

  @ViewChild('clientDropdown')
  clientDropdown: NgbDropdown;

  constructor(
    public activeModal: NgbActiveModal,
    private clientsService: ClientsService,
    private toastr: ToastrService,
    private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.clientsService.getAllClients(this.user.id).subscribe(
      (response: IClientData) => {
        this.allAvailableClients = [...response.data];
        this.availableClients = [...response.data].splice(0, this.allAvailableClients.length);
      }
    );
  }

  close() {
    this.activeModal.close();
  }

  save() {
    this.loading = true;
    if (!this.selectedClientId || !this.user.id) {
      this.toastr.error('Wybierz podopiecznego', 'Error');
      this.loading = false;
      return;
    }
    const data: IAddUserClient = {
      user_relations_attributes: JSON.stringify([{
        user_id: this.user.id,
        partner_id: this.selectedClientId,
        relation_type: 'client'
      }])
    }
    this.usersService.addUserAttributes(this.user.id, data).subscribe(
      (response) => {
        this.success.emit(response);
        this.toastr.success('Dodano podopiecznego do pracownika', 'Sukces');
        this.loading = false;
        this.close();
      },
      (error) => {
        this.toastr.error('Wystąpił nieoczekiwany błąd', 'Error');
        this.loading = false;
      }
    )
  }

  onSearchClient(query: string) {
    if (query === '') {
      this.availableClients = [...this.allAvailableClients].splice(0, this.allAvailableClients.length);
      return
    }
    const searchRes = this.allAvailableClients.filter(client =>
      (client && client.first_name ? client.first_name.toLowerCase() : '').includes(query.toLowerCase()) ||
      (client && client.last_name ? client.last_name.toLowerCase() : '').includes(query.toLowerCase()) ||
      client.email.toLowerCase().includes(query.toLowerCase())
    );
    this.availableClients = searchRes;
  }

  fillClientData(client: IClient) {
    this.selectedClient = `${client.first_name} ${client.last_name}`;
    this.selectedClientId = client.id;
    this.clientDropdown.close();
  }
}
