import { Component, OnInit } from '@angular/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { PacketsService, Packet } from '../../api/packets.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'packets-list',
  templateUrl: './packets-list.component.html',
  styleUrls: ['./packets-list.component.scss']
})
export class PacketsListComponent implements OnInit {

  packets;
  pagination;
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  loading = false;
  q: any = {
    date_eq: ''
  };
  sort: any;

  constructor(
    private packetsService: PacketsService,
    private toastr: ToastrService
  ) {
    this.page.pageNumber = 0;
  }

  ngOnInit() {
    this.setPage({
      offset: 0
    });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.packetsService.getPackets(this.page.pageNumber + 1, this.q, this.sort).subscribe((pagedData: any) => {
      this.page.totalElements = pagedData.size;
      this.page.size = pagedData.per_page;
      this.packets = pagedData.data;
      this.viewTable = true;
    });
  }

  onSort(event) {
    this.loading = true;
    const s = event.sorts[0];
    let prop = s.prop.replace(/\./g, '_');
    if (prop === 'name') {
      prop = 'name';
    } else if (prop === 'number_of_bookings') {
      prop = 'number_of_bookings';
    } else if (prop === 'user') {
      prop = 'user_first_name';
    }
    this.sort = prop + ' ' + s.dir;
    this.packetsService.getPackets(0, this.q, this.sort)
      .subscribe((res: any) => {
        this.packets = res.data;
        this.loading = false;
      });
  }

  onQueryChange(event) {
    this.q = event;
  }

  remove(packetId) {
    if (confirm('Jesteś pewny?')) {
      this.packetsService.deletePacket(packetId)
        .subscribe(response => {
          this.toastr.success('Pakiet został usunięty', 'Success!');
          this.setPage({ offset: 0 });
        }, err => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
        })
    }
  }

  onFilterChange(query: Object) {
    this.q = query;
    this.setPage({offset: 0});
  }
}
