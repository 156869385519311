import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as Moment from 'moment';
import { ToastrService } from 'ngx-toastr';

export interface Times {
  from: number;
  to: number;
}

@Component({
  selector: 'day-view-scheduler-time-selector',
  templateUrl: './day-view-scheduler-time-selector.component.html',
  styleUrls: ['./day-view-scheduler-time-selector.component.scss']
})
export class DayViewSchedulerTimeSelectorComponent implements OnInit {

  times = Array.from(Array(24), (value, i) => i);
  selectedTime: Times = {
    from: 0,
    to: 23,
  }

  @Input()
  events: any[];

  @Output()
  timeChanged: EventEmitter<Times> = new EventEmitter<Times>();

  constructor(private toastr: ToastrService) { }

  ngOnInit() {
    const savedTimes = this.getSavedTimes();
    if (savedTimes) {
      this.selectTime(savedTimes);
    }
  }

  selectTime(time: Partial<Times>) {
    Object.keys(time).forEach((key) => {
      this.selectedTime[key] = time[key];
    });
    if (!this.eventDateInRange()) {
      this.eventsOutOfRangeAlert();
    }
    this.timeChanged.emit(this.selectedTime);
  }

  onSelectChange() {
    if (!this.eventDateInRange()) {
      this.eventsOutOfRangeAlert();
    }
    this.saveSelectedTimes();
    this.timeChanged.emit(this.selectedTime);
  }

  private eventDateInRange(): boolean {
    let mark: boolean = true;
    for (let event of this.events) {
      const evetFrom = Moment(event.time_from, 'hh:mm');
      const evetTo = Moment(event.time_to, 'hh:mm');
      if (evetFrom.isBefore(Moment(this.selectedTime.from, 'hh'))) {
        mark = false;
        break;
      }

      if (evetTo.isAfter(Moment(this.selectedTime.to, 'hh'))) {
        mark = false;
        break;
      }
    }
    return mark
  }

  private eventsOutOfRangeAlert(): void {
    this.toastr.info('Niektóre wydarzenia są poza wybranym zakresem czasowym');
  }

  private getSavedTimes(): Times | null {
    return JSON.parse(window.localStorage.getItem('calendar-times'));
  }

  private saveSelectedTimes() {
    window.localStorage.setItem('calendar-times', JSON.stringify(this.selectedTime));
  }
}
