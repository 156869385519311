import { Component, Input } from '@angular/core';
import { UIRouter } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '@ngx-translate/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { ServicesService } from './../../api/services.service';

@Component({
  selector: 'service-verification-comments',
  templateUrl: './service-verification-comments.component.html',
  styleUrls: ['./service-verification-comments.component.scss'],
  providers: [TranslatePipe]
})
export class ServiceVerificationCommentsComponent{

  @Input()
  comments;

  id:Number;
  verificationRequestSubmitted: boolean = false;

  constructor(
    private servicesService: ServicesService,
    private route: UIRouter,
    private toastr: ToastrService,
    private translatePipe: TranslatePipe,
  ) {
    this.id = this.route.globals.params.id;
  }

  repeatVerifictaion() {
    let service = {
      id: this.id,
      verification_status:'for_reconsideration'
    }

    this.servicesService.editService(service)
      .subscribe(
        (res: any) => {
          this.verificationRequestSubmitted = true;
          this.toastr.success(this.translatePipe.transform(_i18n('service.verification-comment.toastr-messages.success.repeat-verification')),'Success');
        },
        err => {
          this.toastr.error(this.translatePipe.transform(_i18n('service.verification-comment.toastr-messages.error.repeat-verification')),'Error');
        }
      );
  }

}
