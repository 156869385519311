import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routerModule } from './clients.routing';
import { UtilitiesModule } from '../utilities/utilities.module';
import { ClientsComponent } from './clients.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientComponent } from './client/client.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { ClientsService } from '../api/clients.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReservationsComponent } from './client/components/reservations/reservations.component';
import { DocsComponent } from './client/components/docs/docs.component';
import { NotesComponent } from './client/components/notes/notes.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModule,
    TranslateModule.forChild(),
    NgxDatatableModule,
    UtilitiesModule,
    NgbModule
  ],
  providers: [
    ClientsService
  ],
declarations: [ClientsComponent, ClientListComponent, ClientComponent, ClientFormComponent, ReservationsComponent, DocsComponent, NotesComponent]
})
export class ClientsModule { }
