import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";

import { routerModule } from "./settings.routing";
import { SettingsComponent } from './settings.component'
import { ProfileSafetyComponent } from './profile-safety/profile-safety.component';
import { EmailChangeComponent } from './email-change/email-change.component';
import { SettingsService } from '../api/settings.service';
import { BankAccountComponent } from './bank-account/bank-account.component';
import { ImageChangeComponent } from './image-change/image-change.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { BillingComponent } from './billing/billing.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TermsAgreementsComponent } from './terms-agreements/terms-agreements.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routerModule,
    TranslateModule.forChild(),
    NgbModule
  ],
  providers: [
    SettingsService
  ],
  declarations: [SettingsComponent, ProfileSafetyComponent, EmailChangeComponent, BankAccountComponent, ImageChangeComponent, PaymentMethodComponent, BillingComponent, ProfileDetailsComponent, ProfileEditComponent, TermsAgreementsComponent]
})
export class SettingsModule { }
