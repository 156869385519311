import { UIRouterModule } from "@uirouter/angular";
import { BookingsComponent } from "./bookings.component";
import { BookingsListComponent } from "./bookings-list/bookings-list.component";
import { BookingFormComponent } from "./booking-form/booking-form.component";
import { BookingDetailsComponent } from "./booking-details/booking-details.component";

const routesConfig = {
  states: [
    {
      name: 'bookings',
      redirectTo: 'bookings.list',
      component:  BookingsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'bookings.list',
      url: '/bookings',
      component:  BookingsListComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'bookings.toAccept',
      url: '/bookings-to-accept',
      component:  BookingsListComponent,
      data: { requiresAuth: true, acceptOnly: true }
    },
    {
      name: 'bookings.listPage',
      url: '/bookings/page/:pageNumber',
      component:  BookingsListComponent,
      data: { requiresAuth: true }
    },
    // {
    //   name: 'bookings.edit',
    //   url: '/service/:serviceId/bookings/:bookingId/edit',
    //   component:  BookingFormComponent,
    //   data: { requiresAuth: true }
    // },
    {
      name: 'bookings.details',
      url: '/service/:serviceId/bookings/:bookingId',
      component:  BookingDetailsComponent,
      data: { requiresAuth: true }
    },
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
