import { Component, OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'additional-income',
  templateUrl: './additional-income.component.html',
  styleUrls: ['./additional-income.component.scss']
})
export class AdditionalIncomeComponent implements OnInit {

  link: string;
  promoLink: string;

  constructor(private _tokenService: AngularTokenService) { }

  ngOnInit() {
    this.promoLink = `${environment.webApp}/promocje/bonus-za-transakcje`;
    if (this._tokenService.currentUserData) {
      let profiles = this._tokenService.currentUserData["profiles"];
      if (profiles.length > 0) {
        this.link = `${environment.webApp}/profile/${profiles[0]["id"]}?referral=${profiles[0]["referral_code"]}`;
      }
    }
  }

}
