import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarsListComponent } from './calendars-list/calendars-list.component';
import { CalendarsComponent } from './calendars.component';
import { routerModule } from './calendars.routing';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarService } from 'app/api/calendar.service';
import { CalendarsButtonComponent } from './calendars-button/calendars-button.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    NgxDatatableModule
  ],
  declarations: [CalendarsListComponent, CalendarsComponent, CalendarsButtonComponent],
  providers: [
    CalendarService
  ],
  exports: [CalendarsButtonComponent]
})
export class CalendarsModule { }
