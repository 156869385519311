import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { TransactionsService, Transaction } from "../../api/transactions.service";

@Component({
  selector: 'transactions-details',
  templateUrl: './transactions-details.component.html',
  styleUrls: ['./transactions-details.component.scss']
})
export class TransactionsDetailsComponent implements OnInit {

  transaction;

  constructor(private stateService: StateService,
              private transactionsService: TransactionsService) { }

  ngOnInit() {
    let id = this.stateService.params.id;
    this.transactionsService.getTransaction(id)
      .subscribe(res => {
        this.transaction = res;
        console.log(this.transaction.bookings);
        
      })
  }

}
