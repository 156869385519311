import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routerModule } from "./services.routing";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServicesService } from "../api/services.service";
import { ServiceAvailabilityService } from "../api/service-availability.service";
import { ServiceUnavailabilityService } from "../api/service-unavailability.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { OrderModule } from 'ngx-order-pipe';
import { UtilitiesModule } from "../utilities/utilities.module";
import { MomentModule } from 'angular2-moment';
import { TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


import { ServicesListComponent } from './services-list/services-list.component';
import { ServicesComponent } from './services.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ServiceFormComponent } from './service-form/service-form.component';
import { ServiceEditComponent } from './service-edit/service-edit.component';
import { ServiceAvailabilityComponent } from './service-availability/service-availability.component';
import { ServiceHoursModalComponent } from './service-hours-modal/service-hours-modal.component';
import { ServiceUnavailabilityComponent } from './service-unavailability/service-unavailability.component';
import { ServiceClosedModalComponent } from './service-closed-modal/service-closed-modal.component';
import { ServicePricesComponent } from './service-prices/service-prices.component';
import { ServicePricesEditComponent } from './service-prices-edit/service-prices-edit.component';
import { AgmCoreModule } from '@agm/core';
import { ServiceLocationsComponent } from './service-locations/service-locations.component';
import { ServiceLocationsEditComponent } from './service-locations-edit/service-locations-edit.component';
import { TextMaskModule } from 'angular2-text-mask';


import { ServiceVerificationCommentsComponent } from './service-verification-comments/service-verification-comments.component';
import { ServiceVariantsComponent } from './service-variants/service-variants.component';
import { ServiceVariantFormComponent } from './service-variant-form/service-variant-form.component';
import { ServiceCoursesAndExperienceComponent } from './service-courses-and-experience/service-courses-and-experience.component';
@NgModule({
  imports: [
    CommonModule,
    routerModule,
    NgbModule.forRoot(),
    FormsModule,
    UtilitiesModule,
    OrderModule,
    MomentModule,
    ReactiveFormsModule,
    AgmCoreModule,
    TranslateModule.forChild(),
    NgxDatatableModule,
    TextMaskModule
  ],
  entryComponents: [
    ServiceHoursModalComponent,
    ServiceClosedModalComponent,
    ServiceVariantFormComponent
  ],
  providers: [
    ServicesService,
    ServiceAvailabilityService,
    ServiceUnavailabilityService,
  ],
  declarations: [ServicesListComponent, ServicesComponent, ServiceDetailsComponent, ServiceFormComponent, ServiceEditComponent, ServiceAvailabilityComponent, ServiceHoursModalComponent, ServiceUnavailabilityComponent, ServiceClosedModalComponent, ServicePricesComponent, ServicePricesEditComponent, ServiceLocationsComponent, ServiceLocationsEditComponent, ServiceVerificationCommentsComponent, ServiceVariantsComponent, ServiceVariantFormComponent, ServiceCoursesAndExperienceComponent]
})
export class ServicesModule { }
