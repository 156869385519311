import { UIRouterModule } from "@uirouter/angular";
import { SettingsComponent } from "./settings.component";
import { ProfileSafetyComponent } from "./profile-safety/profile-safety.component";
import { EmailChangeComponent } from "./email-change/email-change.component";
import { PaymentMethodComponent } from "./payment-method/payment-method.component";
import { BankAccountComponent } from "./bank-account/bank-account.component";
import { ProfileDetailsComponent } from "./profile-details/profile-details.component";
import { ImageChangeComponent } from "./image-change/image-change.component";
import { BillingComponent } from './billing/billing.component';
import { ProfileEditComponent } from "./profile-edit/profile-edit.component";
import { TermsAgreementsComponent } from "./terms-agreements/terms-agreements.component";
const routesConfig = {
  states: [
    { 
      name: 'settings',
      redirectTo: 'settings.profile-details',
      component:  SettingsComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'settings.password',
      url: '/settings/profile-safety',
      component:  ProfileSafetyComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'settings.email',
      url: '/settings/email-change',
      component:  EmailChangeComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'settings.payment-methods',
      url: '/settings/payment-methods',
      component:  PaymentMethodComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'settings.bank-account',
      url: '/settings/bank-account',
      component:  BankAccountComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'settings.profile-details',
      url: '/settings/profile-details',
      component:  ProfileDetailsComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'settings.image-change',
      url: '/settings/image-change',
      component:  ImageChangeComponent,
      data: { requiresAuth: true }
    },
    {
      name:'settings.billing',
      url:'/settings/billing',
      component:BillingComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'settings.profile-edit',
      url: '/settings/profile-edit',
      component:  ProfileEditComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'settings.terms-agreements',
      url: '/settings/terms',
      component:  TermsAgreementsComponent,
      data: { requiresAuth: true }
    },
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
