import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { CalendarWeekViewComponent } from 'angular-calendar';
import * as Moment from 'moment';

@Component({
  selector: 'dsn-week-view-scheduler',
  templateUrl: './week-view-scheduler.component.html',
})
export class WeekViewSchedulerComponent extends CalendarWeekViewComponent implements OnInit, AfterViewInit {

  currentHour: string;

  @ViewChild('eventsContainer')
  wrappper: ElementRef;

  ngOnInit() {
    super.ngOnInit();
    this.currentHour = Moment().format('hh:00');
  }

  ngAfterViewInit() {
    const currentTimeRow = this.wrappper.nativeElement.querySelector(`[data-hour="${this.currentHour}"]`);
    currentTimeRow.scrollIntoView({block: 'center'});
  }
}
