import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { StateService } from "@uirouter/angular";
import { MessagesService } from './../../api/messages.service';

@Component({
  selector: 'messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss']
})
export class MessagesListComponent implements OnInit {

  loaded = false;
  conversations;
  messagesStreamSubscription;

  constructor(private messagesService: MessagesService,
              private stateService: StateService) { }

  ngOnInit() {
    this.getConversations();
    this.getMessagesStream();
  }

  ngOnDestroy() {
    this.messagesStreamSubscription.unsubscribe();
  }

  getConversations() {
    this.messagesService.getConversations()
      .subscribe(
        (res: any) => {
          this.loaded = true;
          this.conversations = res.data
        },
        err => console.log(err)
      )
  }

  setConversationRead(conversation) {
    conversation.unread = 0;
  }

  getMessagesStream() {
    this.messagesStreamSubscription = this.messagesService.getMessagesStream()
      .subscribe( (res: any) =>{
        if (res.type == "message") {
          for (let data of res.data) {
            if (this.stateService.params.conversation_id != data.message.conversation_id) {
              for (let conversation of this.conversations) {
                if (conversation.id == data.message.conversation_id) {
                  conversation.unread++;
                  let i = this.conversations.indexOf(conversation)
                  let c = this.conversations.splice(i, 1)
                  this.conversations.splice(0, 0, c[0])
                }
              }
            }
          }
        }
      })
  }
}
