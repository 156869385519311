import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from '../../api/services.service';

@Component({
  selector: 'service-variant-form',
  templateUrl: './service-variant-form.component.html',
  styleUrls: ['./service-variant-form.component.scss']
})
export class ServiceVariantFormComponent implements OnInit {

  @Input() service;
  @Input() variant;

  intervals = [480, 420, 360, 300, 240, 180, 120, 90, 75, 60, 45, 30, 15];
  submitted = false;
  saving = false;

  constructor(public activeModal: NgbActiveModal, private servicesService:ServicesService,) { }

  ngOnInit() {
    // let id = this.route.globals.params.id;
    // get variant
    // this.servicesService.getService(id)
    // .subscribe(service => {
    //   console.log(service)
    //   this.service = service;
    //   this.kind = service.hosts[0].kind
    //   let locationObject = {
    //     name: service.location,
    //     lat: service.lat,
    //     lng: service.lng,
    //     serviceId: service.id
    //   };
    //
    //   this.location = locationObject;
    // }, err => {
    //   console.error(err);
    //   this.toastr.error(err.statusText, err.status);
    // })
  }

  priceInputChanged() {
    this.variant.price = parseInt(this.variant.price) < 1 ? this.variant.price = String(1) : this.variant.price
  }

  save() {

    if (!this.variant.name || !this.variant.price || (this.service.category == 'bookable' && !this.variant.price)) {
      this.submitted = true;
      return;
    }

    if (!this.saving) { this.saving = true }

    // this.activeModal.close(reason);

    // let service_variant = Object.assign({ id: variant.id, _destroy: "1" })
    let service_variants_attributes = JSON.stringify([this.variant])
    let data = {id: this.service.id, service_variants_attributes: service_variants_attributes}
    console.warn("THE DATA")
    console.warn(data)
    this.servicesService.editService(data).subscribe(
      (res: any) => {
        console.info("+++")
        console.info(res)

        this.activeModal.close(res.service_variants);
      },
      err => {
        console.error("!!!")
        console.error(err)
      }
    )
  }

  cancel() {
    this.activeModal.close();
  }

}
