import { UIRouterModule } from "@uirouter/angular";
import { NotificationsComponent } from './notifications.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
// import  requireAuthentication  from '../resolvers/signed-in.resolver';

const routesConfig = {
  states: [
    { name: 'notifications', redirectTo: 'notifications.list', component:  NotificationsComponent},
    { name: 'notifications.list', url: '/notifications', component:  NotificationsListComponent},
    { name: 'notifications.listPage', url: '/notifications/page/:pageNumber', component:  NotificationsListComponent}
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
