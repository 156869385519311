import { TransitionService } from "@uirouter/angular";
import { Subject ,  Observable } from 'rxjs';

let $currentStateSubject = new Subject<any>();

export function currentStateHook(transitionService: TransitionService) {

  const criteria = {
    to: (state) => true
  };

  const getState = (transition) => {
    const currentState = transition.to();
    $currentStateSubject.next(currentState);
  };

  transitionService.onEnter(criteria, getState, {priority: 10});
}

export function getCurrentState() {
  return $currentStateSubject.asObservable();
}