import { from as observableFrom, Observable, Subject, of } from 'rxjs';
import { startWith, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import * as Moment from "moment";
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { ExperienceAttributes } from '../services/service-courses-and-experience/service-courses-and-experience.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PageableReviewInterface } from './../services/models/review.interface';
import { AngularTokenService } from 'angular-token';

export interface NewService {
  name: string,
  description: string,
  location: string,
  interval?: number,
  city_id: number,
  openTime?: string,
  closeTime?: string,
  price: string,
  addresses_attributes?: any,
  disciplineSelected: boolean,
  suggested_discipline: string,
  primary_service_id: string,
  category: string,
  cancellation_before_time: string,
  privacy: string,
  // ONLINE
  connectivity_type: string,
  referenced_service_id: any,
}

export interface Ransack {
  [prop: string]: string;
}

@Injectable()
export class ServicesService {

  reservation;

  cities: Array<object>;
  disciplines: Array<object>;

  disciplinesStream$ = new Subject();
  citiesStream$ = new Subject();

  constructor(
    private http: HttpClient,
    private _tokenService: AngularTokenService,
    private errorHandler: ErrorHandlerService,
  ) { }

  getServices(page) {
    let url = `${environment.apiEndpoint}/services?q[category_in][]=bookable&q[category_in][]=unitable&page=${page}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getOfflineServices() {
    let url = `${environment.apiEndpoint}/services?q[connectivity_type_eq]=offline&q[category_eq]=bookable&except[]=limit&except[]=offset`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getAllServices(userId: number) {
    let url = `${environment.apiEndpoint}/services?q[category_in][]=bookable&q[category_in][]=unitable&except[]=limit&except[]=offset&user_id_not_eq=${userId}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getAllTimedServices() {
    let url = `${environment.apiEndpoint}/services?q[category_eq]=bookable&[category_not_eq]=facility&except[]=limit&except[]=offset&q[verification_status_eq]=verified`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getAllVerifiedServices() {
    let url = `${environment.apiEndpoint}/services?q[category_in][]=bookable&q[category_in][]=unitable&except[]=limit&except[]=offset&q[verification_status_eq]=verified`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getPagedServices(page, query, sort) {
    let q = "";
    let s = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiEndpoint}/services?q[category_in][]=bookable&q[category_in][]=unitable&page=${page}${q}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getVerifiedServices(page, query) {
    let q = '';
    for (let key in query) {
      if (query[key]) {
        q += `&q[${key}]=${query[key]}`
      }
    }
    let url = `${environment.apiEndpoint}/services?q[category_in][]=bookable&q[category_in][]=unitable&&page=${page}${q}`;
    return this.http.get(url);
  }

  deleteService(serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}`;
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getService(id) {
    let url = `${environment.apiEndpoint}/services/${id}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  removeServiceImage(serviceId, imageId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/pictures/${imageId}`
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  setServiceImageDefault(image, service) {
    let url = `${environment.apiEndpoint}/services/${service.id}/pictures/${image.id}/set_default.json`
    let data = {
      image: image
    }
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  addServiceImage(data, serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/pictures`;
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, data, { headers }).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  newService(service, disciplines, weekdays, file, markerLat, markerLng) {

    let formData: FormData = new FormData();

    if (service.addresses_attributes.length > 0) {
      service.location = service.addresses_attributes[0].formatted_address;
      service.addresses_attributes = JSON.stringify(service.addresses_attributes);
    }

    if (service.category == "unitable") {
      service.interval = 60;
      service.openTime = "00:00"
      service.closeTime = "24:00"
    }

    if (service.variants.length == 1) {
      service.variants[0].name = service.name;
    }

    // set service interval and price
    if (service.variants.length == 1) {
      service.interval = service.variants[0].interval;
      service.price = service.variants[0].price;
    } else {
      // find lowest price or shortest interval ???
      let interval = service.variants[0].interval;
      let price = service.variants[0].price;
      for (let variant of service.variants) {
        if (variant.price < price) {
          interval = variant.interval;
          price = variant.price;
        }
      }
      // set service data
      service.interval = interval
      service.price = price
    }

    formData.append('service_variants_attributes', JSON.stringify(service.variants))

    for (let key in service) {
      formData.append(key, service[key]);
    }
    if (service.addresses_attributes.length > 0) {
      service.addresses_attributes = JSON.parse(service.addresses_attributes);
    }


    let hoursArray = []
    let startMoment = Moment(service.openTime, "HHmm");
    let endMoment = Moment(service.closeTime, "HHmm");

    let t = endMoment.diff(startMoment, 'm') / 15;

    while (t--) {
      hoursArray.push(startMoment.format('HHmm'))
      startMoment.add(15, 'minute');
    }

    let availability_attributes = {
      days: 0,
      interval: service.interval,
      hours: {
        from: service.openTime,
        to: service.closeTime,
        price: service.price,
        location: service.addresses_attributes[0]
      }
    };

    for (let i = 0; i < weekdays.length; i++) {
      if (weekdays[i].selected) {
        availability_attributes.days += weekdays[i].value;
        availability_attributes[weekdays[i].engName] = hoursArray;
      }
    }
    formData.append('availability_attributes', JSON.stringify(availability_attributes));

    let discipline_ids = [];

    for (let i = 0; i < disciplines.length; i++) {
      if (disciplines[i].selected) {
        discipline_ids.push(disciplines[i].id)
      }
    }

    if (service.addresses_attributes.length > 0) {
      formData.append('lat', service.addresses_attributes[0].lat);
      formData.append('lng', service.addresses_attributes[0].lng);
    }
    formData.append('discipline_ids', JSON.stringify(discipline_ids));
    formData.append('image', file, file.name);

    let url = `${environment.apiEndpoint}/services`;
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, formData, { headers }).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  editService(service: any) {
    let url = `${environment.apiEndpoint}/services/${service.id}`;
    return this.http.put(url, service).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getDisciplines() {
    let url = `${environment.apiEndpoint}/disciplines`;

    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
      .subscribe((response: any) => {
        if (!this.disciplines) {
          this.disciplines = response;
          if (this.disciplines) {
            this.disciplines.push({ id: 0, name: 'Inny' })
          }
          this.disciplinesStream$.next(this.disciplines);
        }
      })
  }

  getCities() {
    let url = `${environment.apiEndpoint}/cities`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
      .subscribe((response: any) => {
        if (!this.cities) {
          this.cities = response;
          this.citiesStream$.next(this.cities);
        }
      })
  }

  getDisciplinesStream() {
    if (!this.disciplines) {
      this.getDisciplines();
    }
    return observableFrom(this.disciplinesStream$).pipe(
      startWith(this.disciplines)
    )
  }

  getCitiesStream() {
    if (!this.cities) {
      this.getCities();
    }
    return observableFrom(this.citiesStream$).pipe(
      startWith(this.cities)
    )
  }

  getServiceAvailability(serviceId, date) {
    let url = `${environment.apiBase}/services/${serviceId}/availability`;

    return this.http.get(url, { params: date }).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  reserveService(reservation) {
    this.reservation = reservation;
    let person = reservation.person;
    let date = [reservation.reservation.day.year, reservation.reservation.day.month, reservation.reservation.day.day].join("-")
    let bookings = [];
    let hours = reservation.reservation.hours;
    let packetId = reservation.packetId;
    for (let i in hours) {
      let time_from = (hours[i].from < 10) ? '0' + hours[i].from + ':00' : hours[i].from + ':00';
      let time_to = (hours[i].to < 10) ? '0' + hours[i].to + ':00' : hours[i].to + ':00';
      bookings.push({
        'date': date,
        'time_from': time_from,
        'time_to': time_to,
        'service_id': reservation.service.id,
        'service_variant_id': reservation.service_variant_id,
        'first_name': person.first_name,
        'last_name': person.last_name,
        'email': person.email,
        'phone': person.phone,
        'note': person.note,
        'service_booking_group_id': packetId
      })
    }
    let data = { 'booking': bookings };

    let url = `${environment.apiEndpoint}/services/${reservation.service.id}/bookings`;
    return this.http.post(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  editReserveService(reservation, bookingId) {
    this.reservation = reservation;
    let person = reservation.person;
    let date = [reservation.reservation.day.year, reservation.reservation.day.month, reservation.reservation.day.day].join("-")
    let bookings = [];
    let hours = reservation.reservation.hours;
    let packetId = reservation.packetId;
    for (let i in hours) {
      let time_from = (hours[i].from < 10) ? "0" + hours[i].from + ":00" : hours[i].from + ":00";
      let time_to = (hours[i].to < 10) ? "0" + hours[i].to + ":00" : hours[i].to + ":00";
      bookings.push({
        "date": date,
        "time_from": time_from,
        "time_to": time_to,
        "service_id": reservation.service.id,
        "service_variant_id": reservation.service_variant_id,
        "client_id": person.id,
        "first_name": person.first_name,
        "last_name": person.last_name,
        "email": person.email,
        "phone": person.phone,
        "note": person.note,
        'service_booking_group_id': packetId
      })
    }

    let data = { "booking": bookings[0] };

    let url = `${environment.apiEndpoint}/services/${reservation.service.id}/bookings/${bookingId}`;
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getReservation(reservation, query: Ransack, queryString: String) {
    let q = '';
    for (let key in query) {
      if (query[key]) {
        q += `&q[${key}]=${query[key]}`
      }
    }

    let url = `${environment.apiEndpoint}/services/${reservation.service.id}/bookings?${q}${queryString}`;
    return this.http.get(url);
  }

  public updateExperience(data: ExperienceAttributes, serviceId: number) {
    const url = `${environment.apiEndpoint}/services/${serviceId}`;
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  public getServiceReview(serviceId: string | number, page: number): Observable<PageableReviewInterface> {
    let url = `${environment.apiBase}/services/${serviceId}/reviews?page=${page}&show_all=true`;
    return this.http.get<PageableReviewInterface>(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  respondToServiceReview(reviewableId, id, data) {
    let url = `${environment.apiBase}/services/${reviewableId}/reviews/${id}/respond`
    return this.http.put(url, data)
  }
}
