import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UsersService } from "../../api/users.service";
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  providers: [TranslatePipe]
})
export class UserFormComponent implements OnInit, OnDestroy {

  @ViewChild('formRef') formRef: NgForm;

  constructor(private usersService:UsersService,
              private route:UIRouter,
              private stateService:StateService,
              private toastr: ToastrService,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private translatePipe: TranslatePipe,
              private transitionService: TransitionService) {
                this.transitionHook = transitionService.onBefore({from: 'users.**'}, (trans: Transition)=>{
                    return changesValidationService.validate(this.user)
                })
              }

  file: File;
  user = {
    id: "",
    description: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    avatar: "",
    type: ""
  };
  transitionHook: any;
  savingInProgress:boolean = false;
  imageChanged: boolean;

  ngOnInit() {
    if (this.route.globals.params.id) {
      let id = this.route.globals.params.id;
      this.usersService.getUser(id)
      .subscribe((user: any) => {
        this.user = user;
        this.checkChangesService.assignObject(this.user);
        console.log(this.user)
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
    } else {
      this.checkChangesService.assignObject(this.user);
    }
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  eventHandler(event){
    if (event) {
      if (event < 48 || event > 57) {
        return false
      }
    }
  }

  save(form, user) {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;

    this.changesValidationService.resolved = true;

    if (form.invalid && !this.imageChanged) {
      this.toastr.warning(this.translatePipe.transform(_i18n('users.form.toast-messages.warning.invalid-form')), null);
      this.savingInProgress = false;
      for (let control of Object.keys(this.formRef.controls)) {
        this.formRef.controls[control].markAsTouched();
      }
      return
    }

    let formData:FormData = new FormData();

    for (let key in user) {
      formData.append(key, user[key])
    }

    if (this.file) {
      formData.append('avatar', this.file, this.file.name);
      this.toastr.info(this.translatePipe.transform(_i18n('users.form.toast-messages.info.uploading-data')), null);
    }

    if (this.route.globals.params.id) {
      this.usersService.editUser(formData, user.id)
        .subscribe( (user) => {
          this.toastr.success(this.translatePipe.transform(_i18n('users.form.toast-messages.success.user-edited')), null);
          this.file = null;
          this.savingInProgress = false;
          this.imageChanged = false;
        }, (err) => {
          console.error(err)
          this.toastr.error(err.statusText, err.status);
          this.savingInProgress = false;
          for (let control of Object.keys(this.formRef.controls)) {
            this.formRef.controls[control].markAsTouched();
          }
        })
    } else {
      this.usersService.newUser(formData)
        .subscribe( (user: any) => {
          this.toastr.success(this.translatePipe.transform(_i18n('users.form.toast-messages.success.user-creted')), null)
          this.stateService.go('users.details', {id: user.id});
          this.savingInProgress = false;
          this.imageChanged = false;
        }, (err) => {
          console.error(err);
           if (err._body == '{"email":["has already been taken"]}') {
             this.toastr.error(this.translatePipe.transform(_i18n('users.form.toast-messages.error.email-taken')), null);
           } else if (err._body == '{"email":["is invalid","is not an email"]}' || err._body == '{"email":["is not an email"]}') {
              this.toastr.error(this.translatePipe.transform(_i18n('users.form.toast-messages.error.invalid-email')) , null);
           }
           this.savingInProgress = false;
           for (let control of Object.keys(this.formRef.controls)) {
            this.formRef.controls[control].markAsTouched();
          }
        })
    }

  }

  cancel() {
    if (this.route.globals.params.id) {
      this.stateService.go('users.details', {id: this.route.globals.params.id})
    } else {
      this.stateService.go('users.list')
    }
  }

  fileChange(event, user) {
    this.imageChanged = true;
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      this.file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        user.avatar = reader.result;
      }
    }
  }

}
