import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'gallery-card',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss'],
  providers: [TranslatePipe]
})
export class GalleryCardComponent implements OnInit {

  @Input() header: boolean = true;
  @Input() images;
  @Input() title: string = 'Galeria';
  @Input() tooltip: boolean = true;
  @Input() tooltipContent: string = 'Stwórz własną galerię zdjęć, pamiętaj, aby wybierać zdjęcia, które prezentują Ciebie lub Twój obiekt z najlepszej strony';
  @Input() serviceView;

  @Output() imageRemoved = new EventEmitter();
  @Output() imageAsDefault = new EventEmitter();
  @Output() newImage = new EventEmitter();

  constructor(
    private toastr: ToastrService,
    private translatePipe: TranslatePipe,
  ) { }

  ngOnInit() {
  }

  setAsDefault(image) {
    this.imageAsDefault.emit(image);
  }

  removeImage(image) {
    this.imageRemoved.emit(image);
  }

  imageAdded(event) {
    const size = event.target.files[0].size / 1024;

    if (size > 3000) {
      this.toastr.error(this.translatePipe.transform('utilies.gallery-card.toast-messages.too-big-size'));
    } else {
      this.newImage.emit(event);
    }
  }

}
