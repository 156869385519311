import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../api/users.service';
import { SettingsService } from '../../api/settings.service'
import { AngularTokenService } from 'angular-token';
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
  providers: [TranslatePipe]
})
export class PaymentMethodComponent implements OnInit, OnDestroy {

  PaymentMethod: any;
  error:boolean = false;
  submitted:boolean = false;
  data = {
    payment_option_ids: []
  }
  savingInProgress:boolean = false;
  transitionHook: any;
  methodsObject: any;
  profile: any;

  methods = [
    {id: 1, name: 'Płatność na miejscu', selected: false, disabled: false},
    {id: 3, name: 'Dotpay', selected: false, disabled: false}
  ]

  constructor(private usersService: UsersService,
              private settingsService: SettingsService,
              private _tokenService: AngularTokenService,
              private toastr: ToastrService,
              private translatePipe: TranslatePipe,
              private modalService: NgbModal,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService) {
                this.transitionHook = transitionService.onBefore({from: 'settings.**'}, (trans: Transition)=>{
                  this.methodsObject = this.reducePaymentMethods();
                  return changesValidationService.validate(this.methodsObject)
                })

                this.usersService.getMyProfile()
                  .subscribe((res: any) => {
                    this.profile = res;
                    for (let i = 0; i < res.payment_options.length; i++) {
                      for (let j = 0; j < this.methods.length; j++) {
                          if (res.payment_options[i].id == this.methods[j].id) {
                            this.methods[j]["selected"] = true;
                          }
                      }
                    }

                    this.methodsObject = this.reducePaymentMethods();
                    this.checkChangesService.assignObject(this.methodsObject);
                    this.changesValidationService.resetResolved();
                  })
    }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  save(invalid, data) {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;
    this.submitted = true;
    this.error = false;

    let payment_option_ids = []
    for (let method in this.methods) {
      if (this.methods[method]["selected"]) {
        payment_option_ids.push(this.methods[method].id);
      }
    }
    data.payment_option_ids = payment_option_ids;

    if (data.payment_option_ids.length == 0) {
      this.error = true;
      this.toastr.error(this.translatePipe.transform(_i18n('settings.payment-method.toast-messages.error.no-selected-method')), 'Błąd!');
      this.savingInProgress = false;
      return;
    }
    this.changesValidationService.resolved = true;

    this.settingsService.saveProfile(data)
      .subscribe((res: any) => {
        this.submitted = false;
        this._tokenService.validateToken();
        this.toastr.success(this.translatePipe.transform(_i18n('settings.payment-method.toast-messages.success.method-change')), 'Powodzenie!');
        this.savingInProgress = false;
      }, err => {
        console.info(err)
        this.toastr.error(this.translatePipe.transform(_i18n('settings.payment-method.toast-messages.error.method-change')), err.status);
        this.savingInProgress = false;
      })
  }

  reducePaymentMethods() {
    return this.methodsObject = this.methods.reduce(function(acc, cur, i) {
      acc[cur.name] = cur.selected;
      return acc;
    }, {});
  }
}
