import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularTokenModule, AngularTokenService } from 'angular-token';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { SettingsModule } from "./settings/settings.module";
import { ContactModule } from "./contact/contact.module";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Location } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2CableModule } from 'ng2-cable';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { NotificationsService } from './api/notifications.service';

import { HappeningsModule } from './happenings/happenings.module';
import { FacilitiesModule } from "./facilities/facilities.module";
import { ServicesModule } from "./services/services.module";
import { UtilitiesModule } from "./utilities/utilities.module";
import { AuthModule } from "./auth/auth.module";
import { UsersModule } from "./users/users.module";
import { ClientsModule } from "./clients/clients.module";
import { TransactionsModule } from "./transactions/transactions.module";
import { BookingsModule } from "./bookings/bookings.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { HelpModule } from "./help/help.module";
import { NotificationsModule } from "./notifications/notifications.module";
import { MessagesModule } from './messages/messages.module';
import { PromoModule } from './promo/promo.module';
import { PacketsModule } from "./packets/packets.module";
import { CalendarsModule } from './calendars/calendars.module';
import { FinancialStatsModule } from './financial-stats/financial-stats.module';

import { routerModule } from './app.routing';
import { AppConfig } from "./app.config";

import { NavBarComponent } from './nav-bar/nav-bar.component';
import { AppComponent } from './app.component';
import { environment } from 'environments/environment';
import { UnauthorizedInterceptor } from './utilities/interceptors/Unauthorized.interceptor';
import { StateService } from '@uirouter/core';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    routerModule,
    FormsModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    HappeningsModule,
    FacilitiesModule,
    ServicesModule,
    AuthModule,
    UsersModule,
    ClientsModule,
    TransactionsModule,
    BookingsModule,
    DashboardModule,
    HelpModule,
    NotificationsModule,
    ContactModule,
    SettingsModule,
    MessagesModule,
    PromoModule,
    PacketsModule,
    NgxDatatableModule,
    UtilitiesModule,
    CalendarsModule,
    FinancialStatsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA2x5GHK_l69yz3MbT_24ZvD8ouBdU5w9c',
      libraries: ["places"]
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    Ng2CableModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AngularTokenModule.forRoot({
      apiBase: environment.apiBase,
      signInRedirect: '/login',

    })
  ],
  providers: [
    AppConfig,
    AngularTokenModule,
    Location,
    { provide: LOCALE_ID, useValue: "pl-PL" },
    NotificationsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
      deps: [
        StateService,
        NgbModal,
        AngularTokenService
      ]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}