import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../api/users.service';
import { UIRouter } from "@uirouter/angular";
import { IUser } from '../users.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAddServiceModalComponent } from '../modals/user-add-service-modal/user-add-service-modal.component';
import { UserAddClientModalComponent } from '../modals/user-add-client-modal/user-add-client-modal.component';
import { TranslatePipe } from '@ngx-translate/core';
import { _i18n } from './../../utilities/translation-marker/translation-marker';

@Component({
  selector: 'user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  providers: [TranslatePipe]
})
export class UserDetailsComponent implements OnInit {

  user: IUser;
  userCardHeader = _i18n('users.details.header');
  loading: boolean = true;
  userServices: any[];
  userClients: any[];

  userClientsPage = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  userClientsSort: any;
  userClientsQ = {};

  usersServicespage = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  }
  usersServiceSort: any;
  usersServiceQ = {};

  constructor(
    private usersService:UsersService,
    private route:UIRouter,
    private modalService: NgbModal,
    private translatePipe: TranslatePipe,
  ) { }

  ngOnInit() {
    this.getUser();
    this.getUserClients({ offset: 0 });
    this.getUserServices({ offset: 0 });
  }

  openAddServiceToUserModal() {
    const modalRef = this.modalService.open(UserAddServiceModalComponent);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.success.subscribe(
      (data) => {
        this.getUserServices({ offset: 0 });
      }
    )
  }

  openAddClientToUserModal() {
    const modalRef = this.modalService.open(UserAddClientModalComponent);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.success.subscribe(
      (data) => {
        this.getUserClients({ offset: 0 });
      }
    )
  }

  private getUser() {
    this.usersService.getUser(this.userId)
      .subscribe((user: IUser) => this.user = user);
  }

  private getUserServices(pageInfo) {
    this.usersServicespage.pageNumber = pageInfo.offset;
    this.usersService.getUserServices(this.userId, this.usersServicespage.pageNumber+1, this.usersServiceQ, this.usersServiceSort).subscribe(
      (services: any) => {
        this.userServices = services;
        this.loading = false;
      }
    );
  }

  private getUserClients(pageInfo) {
    this.userClientsPage.pageNumber = pageInfo.offset;
      this.usersService.getUserClients(this.userId, this.userClientsPage.pageNumber + 1, this.userClientsQ, this.userClientsSort).subscribe(
        (clients: any) => {
          this.userClients = clients;
          this.userClientsPage.totalElements = clients.size;
          this.userClientsPage.size = clients.per_page;
          this.loading = false;
        }
      );
  }

  get userId() {
    return this.route.globals.params.id;
  }

  translateStatus(status) {
    return this.translatePipe.transform(_i18n(`service.status.${status}`))
  }

  onSortUserClients(event) {
    this.loading = true;
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.userClientsSort = prop + " " + s.dir;
    this.getUserClients({ offset: 0 })
  }

  onSortUserServices(event) {
    this.loading = true;
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.usersServiceSort = prop + " " + s.dir;
    this.getUserServices({ offset: 0 });
  }

  removeServiceFromUser(id: number) {
    if (confirm('Czy chcesz usunąć przypisaną usługę')) {
      this.usersService.removeServiceFromUser(id, this.user.id).subscribe(
        res => {
          this.getUserServices({ offset: 0 });
        }
      )
    }
  }

  removeClientFromUser(id: number) {
    console.log(id);

    if (confirm('Czy chcesz usunąć przypisanego podopiecznego')) {
      this.usersService.removeClientFromUser(id, this.user.id).subscribe(
        res => {
          this.getUserClients({ offset: 0 });
        }
      )
    }
  }
}
