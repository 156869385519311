import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import * as Moment from 'moment';
import { NgbActiveModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from './../../api/services.service';
import { UIRouter } from '@uirouter/angular';

@Component({
  selector: 'app-service-closed-modal',
  host: {
    '(document:click)': 'onClick($event)',
  },
  templateUrl: './service-closed-modal.component.html',
  styleUrls: ['./service-closed-modal.component.scss']
})
export class ServiceClosedModalComponent implements OnInit {
  @Input() interval;
  @Input() date;
  @Input() availability;

  @Output() newDate = new EventEmitter();

  timesFrom: any[];
  timesTo: any[];
  pickerInterval: number = 15;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  minFrom: NgbDateStruct;
  minTo: NgbDateStruct;
  bookings: Array<any>;
  allBlocked: boolean;
  isTimepickerVisible: boolean = false;
  dynamicId: any;

  constructor(
    private modalInstace: NgbActiveModal,
  ) { }

  onFromDateChange(date: NgbDateStruct) {
    this.fromDate = date;
    this.toDate = date;
    this.minFrom = null;
    this.minTo = date;
  }

  onToDateChange(date: NgbDateStruct) {
    this.toDate = date;
    this.minTo = null;
    if (!this.fromDate) {
      this.fromDate = date;
      this.minFrom = date;
    }
  }

  ngOnInit() {
    if (!this.date.new) {
      this.fromDate = {
        year: parseInt(this.date.from.split('-')[0], 10),
        month: parseInt(this.date.from.split('-')[1], 10),
        day: parseInt(this.date.from.split('-')[2], 10),
      };
      this.toDate = {
        year: parseInt(this.date.to.split('-')[0], 10),
        month: parseInt(this.date.to.split('-')[1], 10),
        day: parseInt(this.date.to.split('-')[2], 10),
      };
    } else {
      this.fromDate = {
        year: Moment().year(),
        month: Moment().add(1, 'month').month(),
        day: Moment().date(),
      };
      this.toDate = {
        year: Moment().year(),
        month: Moment().add(1, 'month').month(),
        day: Moment().date(),
      };
    }

    if (this.date.new === true) {
      this.allBlocked = true;
      this.date.hours = [{
        from: '00:00',
        to: '24:00'
      }];
      this.date.twenty_four_hours = true;
    } else {
      this.allBlocked = this.date.twenty_four_hours;
    }

    this.createTimesFrom(this.pickerInterval, '00:00', '24:00');
    this.createTimesTo(this.pickerInterval, '00:00', '24:00');
  }

  save(date) {
    if (!this.allBlocked && date.hours.length === 0) { return; }

    delete date.currentFrom;
    delete date.currentTo;

    if (!this.toDate) {
      this.toDate = Object.assign({}, this.fromDate);
    }

    this.fromDate.month --;
    this.toDate.month --;

    date.from = Moment(this.fromDate).format('YYYY-MM-DD');
    date.to = Moment(this.toDate).format('YYYY-MM-DD');

    date.twenty_four_hours = this.allBlocked;

    this.newDate.emit(date);
    this.close();
  }

  createTimesFrom(interval, from, to) {
    this.timesFrom = [];
    let fromMoment = Moment(from, 'HH:mm');
    let toMoment = Moment(to, 'HH:mm');

    let t = toMoment.diff(fromMoment, 'm') / interval;
    while (t--) {
      this.timesFrom.push(fromMoment.format('HH:mm'));
      fromMoment.add(interval, 'minute');
    }
  }

  createTimesTo(interval, from, to) {
    this.timesTo = [];
    let fromMoment = Moment(from, 'HH:mm');
    let toMoment = Moment(to, 'HH:mm');

    let t = toMoment.diff(fromMoment, 'm') / interval;
    fromMoment.add(interval, 'minute');
    while (t--) {
      if (t < 1) {
        this.timesTo.push(fromMoment.format('kk:mm'));
      } else {
        this.timesTo.push(fromMoment.format('HH:mm'));
      }
      fromMoment.add(interval, 'minute');
    }
  }

  close() {
    this.modalInstace.close();
  }

  allDayChanged() {
    if (this.allBlocked) {
      this.date.hours = [{
        from: '00:00',
        to: '24:00'
      }];
      this.date.currentFrom = null;
      this.date.currentTo = null;
    } else {
      this.date.hours = [{
        from: '09:00',
        to: '17:00'
      }];
    }
  }

  fromChanged(from) {
    let fromMoment = Moment(from, 'HH:mm');

    if (!this.date.currentTo) {
      this.date.currentTo = fromMoment.add(15, 'minute').format('HH:mm');
      this.createTimesFrom(15, '00:00', this.date.currentTo);
      this.createTimesTo(15, this.date.currentFrom, '24:00');
    }
  }

  toChanged(to) {
    let toMoment = Moment(to, 'HH:mm');

    if (!this.date.currentFrom) {
      this.date.currentFrom = toMoment.subtract(15, 'minute').format('HH:mm');
      this.createTimesFrom(15, '00:00', this.date.currentTo);
      this.createTimesTo(15, this.date.currentFrom, '24:00');
    }
  }

  editTimes(date, from, to, invalid) {
    if (from === 'From' || to === 'To') {
      return;
    }

    if (!from || !to) {
      return;
    }

    if (!date.hours) {
      date.hours = [];
    }

    date.hours.push({ from, to });

    if (date.hours.length > 1) {
      date.hours.sort((a: any, b: any) => {
        a = Object.assign({}, a);
        b = Object.assign({}, b);
        a.to = parseInt(a.to.replace(':', ''), 10);
        b.to = parseInt(b.to.replace(':', ''), 10);
        if (a.to > b.to) {
          return 1;
        }
        return -1;
      });
    }
    this.merge(date);
    this.isTimepickerVisible = false;
    this.date.currentFrom = null;
    this.date.currentTo = null;
    this.createTimesFrom(15, '00:00', '24:00');
    this.createTimesTo(15, '00:00', '24:00');
  }

  merge(date) {
    date.hours.forEach((current: {from: string; to: string}, index: number) => {
      const currentStart = Moment(current.from, 'HH:mm');
      const currentEnd = Moment(current.to, 'HH:mm');
      if (date.hours[index + 1]) {
        const nextStart = Moment(date.hours[index + 1].from, 'HH:mm');
        const nextEnd = Moment(date.hours[index + 1].to, 'HH:mm');

        if (nextStart.isSameOrBefore(currentStart) && (nextEnd.isSameOrAfter(currentStart) && nextEnd.isSameOrBefore(currentEnd))) {
          date.hours[index] = { from: date.hours[index + 1].from , to: current.to };
          date.hours.splice(index + 1, 1);
          this.merge(date);
          return;
        }

        if  (nextStart.isSameOrBefore(currentStart) && nextEnd.isSameOrAfter(currentEnd)) {
          date.hours[index] = { from: date.hours[index + 1].from , to: date.hours[index + 1].to };
          date.hours.splice(index + 1, 1);
          this.merge(date);
          return;
        }

        if ((nextStart.isSameOrAfter(currentStart) && nextStart.isSameOrBefore(currentEnd)) && nextEnd.isSameOrAfter(currentEnd)) {
          date.hours[index] = { from: current.from , to: date.hours[index + 1].to };
          date.hours.splice(index + 1, 1);
          this.merge(date);
          return;
        }

        if (nextStart.isSameOrAfter(currentStart) && nextEnd.isSameOrBefore(currentEnd)) {
          date.hours[index] = { from: current.from , to: current.to };
          date.hours.splice(index + 1, 1);
          this.merge(date);
          return;
        }
      }
    });
  }

  removeOpenHours(index: number, date: any) {
    this.date.hours.splice(index, 1);
    this.merge(date);
  }

  showTimepicker() {
    this.isTimepickerVisible = true;
  }

  openDatepicker(id: any) {
    if (this.dynamicId) {
      this.dynamicId.close();
    }
    this.dynamicId = id;
  }

  // JUST TEMP CHANGES
  onClick(event) {
    // if (this.dynamicId && event.target.className !== 'datepicker__cover') {
    //   setTimeout(() => {
    //     this.dynamicId.close();
    //   }, 10);
    // }
    if (this.dynamicId == undefined) {
      // console.log("Dynamic id ==");
    } else if (!document.getElementById('datepickers').contains(event.target)) {
      setTimeout(() => {
        this.dynamicId.close();
        this.dynamicId = null
      }, 10);
    }
  }

  focusDatepicker(event, d) {
    if (event.relatedTarget) {
      d.open();
      this.openDatepicker(d);
    }
  }
}
