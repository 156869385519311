import { Component, OnInit, Input } from '@angular/core';
import { UIRouter, StateService } from "@uirouter/angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ServicesService } from '../../api/services.service';
import { ServiceVariantFormComponent } from '../service-variant-form/service-variant-form.component';

@Component({
  selector: 'service-variants',
  templateUrl: './service-variants.component.html',
  styleUrls: ['./service-variants.component.scss']
})
export class ServiceVariantsComponent implements OnInit {

  @Input() service;

  constructor(private servicesService:ServicesService,
              private route:UIRouter,
              private modalService: NgbModal,
              public stateService: StateService,) { }

  ngOnInit() {



  }

  new() {
    const modalRef = this.modalService.open(ServiceVariantFormComponent, {
      size: 'sm'
    });
    modalRef.componentInstance.service = this.service;
    modalRef.componentInstance.variant = Object.assign({}, {interval: 60});

    modalRef.result.then(
      (result) => {
        if (result) {
          console.info("RESULT IS")
          console.info(result)
          this.service.service_variants = result
        }
      },
      (reason) => {
        console.info(reason)
      }
    );
  }

  edit(variant, i) {
    // this.stateService.go("services.editServiceVariant", {id: variant.id});
    const modalRef = this.modalService.open(ServiceVariantFormComponent, {
      size: 'sm'
    });
    modalRef.componentInstance.service = this.service;
    modalRef.componentInstance.variant = Object.assign({}, variant);
    
    modalRef.result.then(
      (result) => {
        if (result) {
          console.info("RESULT IS")
          console.info(result)
          this.service.service_variants = result
        }
      },
      (reason) => {
        console.info(reason)
      }
    );
  }

  delete(variant, i) {
    let c = confirm("Czy napewno chcesz usunąć wariant usługi")
    if (c == true) {
      this.service.service_variants.splice(i, 1)

      let service_variant = Object.assign({ id: variant.id, _destroy: "1" })
      let service_variants_attributes = JSON.stringify([service_variant])
      let data = {id: this.service.id, service_variants_attributes: service_variants_attributes}
      console.warn("THE DATA")
      console.warn(data)
      this.servicesService.editService(data).subscribe(
        res => {
          console.info("+++")
          console.info(res)
        }, 
        err => {
          console.error("!!!")
          console.error(err)
        }
      )
    }
  }

}
