import { UIRouterModule } from "@uirouter/angular";
import { ServicesComponent } from './services.component';
import { ServicesListComponent } from './services-list/services-list.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ServiceFormComponent } from './service-form/service-form.component';
import { ServiceEditComponent } from './service-edit/service-edit.component';
import { ServicePricesEditComponent } from "./service-prices-edit/service-prices-edit.component";
import { ServiceLocationsEditComponent } from "./service-locations-edit/service-locations-edit.component";

const routesConfig = {
  states: [
    { 
      name: 'services',
      redirectTo: 'services.list',
      component:  ServicesComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'services.new-no-params',
      url: '/services/new',
      redirectTo: 'services.list',
    },
    { 
      name: 'services.list',
      url: '/services', 
      component:  ServicesListComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'services.listPage',
      url: '/services/page/:pageNumber',
      component:  ServicesListComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'services.new',
      url: '/services/new/:primary_service_id',
      component:  ServiceFormComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'services.details',
      url: '/services/:id',
      component:  ServiceDetailsComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'services.edit',
      url: '/services/:id/edit',
      component:  ServiceEditComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'services.editLocation',
      url: '/services/:id/edit/location',
      component:  ServiceFormComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'services.editPrices',
      url: '/services/:id/edit/prices',
      component:  ServicePricesEditComponent,
      data: { requiresAuth: true }
    },
    { 
      name: 'services.editLocations',
      url: '/services/:id/edit/locations',
      component:  ServiceLocationsEditComponent,
      data: { requiresAuth: true }
    }
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
