import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceAvailabilityService } from "../../api/service-availability.service";
import { ServiceClosedModalComponent } from "../service-closed-modal/service-closed-modal.component";
import { ServiceUnavailabilityService } from "../../api/service-unavailability.service";
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';

// TODO: add displaying component move this component to seperate url

@Component({
  selector: 'service-unavailability',
  templateUrl: './service-unavailability.component.html',
  styleUrls: ['./service-unavailability.component.scss'],
  providers: [TranslatePipe]
})
export class ServiceUnavailabilityComponent implements OnInit {

  @Input()
  serviceId;

  dates = [];
  interval;
  availability;

  constructor(private modalService: NgbModal,
              private availabilityService: ServiceAvailabilityService,
              private blockService: ServiceUnavailabilityService,
              private translatePipe: TranslatePipe,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.availabilityService.getAvailability(this.serviceId)
      .subscribe( res => {
        this.availability = res;
      })
    this.blockService.getBlocks(this.serviceId)
      .subscribe( (res: any) => {
        console.log(res)
        this.dates = res.data;
        this.interval = res.interval;
      })
  }

  remove(date, index) {
    if (confirm(this.translatePipe.transform(_i18n('service.unavailability.confirm.remove')))) {
      this.blockService.removeBlock(this.serviceId, date.id)
      .subscribe(res => {
        this.dates.splice(index, 1);
        this.toastr.success(this.translatePipe.transform(_i18n('service.unavailability.toast-messages.success.removed')), 'Success!')
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
    }
  }

  openEditModal(date) {
    const modalRef = this.modalService.open(ServiceClosedModalComponent);
    modalRef.componentInstance.date = date;
    modalRef.componentInstance.interval = this.interval;
    modalRef.componentInstance.availability = this.availability;
    modalRef.componentInstance.newDate
      .subscribe((date) => {
        console.log(date)
        if (date.new) {
          this.blockService.newBlock(this.serviceId, date)
            .subscribe(res => {
              this.dates.push(res);
              this.toastr.success(this.translatePipe.transform(_i18n('service.unavailability.toast-messages.success.new-unavailabilities')), 'Success!')
            }, err => {
              console.error(err);
              this.toastr.error(err.statusText, err.status);
            })
        } else {
          this.blockService.editBlock(this.serviceId, date)
            .subscribe(res => {
              this.toastr.success(this.translatePipe.transform(_i18n('service.unavailability.toast-messages.success.chaneges-saved')), 'Success!')
            }, err => {
              console.error(err);
              this.toastr.error(err.statusText, err.status);
            })
        }
      })
  }

}
