/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input, NgZone, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from "@angular/forms";
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss']
})
export class LocationCardComponent implements OnInit {

  @Input()
  locations;

  ngOnChanges(changes: {[propKey: string]: any}) {
    this.addresses = this.locations;
    for (let i = 0; i < this.addresses.length; i++) {
        this.addresses[i].lat = Number(this.addresses[i].lat);
        this.addresses[i].lng = Number(this.addresses[i].lng);
        this.addresses[i].edit = false;
        this.addresses[i]._destroy = "0";
    }
    this.latitude = this.addresses[0].lat;
    this.longitude = this.addresses[0].lng;

    //create search FormControl
    this.searchControl = new FormControl();
    this.postalControl = new FormControl();
    this.zoom = 8;
    this.locationSubmitted = false;
  }

  @Output()
  changes: EventEmitter<any> = new EventEmitter<any>();

  addresses = [];
  latitude: number = 52.069316;
  longitude: number = 19.480290;
  markerLat: number;
  markerLng: number;
  zoom: number = 8;

  searchControl: FormControl;
  postalControl: FormControl;
  @ViewChildren('search') searchElementsRefs: QueryList<any>; //all refs list
  searchElementRef:any;
  // GOOGLE AUTOCOMPLETE
  hasDownBeenPressed = false;
  address_attributes:any = {
    formatted_address: ''
  };
  emptyAddress: boolean;
  newAddressForm: boolean = false;
  locationSubmitted: boolean = false;
  user_kind;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, public _tokenService: AngularTokenService) {
    this._tokenService.validateToken().subscribe(
      res => {
        this.user_kind = this._tokenService.currentUserData['user_kind'];
      }
    )
  }

  ngOnInit() {
    this.addresses = this.locations;
    for (let i = 0; i < this.addresses.length; i++) {
        this.addresses[i].lat = Number(this.addresses[i].lat);
        this.addresses[i].lng = Number(this.addresses[i].lng);
        this.addresses[i].edit = false;
        this.addresses[i]._destroy = "0";
    }
    this.latitude = this.addresses[0].lat;
    this.longitude = this.addresses[0].lng;

    //create search FormControl
    this.searchControl = new FormControl();
    this.postalControl = new FormControl();
  }

  initGooglePlace(index) {
    this.searchElementsRefs.forEach((ref,i) => {
      if (i === index) {
        this.searchElementRef = ref;
      }
    });

    //set current position
    this.setCurrentPosition();
    let autocompleteOptions ={
      country: 'pl'
    }
    //load Places Autocomplete
    this.searchElementRef.nativeElement.addEventListener('keydown', (e) => {
      if (e.keyCode === 40) {
        this.hasDownBeenPressed = true;
      }
    });
    this.searchElementRef.nativeElement.addEventListener('focus', () => {
      this.hasDownBeenPressed = false;
      this.searchElementRef.nativeElement.value = '';
    });
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: autocompleteOptions
      });
      google.maps.event.addDomListener(this.searchElementRef.nativeElement, 'keydown', (e) => {
        // Maps API e.stopPropagation();
        e.cancelBubble = true;
        // If enter key, or tab key
        if (e.keyCode === 13 || e.keyCode === 9) {
          // If user isn't navigating using arrows and this hasn't ran yet
          if (!this.hasDownBeenPressed && !e.hasRanOnce) {
            google.maps.event.trigger(e.target, 'keydown', {
              keyCode: 40,
              hasRanOnce: true,
            });
          }
        }
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (typeof place.address_components !== 'undefined') {
            // reset hasDownBeenPressed in case they don't unfocus
            this.hasDownBeenPressed = false;
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.markerLat = place.geometry.location.lat();
            this.markerLng = place.geometry.location.lng();
            this.zoom = 17;
            this.formatAdressAttributes(place);
          }
        })
      });
    });
  }

  setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.markerLat = position.coords.latitude;
        this.markerLng = position.coords.longitude;
        this.zoom = 17;
      });
    }
  }

  formatAdressAttributes(place) {
    let address_attributes = {}
    for (let i = 0; i < place.address_components.length; i++) {
      for (let j = 0; j < place.address_components[i].types.length; j++) {
        address_attributes[place.address_components[i].types[j]] = place.address_components[i].long_name;
      }
    }
    address_attributes["formatted_address"] = place.formatted_address;
    this.address_attributes = address_attributes;
  }

  markerDrag(event) {
    this.markerLat = event.coords.lat;
    this.markerLng = event.coords.lng;
  }
  addressFieldInputChange(e, index) {
    if (e.keyCode == 13) {
      e.preventDefault();
      // this.saveLocation(index)
    }
  }

  saveLocation(index) {
    this.locationSubmitted = true;
    if (!this.address_attributes.formatted_address || !this.address_attributes.postal_code) {
      return;
    }
    this.address_attributes.id = this.addresses[index].id;
    this.address_attributes.lat = this.markerLat;
    this.address_attributes.lng = this.markerLng;
    this.changes.emit(this.address_attributes);
    this.addresses[index].edit = false;
    this.markerLat = null;
    this.markerLng = null;
    this.address_attributes = {};
  }

  editAdress(addres, index) {
    this.switchEditMode(index, true);
    if (addres.edit) {
      this.initGooglePlace(index);
    }
  }

  switchEditMode(index, mode) {
    this.locationSubmitted = false;
    this.addresses.forEach(addr => addr.edit = false);
    this.newAddressForm = false;
    this.addresses[index].edit = mode;
  }

  markAsDelete(index) {
    delete this.addresses[index].edit;
    this.addresses[index]._destroy = "1";
    this.changes.emit([this.addresses[index]]);
    this.addresses[index].edit = false;
  }

  showNewLocationForm() {
    this.addresses.forEach(addr => addr.edit = false);
    this.newAddressForm = true;
    this.initGooglePlace(this.addresses.length)
  }
  hideNewLocationForm() {
    this.locationSubmitted = false;
    this.newAddressForm = false;
    this.markerLat = null;
    this.markerLng = null;
    this.address_attributes = {};
  }
  submitNewLocationForm() {
    this.locationSubmitted = true;
    if (!this.address_attributes.formatted_address || !this.address_attributes.postal_code) {
      return;
    }
    this.newAddressForm = false;

    this.address_attributes.lat = this.markerLat;
    this.address_attributes.lng = this.markerLng;
    this.changes.emit([this.address_attributes]);
    this.markerLat = null;
    this.markerLng = null;
    this.address_attributes = {};

  }
}
