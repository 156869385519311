import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { HappeningsService } from '../../api/happenings.service';

@Component({
  selector: 'happenings-details',
  templateUrl: './happenings-details.component.html',
  styleUrls: ['./happenings-details.component.scss']
})
export class HappeningsDetailsComponent implements OnInit {

  happening: any;

  constructor(
    private route: UIRouter,
    private happeningsService: HappeningsService,
  ) {
    happeningsService.pictureChanged$.subscribe(picture => this.onPictureChange(picture));
  }

  ngOnInit() {
    this.happeningsService.getHappening(this.route.globals.params.id)
      .subscribe(
        res => {
          this.happening = res;
        }
      );
  }

  onPictureChange(picture) {
    this.happening.image = picture;
  }

}
