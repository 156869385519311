import {map} from 'rxjs/operators';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'pay-in-cash-modal',
  templateUrl: './pay-in-cash-modal.component.html',
  styleUrls: ['./pay-in-cash-modal.component.scss']
})
export class PayInCashModalComponent {

  constructor(
    private modalInstace: NgbActiveModal,
    private http: HttpClient,
  ) { }

  registerCard() {
    this.http.get(`/user/register_card`).subscribe(
      (response: any) => {
        window["location"]["href"] = response.url;
      }
    )
  }

  close() {
    this.modalInstace.close();
  }
}
