import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routerModule } from './messages.routing';
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MessagesService } from './../api/messages.service';

import { MessagesComponent } from './messages.component';
import { MessagesListComponent } from './messages-list/messages-list.component';
import { MessagesConversationComponent } from './messages-conversation/messages-conversation.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    FormsModule,
    TranslateModule.forChild()
  ],
  declarations: [
    MessagesComponent,
    MessagesListComponent,
    MessagesConversationComponent
  ],
  providers: [
    MessagesService
  ]
})
export class MessagesModule { }
