import { UIRouterModule } from "@uirouter/angular";
import { FinancialStatsListComponent } from './pages';
import { FinancialStatsComponent } from './financial-stats.component';

const routesConfig = {
  states: [
    {
      name: 'financialStats',
      redirectTo: 'financialStats.list',
      component:  FinancialStatsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'financialStats.list',
      url: '/financial-stats',
      component:  FinancialStatsListComponent,
      data: { requiresAuth: true }
    }
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
