import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ClientsService } from "app/api/clients.service";
import { UIRouter } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {

  public docsForm: FormGroup;
  public modalRef: NgbModalRef;
  public documnets: any[];
  public page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };


  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private clientsService: ClientsService,
    private route: UIRouter,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.docsForm = this.fb.group({
      name: ['', Validators.required],
      file: ['', Validators.required]
    });
    this.setPage({
      offset: 0
    });
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.clientsService.getDocuments(this.route.globals.params.id).subscribe((pagedData: any) => {
      this.page.totalElements = pagedData.size
      this.page.size = pagedData.per_page
      this.documnets = pagedData.data;
    });
  }

  public downloadAttachment(attachment) {
    this.clientsService.downloadAttachment(this.route.globals.params.id, attachment).subscribe(
      (res: any) => {
        const file = new File([res.file], res.filename);
        FileSaver.saveAs(file);
      },
      error => {
        console.log('download error:', JSON.stringify(error));
        this.toastr.error('spróbuj ponownie później', 'Error!');
      }, () => {
        console.log('Completed file download.')
      }
    );
  }

  public submit() {
    if (this.docsForm.valid) {
      console.log(this.docsForm.value);
      this.clientsService.createDocument(this.route.globals.params.id, this.docsForm.value).subscribe(
        (response: any) => {
          this.toastr.success("Pomyślnie dodano dokument", 'Sukces!');
          this.modalRef.close();
          this.ngOnInit();
        },
        error => {
          this.toastr.error("Błąd podczas dodawania dokumentu, spróbuj ponownie później",'Błąd!');
        }
      );
    } else{
      this.docsForm.get('name').markAsTouched();
      this.docsForm.get('file').markAsTouched();
    }
  }

  public onFileChange(event) {
    let reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.docsForm.get('file').patchValue(reader.result);
        this.cd.markForCheck();
      };
    }
  }

  public openModal(content) {
    this.modalRef = this.modalService.open(content);
  }

  get name() {
    return this.docsForm.get('name');
  }

  get file() {
    return this.docsForm.get('file');
  }
}
