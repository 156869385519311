import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { QueryBuilderOptions, or, QueryBuilder } from 'ransack-query-builder';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {

  @Input() fields: string[] = [];
  @Input() placeholder: string = 'Wyszukaj...';

  public query: QueryBuilderOptions[];
  public value: string;
  public model: string;
  private debouncer$ = new Subject<string>();
  private destory$ = new Subject<boolean>()

  @Output()
  filterChange: EventEmitter<Object> = new EventEmitter<Object>();

  constructor() { }

  ngOnInit() {
    this.updateQuery('');
    this.debouncer$.pipe(
      debounceTime(300),
      takeUntil(this.destory$)
    ).subscribe((val: string) => {
      this.value = val;
      this.updateQuery(this.value);
      const searchQuery = new QueryBuilder(this.query).toObject();
      this.filterChange.emit(searchQuery);
    })
  }

  onInputChange(value: string) {
    this.debouncer$.next(value);
  }

  updateQuery(value: string) {
    this.query = [{ property: or(...this.fields), filters: { cont: value}}];
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }
}
