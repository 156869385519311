import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { ErrorHandlerService } from './../utilities/error-handler.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceUnavailabilityService {

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  getBlocks(serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/unavailabilities`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  newBlock(serviceId, block) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/unavailabilities`;
    return this.http.post(url, block).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  editBlock(serviceId, block) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/unavailabilities/${block.id}`
    return this.http.put(url, block).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  removeBlock(serviceId, blockId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/unavailabilities/${blockId}`
    return this.http.delete(url, blockId).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

}
