import { UIRouterModule } from "@uirouter/angular";
import { PacketsComponent } from "./packets.component";
import { PacketsListComponent } from "./packets-list/packets-list.component";
import { PacketDetailsComponent } from "./packet-details/packet-details.component";
import { PacketFormComponent } from "./packet-form/packet-form.component";

const routesConfig = {
  states: [
    {
      name: 'packets',
      redirectTo: 'packets.list',
      component: PacketsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'packets.list',
      url: '/packets',
      component: PacketsListComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'packets.details',
      url: '/packets/:id',
      component: PacketDetailsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'packets.new',
      url: '/packets/new',
      component: PacketFormComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'packets.edit',
      url: '/packets/:id/edit',
      component: PacketFormComponent,
      data: { requiresAuth: true }
    },
  ]
};

export const routerModule = UIRouterModule.forChild(routesConfig);
