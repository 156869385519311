import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';
import { ClientsService } from '../../api/clients.service';
import { UIRouter } from '@uirouter/angular';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  providers: [TranslatePipe],
})
export class ClientComponent implements OnInit {

  user = {};
  user_id;

  constructor(
    private stateService: StateService,
    private route: UIRouter,
    private toastr: ToastrService,
    private clientsService: ClientsService,
  ) { }

  ngOnInit() {
    this.user_id = this.route.globals.params.id;
    this.clientsService.getClient(this.user_id).subscribe(user => {
      this.user = user;
    });
  }

  destroyClient() {
    if (confirm('Czy na pewno chcesz usunąć podopiecznego?')) {
      this.clientsService.destroyClient(this.user_id)
        .subscribe(
          res => {
            this.toastr.success('podopieczny został usunięty pomyślnie', 'Success!');
            this.stateService.go('clients.list');
          },
          err => {
            this.toastr.error('spróbuj później', 'Error!');
          }
      );
    }
  }

  isEditable() {
    return this.user['is_valid_user'] === false;
  }

}
