import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServicesService, NewService } from '../../api/services.service';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import * as Moment from "moment";
import { ServiceChangesCheckService } from '../../utilities/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../utilities/service-changes-validation/service-changes-validation.service';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import { CheckModalComponent } from '../../utilities/check-modal/check-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss'],
  providers: [TranslatePipe]
})
export class ServiceFormComponent implements OnInit, OnDestroy {

  service: NewService = {
    name: '',
    description: '',
    interval: 60,
    location: '',
    city_id: 1,
    openTime: '00:00',
    closeTime: '24:00',
    price: '',
    addresses_attributes: [],
    disciplineSelected: false,
    suggested_discipline: "",
    primary_service_id: null,
    category:'bookable',
    cancellation_before_time: "0",
    privacy: '0',
    connectivity_type: 'offline',
    referenced_service_id: null
  };
  emptyVariant = {name: "", interval: "60", price: "", referenced_service_variant_id: 0};
  variants = [
    {name: "", interval: "60", price: "", referenced_service_variant_id: 0}
  ]
  primary_service_id: string;
  isSaving = false;
  cities:Array<object>;
  disciplines:Array<object>;
  disciplineSelected: number;
  weekDaysSelected: number = 7;
  baseInterval: number = 15;
  intervalsMin = [480, 420, 360, 300, 240, 180, 120, 90, 75, 60, 45, 30, 15];
  timesFrom = [];
  timesTo = [];
  file: File;
  image;
  errors = false;
  user_kind: any;

  weekdays = [
    {
      name: "Poniedziałek",
      engName: "monday",
      selected: true,
      value: 64
    },
    {
      name: "Wtorek",
      engName: "tuesday",
      selected: true,
      value: 32
    },
    {
      name: "Środa",
      engName: "wednesday",
      selected: true,
      value: 16
    },
    {
      name: "Czwartek",
      engName: "thursday",
      selected: true,
      value: 8
    },
    {
      name: "Piątek",
      engName: "friday",
      selected: true,
      value: 4
    },
    {
      name: "Sobota",
      engName: "saturday",
      selected: true,
      value: 2
    },
    {
      name: "Niedziela",
      engName: "sunday",
      selected: true,
      value: 1
    },
  ]

  cancellation = [
    {
      name: "odwołanie darmowe bez względu na czas odwołania",
      value: "0"
    },
    {
      name: "5 dni przed terminem usługi",
      value: "120"
    },
    {
      name: "3 dni przed terminem usługi",
      value: "72"
    },
    {
      name: "24 godziny przed terminem usługi",
      value: "24"
    },
    {
      name: "12 godzin przed terminem usługi",
      value: "12"
    },
    {
      name: "6 godzin przed terminem usługi",
      value: "6"
    },
    {
      name: "brak możliwości darmowego odwołania usługi",
      value: "-1"
    },
  ]

  transitionHook: any;
  savingInProgress:boolean = false;
  resolved: boolean = false;
  resolvedWith: boolean;
  changesChecked: boolean;

  offlineServices = [];
  offlineServicesLoading = true;
  offlineServiceVariants = [];

  openHourMask = function(rawValue) {
    if ( rawValue >= '20') {
      return [/[0-2]/,/[0-3]/,':',/[0-5]/,/[0-9]/]
    } else {
      return [/[0-2]/,/[0-9]/,':',/[0-5]/,/[0-9]/]
    }
  }

  constructor(private servicesService:ServicesService,
              private route:UIRouter,
              private stateService:StateService,
              private toastr: ToastrService,
              public config: NgbTooltipConfig,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService,
              private translatePipe: TranslatePipe,
              private modalService: NgbModal,
              private _tokenService: AngularTokenService,
            ) {
                config.placement = 'right';
                config.triggers = 'hover';
                config.container = 'body';
                this.transitionHook = transitionService.onBefore({from: 'services.**'}, (trans: Transition)=>{
                  if ((this.image || this.service.addresses_attributes.length > 0) && !this.changesChecked) {
                      let cos:HookResult = new Promise((resolve, reject) => {
                        this.openEditModal()
                        .then((res) => {
                          if (res) {
                            this.resolved = true;
                            this.resolvedWith = true;
                            this.changesChecked = true;
                            resolve(true);
                          } else {
                            this.resolved = true;
                            this.resolvedWith = false;
                            this.changesChecked = false;
                            resolve(false);
                          }
                        })
                      })
                      return cos;
                  } else {
                    this.changesChecked = false;
                    return changesValidationService.validate(this.service);
                  }
                })
                this.primary_service_id = this.route.globals.params.primary_service_id;
                if (this.primary_service_id) {
                  this.service.primary_service_id = this.primary_service_id;
                }
              }

  openEditModal(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var modalRef = this.modalService.open(CheckModalComponent);
      modalRef.componentInstance.decision
      .subscribe((decision) => {
        if (decision == true) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      })
    });
  }

  ngOnInit() {
    this._tokenService.validateToken().subscribe(
      res => {
        this.user_kind = this._tokenService.currentUserData['user_kind'];
      }
    )

    this.servicesService.getDisciplinesStream()
      .subscribe((res: Array<object>) => {
        if (res) {
          this.disciplines = JSON.parse(JSON.stringify(res));
        }
        console.log(res)
      })

    this.servicesService.getCitiesStream()
      .subscribe((res: Array<object>) => {
        this.cities = res;
        console.log(res)
      })

    this.createTimesFrom(this.baseInterval, '00:00', '24:00')
    this.createTimesTo(this.baseInterval, '00:00', '24:00')
    this.checkChangesService.assignObject(this.service);
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  isOtherDisciplineSelected() {
    let selected = false;
    if (this.disciplines) {
      for (let discipline of this.disciplines) {
        if (discipline['selected'] == true && discipline['id'] == 0) {
          selected = true
        }
      }
    }
    return selected;
  }
  checkSelectedDisciplines() {
    let selected = false;
    for (let discipline of this.disciplines) {
      if (discipline['selected']) {
        if (discipline['id'] > 0 || (discipline['id'] == 0 && this.service.suggested_discipline != "")) {
          selected = true
        }
      }
    }
    this.service.disciplineSelected = selected;
  }
  selectWeekDays(day) {
    let selected = 0;
    for (let day of this.weekdays) {
      if (day.selected) {
        selected = selected + 1;
      }
    }
    this.weekDaysSelected = selected;
  }
  validVariants() {
    if (this.variants.length == 1) {
      this.variants[0].name = this.service.name
    }
    for (let variant of this.variants) {
      if (!variant.price) {
        variant.price = '0';
      }
      if (!variant.name || (this.service.category == 'bookable' && !variant.interval)) {
        return false;
      }
    }
    return true;
  }

  validOnlineService() {
    let valid = true;
    if (this.service.connectivity_type === 'online') {
      // alert('in')
      if (!this.service.referenced_service_id) {
        valid = false;
        // alert('1')
      }
      for (let variant of this.variants) {
        if (!variant.referenced_service_variant_id) {
          valid = false;
          // alert('2')
        }
      }
    } else {
      // alert('f')
    }
    return valid;
  }

  save(form, service) {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;

    this.changesValidationService.resolved = true;
    this.errors = false;

    // new validation
    // TODO: This validation should validate on form?
    // if (!this.file || !this.service.name || !this.service.description || ((this.primary_service_id && this.user_kind == 'facility_owner') ? false : this.service.addresses_attributes.length == 0) || !this.service.price || !service.disciplineSelected || (this.service.category == 'bookable' && (this.weekDaysSelected == 0 || !service.openTime || (service.openTime && service.openTime.length < 5)))) {
    if (!this.file || !this.service.name || !this.service.description || !this.validVariants() || ((this.primary_service_id && this.user_kind == 'facility_owner') ? false : (this.service.connectivity_type == 'offline' && this.service.addresses_attributes.length == 0)) || !service.disciplineSelected || (this.service.category == 'bookable' && (this.weekDaysSelected == 0 || !service.openTime || (service.openTime && service.openTime.length < 5))) || !this.validOnlineService()) {
    // if (!this.validOnlineService() || !this.file || !this.service.name || !this.service.description || !this.validVariants() || ((this.primary_service_id && this.user_kind == 'facility_owner') ? false : (this.service.connectivity_type == 'offline' && this.service.addresses_attributes.length == 0)) || !service.disciplineSelected || (this.service.category == 'bookable' && (this.weekDaysSelected == 0 || !service.openTime || (service.openTime && service.openTime.length < 5)))) {
      this.toastr.warning(this.translatePipe.transform(_i18n('service.form.toast-messages.warning.no-all-data')), null);
      this.errors = true;
      this.savingInProgress = false;

      // console.info("validations")
      // console.info(1, !this.validVariants())
      // console.info(2, ((this.primary_service_id && this.user_kind == 'facility_owner') ? false : this.service.addresses_attributes.length == 0))
      // console.info(3, (this.service.category == 'bookable' && (this.weekDaysSelected == 0 || !service.openTime || (service.openTime && service.openTime.length < 5))))
      // console.info()
    }

    if (this.errors) {
      this.savingInProgress = false;
      return;
    }

    let disciplinesCopy = JSON.parse(JSON.stringify(this.disciplines))
    if (this.isSaving) {
      return;
    } else {
      this.isSaving = true;
      disciplinesCopy.splice(disciplinesCopy.length-1, 1);
    }

    this.service["variants"] = this.variants

    this.servicesService.newService(service, disciplinesCopy, this.weekdays, this.file, 0, 0)
      .subscribe( (service: any) => {
        this.savingInProgress = false;
        for (let i = 0; i < this.disciplines.length; i++) {
          this.disciplines[i]['selected'] = false;
        }
        disciplinesCopy = {};
        this.changesChecked = true;
        this.toastr.success(this.translatePipe.transform(_i18n('service.form.toast-messages.success.created-service')), 'Success!');
        this.stateService.go('services.details', {id: service.id});
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
        for (let i = 0; i < this.disciplines.length; i++) {
          this.disciplines[i]['selected'] = false;
        }
        this.isSaving = false;
        this.savingInProgress = false;
      })

  }

  createTimesFrom(interval, from, to) {
    this.timesFrom = [];
    let fromMoment = Moment(from, "HH:mm");
    let toMoment = Moment(to, "HH:mm");

    let t = toMoment.diff(fromMoment, 'm') / interval;
    while (t--) {
      this.timesFrom.push(fromMoment.format("HH:mm"));
      fromMoment.add(interval, 'minute');
    }
  }


  createTimesTo(interval, from, to) {
    this.timesTo = [];
    let fromMoment = Moment(from, "HH:mm");
    let toMoment = Moment(to, "HH:mm");

    let t = toMoment.diff(fromMoment, 'm') / interval;
    fromMoment.add(interval, 'minute');
    while (t--) {
      if (t < 1) {
        this.timesTo.push(fromMoment.format("kk:mm"));
      } else {
        this.timesTo.push(fromMoment.format("HH:mm"));
      }
      fromMoment.add(interval, 'minute');
    }
  }

  addVariant() {
    // set name for a first service variant if the name of first variant is empty
    if (!this.variants[0].name && this.variants.length == 1) {
      let name = this.service.name;
      this.variants[0].name = name;
    }

    // add new service variant
    let v = Object.assign({}, this.emptyVariant)
    this.variants.push(v)
  }

  removeVariant(i) {
    if (this.variants.length > 1) {
      let r = confirm("Czy na pewno chcesz usunąć wariant usługi?")
      if (r == true) {
        this.variants.splice(i, 1)
      }
    }
  }

  imageAdded(event) {
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      this.file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.image = reader.result;
      }
    }
  }

  cancel() {
    if (this.route.globals.params.id) {
      this.stateService.go('services.details', {id: this.route.globals.params.id})
    } else {
      this.stateService.go('services.list')
    }
  }

  translate(day:string) {
    return this.translatePipe.transform(_i18n(`${day}`));
  }

  priceInputChanged() {
    parseInt(this.service.price) < 1 ? this.service.price = String(1) : this.service.price
  }

  switchServiceCategory(category) {
    this.service.category = category;
  }

  switchServicePrivacy(privacy) {
    this.service.privacy = privacy;
  }

  // ONLINE
  switchServiceOnline(online) {
    this.service.connectivity_type = online;

    if (online === 'online') {
      this.service.category = 'unitable';

      this.servicesService.getOfflineServices()
        .subscribe(
          (res: any) => {
            console.info('res')
            console.info(res)
            this.offlineServices = res.data;
            this.offlineServicesLoading = false;
          }
        )
    }
  }

  setReferencedServiceId(service) {
    // console.info("reference")
    // console.info(service)
    this.service.referenced_service_id = service.id;
    this.offlineServiceVariants = service.service_variants;
  }

  setReferencedServiceVariantId(variant, referenced) {
    // console.info("reference is")
    // console.info(referenced)
    // console.info("variant is")
    // console.info(variant)
    // this.service.referenced_service_id = service.id;
    // this.offlineServiceVariants = service.service_variants;
    variant.referenced_service_variant_id = referenced.id
  }

  setAddresses(event) {
    this.service.addresses_attributes = event;
  }

  logService() {
    console.info("this.service")
    console.info(this.service)
    console.info("variants")
    console.info(this.variants)
  }

}
