import { Component, OnInit } from '@angular/core';
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent implements OnInit {

  constructor(public _tokenService: AngularTokenService) { }

  user_kind;
  ngOnInit() {
    this._tokenService.validateToken().subscribe(
      res => {
        this.user_kind = this._tokenService.currentUserData['user_kind'];
      }
    )
  }

}
