import { UIRouterModule } from "@uirouter/angular";
import { HelpComponent } from "./help.component";
import { GenerateCodeComponent } from './generate-code/generate-code.component';

const routesConfig = {
  states: [
    { 
      name: 'help',
      url: '/faq',
      component:  HelpComponent,
      data: { requiresAuth: true }
    }
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
