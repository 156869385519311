import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { AngularTokenService } from 'angular-token';
import { FacilitiesService } from "../../api/facilities.service";

@Component({
  selector: 'facilities-transition',
  templateUrl: './facilities-transition.component.html',
  styleUrls: ['./facilities-transition.component.scss']
})
export class FacilitiesTransitionComponent implements OnInit {

  service: any;

  constructor(private _tokenService: AngularTokenService,
              private stateService: StateService,
              private facilitiesService: FacilitiesService) { }

  ngOnInit() {
    this._tokenService.validateToken().subscribe(
      (res: any) => {
        if (this._tokenService.currentUserData) {
          if (this._tokenService.currentUserData['user_kind'] == 'facility_owner') {
            this.stateService.go('facilities', null, {location: 'replace'});
          } else {
            this.facilitiesService.getServices(null, null, null).subscribe(res => {
              this.service = (res as any).data[0];
              if (this.service) {
                this.stateService.go('facilities.details', {id: this.service.id}, {location: 'replace'})
              } else {
                this.stateService.go('facilities.new', null, {location: 'replace'})
              }
            });
          }
        }
      }
    )
  }

}
