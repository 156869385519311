import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { FinancialStatsFilterOutputInterface } from './../../models';

@Component({
  selector: 'financial-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit, OnDestroy {

  displayMonths = 1;
  navigation = 'select';
  showWeekNumbers = false;
  outsideDays = 'visible';
  minDate: NgbDateStruct;

  filterGroup: FormGroup;
  untilDestorySubject$ = new Subject();

  filter: EventEmitter<FinancialStatsFilterOutputInterface> = new EventEmitter<FinancialStatsFilterOutputInterface>();

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.filterGroup = this.fb.group({
      from: [''],
      to: [''],
    });

    this.filterGroup.valueChanges
      .pipe(
        takeUntil(this.untilDestorySubject$)
      )
      .subscribe((filterForm: FinancialStatsFilterOutputInterface) => {
        if (filterForm.from) {
          this.minDate = filterForm.from;
        }

        if (filterForm.from && filterForm.to) {
          this.filter.emit(filterForm);
        }
      });
  }

  ngOnDestroy() {
    this.untilDestorySubject$.next();
    this.untilDestorySubject$.complete();
  }
}
