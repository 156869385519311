import { UIRouterModule } from "@uirouter/angular";

import { TransactionsListComponent } from './transactions-list/transactions-list.component';
import { TransactionsDetailsComponent } from './transactions-details/transactions-details.component';
import { TransactionsComponent } from './transactions.component';

const routesConfig = {
  states: [
    {
      name: 'transactions',
      redirectTo: 'transactions.list',
      component:  TransactionsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'transactions.list',
      url: '/transactions',
      component:  TransactionsListComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'transactions.listPage',
      url: '/transactions/page/:pageNumber',
      component:  TransactionsListComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'transactions.details',
      url: '/transaction/:id',
      component:  TransactionsDetailsComponent,
      data: { requiresAuth: true }
    },

  ]
}
export const routerModule = UIRouterModule.forChild(routesConfig)
