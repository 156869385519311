import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { LeafletModule } from '@asymmetrik/angular2-leaflet';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ErrorHandlerService } from './error-handler.service';

import { ServiceCardComponent } from './service-card/service-card.component';
import { ServiceChangesCheckService } from './service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from './service-changes-validation/service-changes-validation.service';
import { CheckModalComponent } from './check-modal/check-modal.component';
import { ForgotModalComponent } from './forgot-modal/forgot-modal.component';
import { CustomMinDirective } from './custom-min/custom-min.directive';
import { CustomMaxDirective } from './custom-max/custom-max.directive';
import { GalleryCardComponent } from './gallery-card/gallery-card.component';
import { LocationCardComponent } from './location-card/location-card.component';
import { UserCardComponent } from './user-card/user-card.component';
import { SessionModalComponent } from './session-modal/session-modal.component';
import { PayInCashModalComponent } from './pay-in-cash-modal/pay-in-cash-modal.component';
import { NgbDateFRParserFormatter } from './date-formatter/date-formatter';
import { NotFoundComponent } from './not-found/not-found.component';
import { AddressSearchComponent } from './address-search/address-search.component';
import { ServiceVerificationCommentsComponent } from './service-verification-comments/service-verification-comments.component';
import { FilterEventsPipe } from './filter-events/filter-events.pipe';
import { DayViewSchedulerComponent } from './day-view-scheduler/day-view-scheduler.component';
import { DayViewSchedulerTimeSelectorComponent } from './day-view-scheduler-time-selector/day-view-scheduler-time-selector.component';
import { CalendarModule } from 'angular-calendar';
import { StickypollyfillDirective } from './stickypollyfill.directive';
import { WeekViewSchedulerComponent } from './week-view-scheduler/week-view-scheduler.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ServiceReviewsComponent } from './service-reviews/service-reviews.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ServiceReviewResponseComponent } from './service-review-response/service-review-response.component';

@NgModule({
  imports: [
    CommonModule,
    LeafletModule,
    AgmCoreModule,
    UIRouterModule.forChild(),
    NgbModule.forRoot(),
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    NgxDatatableModule
  ],
  exports: [
    UserCardComponent,
    LocationCardComponent,
    GalleryCardComponent,
    ServiceCardComponent,
    CustomMinDirective,
    CustomMaxDirective,
    AddressSearchComponent,
    FilterEventsPipe,
    DayViewSchedulerComponent,
    DayViewSchedulerTimeSelectorComponent,
    WeekViewSchedulerComponent,
    StickypollyfillDirective,
    SearchBarComponent,
    ServiceReviewsComponent,
    ServiceReviewResponseComponent,
  ],
  entryComponents: [
    CheckModalComponent,
    ForgotModalComponent,
    SessionModalComponent,
    PayInCashModalComponent,
    ServiceVerificationCommentsComponent,
    ServiceReviewResponseComponent,
  ],
  declarations: [
    UserCardComponent,
    LocationCardComponent,
    GalleryCardComponent,
    ServiceCardComponent,
    CheckModalComponent,
    ForgotModalComponent,
    CustomMinDirective,
    CustomMaxDirective,
    SessionModalComponent,
    PayInCashModalComponent,
    NotFoundComponent,
    AddressSearchComponent,
    ServiceVerificationCommentsComponent,
    FilterEventsPipe,
    DayViewSchedulerComponent,
    DayViewSchedulerTimeSelectorComponent,
    StickypollyfillDirective,
    WeekViewSchedulerComponent,
    SearchBarComponent,
    ServiceReviewsComponent,
    ServiceReviewResponseComponent,
  ],
  providers: [
    ServiceChangesCheckService,
    ServiceChangesValidationService,
    NgbDateFRParserFormatter,
    ErrorHandlerService
  ],
})
export class UtilitiesModule { }
