import { UIRouterModule } from "@uirouter/angular";
import { ContactComponent } from "./contact.component";
import { SponsorContactComponent } from "./sponsor-contact/sponsor-contact.component";

const routesConfig = {
  states: [
    { 
      name: 'contact',
      url: '/contact',
      component:  ContactComponent,
      data: { requiresAuth: true }
    },
    {
       name: 'contact.sponsor',
       url: '/sponsor-contact',
       component:  SponsorContactComponent,
       data: { requiresAuth: true }
    }
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
