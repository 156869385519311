import { UIRouterModule } from "@uirouter/angular";
import { CalendarsComponent } from "./calendars.component";
import { CalendarsListComponent } from "./calendars-list/calendars-list.component";

const routesConfig = {
  states: [
    {
      name: 'calendars',
      redirectTo: 'calendars.list',
      component:  CalendarsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'calendars.list',
      url: '/calendars?state&code&scope',
      component:  CalendarsListComponent,
      data: { requiresAuth: true }
    },
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig);
