import { UIRouterModule } from '@uirouter/angular';
import { HappeningsComponent } from './happenings.component';
import { HappeningsListComponent } from './happenings-list/happenings-list.component';
import { HappeningsDetailsComponent } from './happenings-details/happenings-details.component';
import { HappeningsInfoComponent } from './happenings-info/happenings-info.component';
import { HappeningsSubmissionsComponent } from './happenings-submissions/happenings-submissions.component';
import { HappeningsDatesComponent } from './happenings-dates/happenings-dates.component';
import { HappeningsGalleryComponent } from './happenings-gallery/happenings-gallery.component';
import { HappeningsReviewsComponent } from './happenings-reviews/happenings-reviews.component';
import { HappeningsFormComponent } from './happenings-form/happenings-form.component';
import { HappeningsDetailsEditComponent } from './happenings-details-edit/happenings-details-edit.component';
import { HappeningsCheckoutDataComponent } from './happenings-checkout-data/happenings-checkout-data.component';

const routesConfig = {
  states: [
    {
      name: 'happenings',
      redirectTo: 'happenings.list',
      component:  HappeningsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.list',
      url: '/happenings',
      component:  HappeningsListComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.new',
      url: '/happenings/new/:primary_service_id',
      component:  HappeningsFormComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.edit',
      url: '/happenings/:id/edit',
      component:  HappeningsDetailsEditComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.details',
      url: '/happenings/:id',
      redirectTo: 'happenings.details.info',
      component:  HappeningsDetailsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.details.info',
      url: '/info',
      component:  HappeningsInfoComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.details.submissions',
      url: '/submissions',
      component:  HappeningsSubmissionsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.details.dates',
      url: '/dates',
      component:  HappeningsDatesComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.details.gallery',
      url: '/gallery',
      component:  HappeningsGalleryComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.details.reviews',
      url: '/reviews',
      component:  HappeningsReviewsComponent,
      data: { requiresAuth: true }
    },
    {
      name: 'happenings.details.checkout',
      url: '/checkout',
      component:  HappeningsCheckoutDataComponent,
      data: { requiresAuth: true }
    },
  ]
};

export const RouterModule = UIRouterModule.forChild(routesConfig);
