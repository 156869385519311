import { Component, OnInit } from '@angular/core';
import { ContactService } from  '../../api/contact.service';

@Component({
  selector: 'sponsor-contact',
  templateUrl: './sponsor-contact.component.html',
  styleUrls: ['./sponsor-contact.component.scss']
})
export class SponsorContactComponent implements OnInit {
  sponsor: any;
  generateView = false;

  constructor(private contactService: ContactService) { }

  ngOnInit() {
    this.contactService.getContact()
    .subscribe(res => {
      console.log(res)
      this.sponsor = res;
      this.generateView = true;
    })
  }


}
