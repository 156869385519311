import { Injectable } from '@angular/core';

@Injectable()
export class ServiceChangesCheckService {
  oldObject: any;

  constructor() { }

  assignObject(object) {
    this.oldObject = { ...object}
    console.log(this.oldObject);

  }

  checkObject(newObject) {

    console.warn('new',newObject);
    console.warn('old',this.oldObject);
    // console.warn('diff', this.DiffObjects(this.oldObject, newObject));


    if (JSON.stringify(newObject) === JSON.stringify(this.oldObject)) {
      return true;
    } else {
      return false;
    }
  }
}
