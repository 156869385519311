import { Component, OnInit } from '@angular/core';
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(public _tokenService: AngularTokenService) { }

  user_kind;
  ngOnInit() {
    this._tokenService.validateToken().subscribe(
      res => {
        this.user_kind = this._tokenService.currentUserData['user_kind'];
      }
    )
  }

}
