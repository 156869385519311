import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'facilities-services',
  templateUrl: './facilities-services.component.html',
  styleUrls: ['./facilities-services.component.scss']
})
export class FacilitiesServicesComponent implements OnInit {
  
  @Input()
  services;
  
  @Input()
  facility;

  constructor() { }

  ngOnInit() {
  }

}
