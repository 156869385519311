import { Component, OnInit } from '@angular/core';
import { UsersService } from "../../api/users.service";
import { UIRouter, StateService } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { _i18n } from './../../utilities/translation-marker/translation-marker';
import { TranslatePipe } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { IUser } from '../users.interface';

@Component({
  selector: 'users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [TranslatePipe]
})
export class UsersListComponent implements OnInit {

  users = [];
  pagination;
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  }
  loading = false;
  sort: any;
  q = {};

  constructor(private usersService:UsersService,
              private route:UIRouter,
              private stateService:StateService,
              private translatePipe: TranslatePipe,
              private toastr: ToastrService) {
                this.page.pageNumber = 0;
              }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo){
   this.page.pageNumber = pageInfo.offset;
   this.usersService.getUsers(this.page.pageNumber+1, this.q, this.sort).subscribe((pagedData: any) => {
     console.log(pagedData)
    this.page.totalElements = pagedData.size;
    this.page.size = pagedData.per_page;
    this.users = pagedData.data;
    this.viewTable = true;
   });
 }

  onSort(event) {
    this.loading = true;
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.sort = prop + " " + s.dir;
    this.usersService.getUsers(0, this.q, this.sort)
    .subscribe((res: any) => {
      this.users = res.data;
      this.loading = false;
    });
  }

  onFilter(event) {
    this.page.totalElements = event.size;
    this.page.size = event.per_page;
    this.users = event.data;
  }

  onQueryChange(event) {
    this.q = event;
  }

  pageChange(page) {
    if (page != this.route.globals.params.pageNumber) {
      console.log(page)
      this.stateService.go('users.listPage', {pageNumber: page})
    }
  }

  remove(userId) {
    if (confirm(this.translatePipe.transform(_i18n('users.list.confirm.remove')))) {
      this.usersService.deleteUser(userId)
      .subscribe( response => {
        this.toastr.success(this.translatePipe.transform(_i18n('users.list.toast-messages.removed')), 'Success!')
        this.setPage({ offset: 0 });
      }, err => {
        console.error(err)
        this.toastr.error(err.statusText, err.status);
      })
    }
  }

  downloadRodoForm(id: number, user: IUser) {
    if (user.rodo_downloading == true) return ;
    user.rodo_downloading = true;
    let name = `${user.first_name} ${user.last_name}`
    this.usersService.downloadRodoStatement(id, name)
      .subscribe(res => {
          const file = new File([res.file], res.filename);
          FileSaver.saveAs(file);
          user.rodo_downloading = false;
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed file download.')
        });
  }
}
