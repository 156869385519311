import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {from as observableFrom,  Observable, Subject } from 'rxjs';
import {startWith, catchError, map} from 'rxjs/operators';
import { Response, RequestOptions, Headers, RequestMethod } from '@angular/http';
import * as Moment from 'moment';
import { ErrorHandlerService } from './../utilities/error-handler.service';

export interface NewService {
  name: string,
  description: string,
  location: string,
  interval?: number,
  city_id: number,
  openTime?: string,
  closeTime?: string,
  price: string,
  addresses_attributes?: any,
  disciplineSelected: boolean,
  suggested_discipline: string,
  primary_service_id: string,
  category: string,
  cancellation_before_time: string,
  periodically: any,
  privacy: string,
  account_free_registration: any,
  external_registration: any,
  external_registration_url: any,
  // ONLINE
  connectivity_type: string
}

export interface Ransack {
  [prop: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class HappeningsService {

  public pictureChanged$: EventEmitter<any>;

  reservation;
  disciplines: Array<object>;
  disciplinesStream$ = new Subject();

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
  ) {
    this.pictureChanged$ = new EventEmitter();
  }

  changePicture(picture) {
    this.pictureChanged$.emit(picture);
  }

  getHappenings(page) {
    const url = `${environment.apiBase}/happenings?page=${page}`;
    return this.http.get(url)
  }

  getHappening(id) {
    const url = `${environment.apiBase}/host/services/${id}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  getHappeningSchedules(id) {
    const url = `${environment.apiEndpoint}/services/${id}/happening_schedules`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    );
  }

  getServices(page) {
    let url = `${environment.apiEndpoint}/services?q[category_not_eq]=facility&page=${page}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getAllServices(userId: number) {
    let url = `${environment.apiEndpoint}/services?q[category_not_eq]=facility&except[]=limit&except[]=offset&user_id_not_eq=${userId}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getAllTimedServices() {
    let url = `${environment.apiEndpoint}/services?q[category_eq]=bookable&[category_not_eq]=facility&except[]=limit&except[]=offset&q[verification_status_eq]=verified`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getPagedServices(page, query, sort) {
    let q = "";
    let s = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiBase}/host/services?q[category_eq]=happening&page=${page}${q}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getVerifiedServices(page, query) {
    let q = '';
    for (let key in query) {
      if (query[key]) {
        q += `&q[${key}]=${query[key]}`
      }
    }
    let url = `${environment.apiEndpoint}/services?q[category_not_eq]=facility&page=${page}${q}`;
    return this.http.get(url);
  }

  deleteService(serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}`;
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getService(id) {
    let url = `${environment.apiEndpoint}/services/${id}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  removeServiceImage(serviceId, imageId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/pictures/${imageId}`
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  setServiceImageDefault(image, service) {
    let url = `${environment.apiEndpoint}/services/${service.id}/pictures/${image.id}/set_default.json`
    let data = {
      image: image
    }
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  addServiceImage(data, serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/pictures`;
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, data, { headers }).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  newService(service, disciplines, weekdays, file, markerLat, markerLng) {

    const formData: FormData = new FormData();

    if (service.addresses_attributes.length > 0) {
      service.location = service.addresses_attributes[0].formatted_address;
      service.addresses_attributes = JSON.stringify(service.addresses_attributes);
    }

    service.category = 'happening';
    service.interval = 60;
    service.openTime = '00:00';
    service.closeTime = '24:00';

    let happeningSchedules = []
    for (let schedule of service.schedules) {

      let startMoment = Moment(service.openTime, "HHmm");
      let endMoment = Moment(service.closeTime, "HHmm");

      const start_date = `${Moment().year(schedule.date_from.year).month(schedule.date_from.month - 1).date(schedule.date_from.day).format('YYYY-MM-DD')} ${schedule.time_from}`;
      const end_date = `${Moment().year(schedule.date_to.year).month(schedule.date_to.month - 1).date(schedule.date_to.day).format('YYYY-MM-DD')} ${schedule.time_to}`;


      for (let happening_schedule_variant of schedule.happening_schedule_variants_attributes) {

        let happening_prices = [];
        for (let service_price of happening_schedule_variant.service_prices_attributes) {
          const valid_from = `${Moment().year(service_price.date_from.year).month(service_price.date_from.month - 1).date(service_price.date_from.day).format('YYYY-MM-DD')} ${service_price.time_from}`;
          const valid_to = `${Moment().year(service_price.date_to.year).month(service_price.date_to.month - 1).date(service_price.date_to.day).format('YYYY-MM-DD')} ${service_price.time_to}`;

          happening_prices.push({
            price: service_price.price,
            valid_from: valid_from,
            valid_to: valid_to,
          });
        }
        happening_schedule_variant.service_prices_attributes = happening_prices;
      }

      happeningSchedules.push({
        start_date: start_date,
        end_date: end_date,
        users_limit: schedule.users_limit,
        happening_schedule_variants_attributes: schedule.happening_schedule_variants_attributes
        // happening_schedule_variants_attributes: happening_prices
      });
    }

    formData.append('happening_schedules_attributes', JSON.stringify(happeningSchedules));

    if (service.attachments.length > 0) {
      for (let i = 0; i < service.attachments.length; i++) {
        formData.append('attachments_attributes[][name]', service.attachments[i].name);
        formData.append('attachments_attributes[][source]', service.attachments[i].source);
      }
    }

    for (let key in service) {
      formData.append(key, service[key]);
    }
    if (service.addresses_attributes.length > 0) {
      service.addresses_attributes = JSON.parse(service.addresses_attributes);
    }

    let hoursArray = []
    let startMoment = Moment(service.openTime, "HHmm");
    let endMoment = Moment(service.closeTime, "HHmm");

    let t = endMoment.diff(startMoment, 'm') / 15;

    while (t--) {
      hoursArray.push(startMoment.format('HHmm'))
      startMoment.add(15, 'minute');
    }

    let availability_attributes = {
      days: 0,
      interval: service.interval,
      hours: {
        from: service.openTime,
        to: service.closeTime,
        price: service.price,
        location: service.addresses_attributes[0]
      }
    };

    for (let i = 0; i < weekdays.length; i++) {
      if (weekdays[i].selected) {
        availability_attributes.days += weekdays[i].value;
        availability_attributes[weekdays[i].engName] = hoursArray;
      }
    }
    formData.append('availability_attributes', JSON.stringify(availability_attributes));

    let discipline_ids = [];

    for (let i = 0; i < disciplines.length; i++) {
      if (disciplines[i].selected) {
        discipline_ids.push(disciplines[i].id)
      }
    }

    if (service.addresses_attributes.length > 0) {
      formData.append('lat', service.addresses_attributes[0].lat);
      formData.append('lng', service.addresses_attributes[0].lng);
    }
    formData.append('discipline_ids', JSON.stringify(discipline_ids));
    formData.append('image', file, file.name);

    let url = `${environment.apiBase}/host/services`;
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, formData, { headers }).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  updateService(id, data) {
    let url = `${environment.apiEndpoint}/services/${id}`;
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  editService(service: any) {
    let url = `${environment.apiEndpoint}/services/${service.id}`;
    if (service.addresses_attributes && service.addresses_attributes.length > 0) {
      service.addresses_attributes = JSON.stringify(service.addresses_attributes);
    }
    return this.http.put(url, service).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getDisciplines() {
    let url = `${environment.apiEndpoint}/disciplines`;

    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
    .subscribe((response: any) => {
      if (!this.disciplines) {
        this.disciplines = response;
        if (this.disciplines) {
          this.disciplines.push({id: 0, name: 'Inny'})
        }
        this.disciplinesStream$.next(this.disciplines);
      }
    })
  }

  getDisciplinesStream() {
    if (!this.disciplines) {
      this.getDisciplines();
    }
    return observableFrom(this.disciplinesStream$).pipe(
      startWith(this.disciplines)
    )
  }

  getServiceAvailability(serviceId, date) {
    let url = `${environment.apiBase}/services/${serviceId}/availability`;

    return this.http.get(url,{params: date}).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  reserveService(reservation) {
    this.reservation = reservation;
    let person = reservation.person;
    let date = [reservation.reservation.day.year, reservation.reservation.day.month, reservation.reservation.day.day].join("-")
    let bookings = [];
    let hours = reservation.reservation.hours;
    let packetId = reservation.packetId;
    for (let i in hours) {
      let time_from = (hours[i].from < 10) ? '0' + hours[i].from + ':00' : hours[i].from + ':00';
      let time_to = (hours[i].to < 10) ? '0' + hours[i].to + ':00' : hours[i].to + ':00';
      bookings.push({
        'date': date,
        'time_from': time_from,
        'time_to': time_to,
        'service_id': reservation.service.id,
        'service_variant_id': reservation.service_variant_id,
        'first_name':person.first_name,
        'last_name':person.last_name,
        'email':person.email,
        'phone': person.phone,
        'note': person.note,
        'service_booking_group_id': packetId
      })
    }
    let data = {'booking': bookings};

    let url = `${environment.apiEndpoint}/services/${reservation.service.id}/bookings`;
    return this.http.post(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  editReserveService(reservation, bookingId) {
    this.reservation = reservation;
    let person = reservation.person;
    let date = [reservation.reservation.day.year, reservation.reservation.day.month, reservation.reservation.day.day].join("-")
    let bookings = [];
    let hours = reservation.reservation.hours;
    let packetId = reservation.packetId;
    for (let i in hours) {
      let time_from = (hours[i].from < 10) ? "0" + hours[i].from + ":00" : hours[i].from + ":00";
      let time_to = (hours[i].to < 10) ? "0" + hours[i].to + ":00" : hours[i].to + ":00";
      bookings.push({
        "date": date,
        "time_from": time_from,
        "time_to": time_to,
        "service_id": reservation.service.id,
        "service_variant_id": reservation.service_variant_id,
        "client_id": person.id,
        "first_name":person.first_name,
        "last_name":person.last_name,
        "email":person.email,
        "phone": person.phone,
        "note": person.note,
        'service_booking_group_id': packetId
      })
    }

    let data = {"booking": bookings[0]};

    let url = `${environment.apiEndpoint}/services/${reservation.service.id}/bookings/${bookingId}`;
    return this.http.put(url, data).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  getReservation(reservation, query: Ransack, queryString: String) {
    let q = '';
    for (let key in query) {
      if (query[key]) {
        q += `&q[${key}]=${query[key]}`
      }
    }

    let url = `${environment.apiEndpoint}/services/${reservation.service.id}/bookings?${q}${queryString}`;
    return this.http.get(url);
  }

  getBookings(page, query, sort, all = false) {
    let q = "";
    let s = "";
    let p = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    if (all) {
      p = "&except[]=limit&except[]=offset";
    }
    let url = `${environment.apiEndpoint}/bookings?page=${page}${q}${p}`;
    return this.http.get(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }

  cancelBooking(serviceId, bookingId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/bookings/${bookingId}`;
    return this.http.delete(url).pipe(
      catchError(err => this.errorHandler.handle(err))
    )
  }
}
