import {from as observableFrom,  Subject, Observable } from 'rxjs';
import {tap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { StateService } from "@uirouter/angular";
import { Ng2Cable, Broadcaster } from 'ng2-cable';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface Notification {
  content: string,
  created_at: string,
  id: number,
  status: string,
  title: string,
  updated_at: string
}

export interface NotificationsList {
  data: Array<Notification>,
  unread: number,
  per_page: number,
  size: number
}

@Injectable()
export class NotificationsService {

  constructor(
    private tokenService: AngularTokenService,
    private http: HttpClient,
    public stateService: StateService,
    private ng2cable: Ng2Cable,
    private broadcaster: Broadcaster
  ) {
    this.setNotificationsChannel();
    this.broadcaster.on<any>('NotificationsChannel').subscribe(
      message => {
        this.unreadNotifications = this.unreadNotifications + 1;
        this.notificationsStream$.next({data: [message.notification]})
      }
    )}

  notificationsStream$ = new Subject();
  navNotifications: NotificationsList;
  unreadNotifications = 0;

  setNotificationsChannel() {
    this.tokenService.validateToken().subscribe(
      res => {
        this.subscribeToNotificationsChannel()
      },
      error => {
        this.unsubscribeFromNotificationsChannel()
      }
    );
  }

  subscribeToNotificationsChannel() {
    this.ng2cable.subscribe(environment.apiBase + `/cable?access-token=${localStorage.accessToken}&uid=${localStorage.uid}&client=${localStorage.client}`, 'NotificationsChannel', { accessToken: localStorage.accessToken, uid: localStorage.uid });
  }

  unsubscribeFromNotificationsChannel() {
    if (this.ng2cable.cable) {
      this.ng2cable.unsubscribe();
    }
    this.notificationsStream$.next({data: []})
  }

  getNotifications(page) {
    let url = `${environment.apiBase}/notifications?page=${page}`
    return this.http.get(url);
  }

  getNavNotificationsStream() {
    if ((!this.navNotifications && this.tokenService.userSignedIn())) {
      this.getNavNotifications();
    }
    return observableFrom(this.notificationsStream$)
  }

  getNavNotifications() {
    this.getNotifications(1).pipe(
      tap((res: any) => { res.data = res.data.slice(0,5) }))
      .subscribe(res => {
        this.navNotifications = res
        this.unreadNotifications = this.unreadNotifications + res.unread
        this.notificationsStream$.next(this.navNotifications)
      })
  }

  readNotification(notification) {
    this.http.get(`${environment.apiBase}/notifications/${notification.id}`)
    if (notification.status == "unread") {
      notification.status = "read"
      this.unreadNotifications = this.unreadNotifications - 1;
    }

    this.notificationsStream$.next(this.unreadNotifications)

    switch (notification.notificable_type) {
      case "Booking":
        this.stateService.go("bookings.details", {serviceId: notification.notificable.service_id, bookingId: notification.notificable_id});
        break;
      case "PaymentTransaction":
      this.stateService.go("bookings.details", { serviceId: notification.notificable.service_id, bookingId: notification.notificable.bookings[0] });
        break;
    }
  }
}
