import { Component, OnInit } from '@angular/core';
import { NotificationsService } from "../../api/notifications.service";
import { UIRouter, StateService } from "@uirouter/angular";

@Component({
  selector: 'notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {

  notifications:Array<object>;
  pagination;

  constructor(private notificationsService: NotificationsService,
              private route:UIRouter,
              private stateService:StateService) { }

  ngOnInit() {
    this.getNotifications()
  }

  getNotifications() {
    let currentPage = this.route.globals.params.pageNumber || 1;
    this.notificationsService.getNotifications(currentPage)
      .subscribe((response: any) => {
        console.log(response)
        this.notifications = response.data;

        let paginationObject = {
          currentPage: currentPage,
          size: response.size,
          perPage: response.per_page
        }

        this.pagination = paginationObject;
      })
  }

  pageChange(page) {
    if (page != this.route.globals.params.pageNumber) {
      console.log(page)
      this.stateService.go('notificationsListPage', {pageNumber: page})
    }
  }

  showNotification(notification) {
    this.notificationsService.readNotification(notification)
  }

}
