import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServicesService } from '../../api/services.service';

@Component({
  selector: 'service-review-response',
  templateUrl: './service-review-response.component.html',
  styleUrls: ['./service-review-response.component.scss']
})
export class ServiceReviewResponseComponent implements OnInit {

  @Input() review;
  @Input() reviewableId;
  @Output() responseCreated: EventEmitter<any> = new EventEmitter();
  view: number = 0;
  response: any = {};
  submitted: boolean = false;
  error: boolean = false;

  constructor(private servicesService: ServicesService) { }

  ngOnInit() {
  }

  trim(attr) {
    if (this.response[attr]) {
      this.response[attr] = this.response[attr].trim();
    }
  }

  submit() {
    if (!this.response.response_content) {
      this.error = true;
      return
    }

    if (this.submitted) { return; }
    this.submitted = true;

    this.servicesService.respondToServiceReview(this.reviewableId, this.review.id, {review: this.response})
      .subscribe(
        res => {
          console.info("res")
          console.info(res)
          this.view = 1;
          this.responseCreated.emit(this.response)
        }
      )
  }

}
