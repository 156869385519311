import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AngularTokenService } from 'angular-token';

import { SessionModalComponent } from './../session-modal/session-modal.component';

export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private stateService:StateService,
    private modalService: NgbModal,
    private tokenService: AngularTokenService,
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap(
        () => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.tokenService.signOut().subscribe(
                () => this.goToLogin(),
                () => this.goToLogin(),
              );
            }
            return;
          }
        }
      )
    );
  }

  public goToLogin() {
    if (this.stateService.current.name === 'auth.resetPassword') { return; }
    this.stateService.go('auth', undefined, { location: true });
    if (!this.modalService.hasOpenModals() && this.stateService.current.name !== 'auth.login') {
      this.modalService.open(SessionModalComponent);
    }
  }
}
