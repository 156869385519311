import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CourseOrExperiece, CourseOrExperieceType, ExperienceAttributes, CurrentlyCheckboxText, HeaderText, ModalHeaderText, NoContentVariant } from './service-courses-and-experience.interface';
import { NgbModal, NgbModalRef, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from 'app/api/services.service';
import { ToastrService } from 'ngx-toastr';

import * as Moment from 'moment';

@Component({
  selector: 'service-courses-and-experience',
  templateUrl: './service-courses-and-experience.component.html',
  styleUrls: ['./service-courses-and-experience.component.scss'],
})
export class ServiceCoursesAndExperienceComponent implements OnInit {

  @Input() public type: CourseOrExperieceType;
  @Input() public serviceId: number;
  @Input() public items: CourseOrExperiece[] = [];
  @Output() public onAdd: EventEmitter<boolean> = new EventEmitter<boolean>()

  public modalInstance: NgbModalRef;
  public newItem: CourseOrExperiece = {
    name: '',
    date_from: {month: '', year: '', day: '01'},
    date_to: {month: '', year: '', day: '01'},
    experience_type: null,
    currently: false
  };
  public submitted: boolean = false;
  public calendarRef: any;
  public months: Array<any>;
  public years: Array<number>;
  public currentDate: Moment.Moment = Moment();

  constructor(
    private modalService: NgbModal,
    private servicesService: ServicesService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.newItem.experience_type = this.type;
    this.months = this.generateMonths();
    this.years = this.generateYears();
  }

  public openModal(content: NgbModalRef) {
    this.modalInstance = this.modalService.open(content, { size: 'sm' });
    this.modalInstance.result.then(() => this.clearForm()).catch(() => {})
  }

  public save() {
    console.log(this.newItem);
    if (!this.newItem.date_from || (!this.newItem.date_to && !this.newItem.currently) || !this.newItem.name) {
      this.submitted = true;
      return;
    }
    this.DatesToString();
    if (this.newItem.currently) {
      this.newItem.date_to = "";
    }
    const data: ExperienceAttributes = {
      experiences_attributes: JSON.stringify([this.newItem])
    }
    this.servicesService.updateExperience(data, this.serviceId).subscribe(
      res => {
        this.onAdd.emit(true);
        this.submitted = false;
        this.toastr.success(`Pomyślnie ${this.newItem.id ? 'zaktualizowano' : 'dodano'}`, 'Sukces');
        this.modalInstance.close();
      },
      err => {
        if (err['experiences.date_to'].length) {
          this.toastr.error(err['experiences.date_to'][0], 'Błąd');
        } else {
          this.toastr.error('Wystąpił nieoczekiwany błąd', 'Błąd');
        }
        this.StringToDates();
      }
    )
  }

  public remove(experienceId: string) {
    const data: ExperienceAttributes = {
      experiences_attributes: JSON.stringify([{id: experienceId, _destroy: "1"}])
    }
    this.servicesService.updateExperience(data, this.serviceId).subscribe(
      res => {
        this.onAdd.emit(true);
        this.toastr.success('Pomyślnie usunięto', 'Sukces');
        if (this.modalInstance) {
          this.modalInstance.close();
        }
      },
      err => this.toastr.error('Wystąpił nieoczekiwany', 'Błąd')
    )
  }

  public edit(item: CourseOrExperiece, content: NgbModalRef) {
    this.openModal(content);
    this.newItem = Object.assign({}, item);
    this.StringToDates();
  }

  public onChangeCurrently(event: any) {
    if (!event) {
      this.newItem.date_to = {
        month: '',
        year: '',
        day: '01'
      }
    }
  }

  public onChangeDate() {
    if (this.newItem.date_from.year && this.newItem.date_from.month) {
      const selectedFrom = `${this.newItem.date_from.year}-${this.newItem.date_from.month}`;
      if (Moment() < Moment(selectedFrom, 'YYYY-MM')) {
        this.newItem.date_from.month = Moment().get('M') + 1;
        this.toastr.info('Nie możesz wybrać daty w przyszłosci');
      }
    }

    if (this.newItem.date_to.year && this.newItem.date_to.month) {
      const selectedFrom = `${this.newItem.date_to.year}-${this.newItem.date_to.month}`;
      if (Moment() < Moment(selectedFrom, 'YYYY-MM')) {
        this.newItem.date_to.month = Moment().get('M') + 1;
        this.toastr.info('Nie możesz wybrać daty w przyszłosci');
      }
    }
  }

  /**
   * DateToString
   * convert @NgbDateStruct to year-month-day string
   */
  private DatesToString() {
    this.newItem.date_from.day = '01';
    this.newItem.date_from = `${this.newItem.date_from.year}-${this.newItem.date_from.month}-${this.newItem.date_from.day}`;
    if (this.newItem.date_to) {
      this.newItem.date_to.day = '01';
      this.newItem.date_to = `${this.newItem.date_to.year}-${this.newItem.date_to.month}-${this.newItem.date_to.day}`;
    }
  }

  /**
   * StringToDates
   * convert year-month-day string to @NgbDateStruct
   */
  private StringToDates() {
    const from = this.newItem.date_from.split('-');
    this.newItem.date_from = {
      year:  parseInt(from[0]),
      month: parseInt(from[1]),
      day: parseInt(from[2]),
    } as NgbDateStruct;
    if (this.newItem.date_to) {
      const to = this.newItem.date_to.split('-');
      this.newItem.date_to = {
        year: parseInt(to[0]),
        month: parseInt(to[1]),
        day: parseInt(to[2]),
      } as NgbDateStruct;
    }
  }

  private clearForm() {
    this.newItem = {
      name: '',
      date_from: {month: '', year: '', day: '01'},
      date_to: {month: '', year: '', day: '01'},
      experience_type: this.type,
      currently: false
    };
  }

  get currentylyText() {
    return CurrentlyCheckboxText[this.newItem.experience_type];
  }

  get headerText() {
    return HeaderText[this.type];
  }

  get modalHeaderText() {
    return ModalHeaderText[this.type];
  }

  get noContentVariant() {
    return NoContentVariant[this.type];
  }

  private generateMonths() {
    const months: any[] = []

    for (let i = 1; i <= 12; i++) {
      months.push({name: Moment(i, 'M').locale('pl').format('MMMM'), index: i})
    }

    return months;
  }

  private generateYears() {
    const years: number[] = [];
    const CURRENT_YEAR = Moment().get('year');

    for (let i = CURRENT_YEAR; i >= CURRENT_YEAR-100; i--) {
      years.push(i);
    }

    return years;
  }


}
